import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Commyounity from "../pages/commyounity";
import Contact from "../pages/contact";
import Legal from "../pages/legal";
import Home from "../pages/home";
import Organisers from "../pages/organisers";
import Students from "../pages/students";
import { paths } from "./paths";
import WhyYouni from "../pages/whyyouni";
import ScrollToTop from "../components/scrollToTop";

const AppRoutes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route exact path={paths.home} element={<Home />} />
          <Route exact path={paths.organisers} element={<Organisers />} />
          <Route exact path={paths.students} element={<Students />} />
          <Route exact path={paths.whyyouni} element={<WhyYouni />} />
          <Route exact path={paths.commyounity} element={<Commyounity />} />
          <Route exact path={paths.contact} element={<Contact />} />
          <Route exact path={paths.legal} element={<Legal />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default AppRoutes;
