import { useNavigate } from "react-router-dom";
import styles from "../../styles/findOut.module.scss"
import { paths } from "../../routes/paths";

const FindOut = () => {
  const navigate = useNavigate();
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <hr />

        <div className={styles.block}>
          <div className={styles.left}>
            <h2>
              For Organisers
              <br />& Societies
            </h2>
            <p>Your page, events, team + updates.</p>
          </div>
          <div
            className={styles.right}
            onClick={() => navigate(paths.organisers)}
          >
            <div>
              find out more <img src="/assets/arrow_2.svg" alt="arrow" />
            </div>
          </div>
        </div>

        <hr />

        <div className={styles.block}>
          <div className={styles.left}>
            <h2>For Students</h2>
            <p>Your profile, groups, calendar + tickets.</p>
          </div>
          <div
            className={styles.right}
            onClick={() => navigate(paths.students)}
          >
            <div>
              find out more <img src="/assets/arrow_2.svg" alt="arrow" />
            </div>
          </div>
        </div>

        <hr />
      </div>
    </section>
  );
}

export default FindOut