import { useNavigate } from "react-router-dom";
import styles from "../../styles/studentMore.module.scss"

const More = () => {
  const navigate = useNavigate();
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1>
          Less{" "}
          <span>
            scrolling
            <img src="/assets/student_vector_1.png" alt="vector" />
          </span>
          , More{" "}
          <span>
            Living.
            <img src="/assets/student_vector_2.png" alt="vector" />
          </span>
        </h1>
        <button onClick={() => navigate("/contact")}>Yes, please</button>

      </div>
    </section>
  );
}

export default More