import contactTouch from "../../styles/contactTouch.module.scss";

export const PrivacyPolicy = () => {
  return (
    <div className={contactTouch.content}>
        <>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Last Updated: September 15, 2023
            </span>
        </p>
        <h2>
            <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>1-2</span>
            </strong>
        </h2>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            1. Who We Are.
            </span>
        </h3>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            1.1 Youni Services.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Welcome to Youni! We at Youni want you to understand what information we
            collect, and how we use and share it. That’s why we encourage you to read
            our privacy policy (
            </span>
            <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                “Privacy Policy”
            </span>
            </strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            )! In this Privacy Policy, we explain how we collect, use, share, retain
            and transfer information. We also let you know your rights.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;We’re a community and events management platform built primarily for
            university students, university organisations, clubs and societies,
            helping Organisers and Consumers thrive and connect through memorable
            lived experiences and virtual connection. Through our platform, mobile
            apps, and other services, we empower members of University Communities,
            local communities and businesses to create, discover, share, and register
            for groups and events. We also allow Organisers and Consumers to
            communicate with one another and with their peers, to keep up to date with
            their community and to view and access ads. In addition, we help
            Organisers get sponsorship with businesses by giving them the tools to
            monetise their following, and by connecting potential sponsors with
            Organisers.&nbsp;
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            This Privacy Policy and the rights and obligations contained in it are in
            addition to and are incorporated into the Youni’s Terms of Service
            </span>            
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;(“Terms of Service”). Nothing in this Privacy Policy will be
            interpreted to limit, change, or waive any terms of the Terms of Service
            or our&nbsp;
            </span>
            <span
            style={{
                color: "#39364f",
                fontSize: "10.5pt",
            }}
            >
            Organiser Agreement
            </span>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            . However, if there is any inconsistency between the Terms of Service and
            this Privacy Policy, this Privacy Policy will control.&nbsp;
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Capitalised terms that are not defined in this Privacy Policy have the
            definition provided in the Terms of Service and in the Organiser
            Agreement. In addition, the headings and subheadings throughout this
            Privacy Policy, including the italicised text following each, are for
            convenience only and will not restrict or affect any provisions, and are
            not legal guidance. When we say Youni “may,” has the right, is permitted,
            is authorised, or is allowed to do something in this Organiser Agreement,
            it means we may, but are not obligated to, exercise the applicable rights
            or options or take the applicable action, as we determine in our sole
            discretion.&nbsp;
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Youni’s Services are as defined in Youni’s Terms of Service.&nbsp;
            </span>
        </p>
        <p>
            <br />
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>1.2 Who's Who.</span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Organisers, Consumers and third parties using our Services are all
            referred to in these Terms collectively as "Users", "you" or "your".
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Youni is owned and managed by TBC GROUP LTD, a limited company
            incorporated in the United Kingdom, &nbsp;Company. No. 12592201 ("Youni,”
            "we,"us," or "our"). When this Privacy Policy mentions&nbsp;
            </span>
            <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                "Youni" "we," "us," or "our,"
            </span>
            </strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;it refers to TBC GROUP LTD. and its affiliates, and subsidiaries,
            and each of its and their respective officers, directors, agents,
            partners, and employees, as defined in the Terms of Service. If you are
            resident in the EEA or Switzerland or the UK, Youni is the responsible
            party with respect to Personal Data (defined below) collected through the
            Services. Youni EU's representative for European data protection law
            purposes is TBC GROUP LTD. Youni’s UK representative for European data
            protection law purposes is TBC GROUP LTD.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you have any questions or concerns at any time, please do not hesitate
            to contact us by emailing us at youni@tbcgroup.xyz.
            </span>
        </p>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            2. Our Privacy Statement.
            </span>
        </h3>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>2.1 Application.</span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            This Privacy Policy sets forth our policy with respect to information that
            can be associated with or which relates to a person and/or could be used
            to identify a person ("Personal Data") that is collected from Users on or
            through the Services. This policy also covers personal data collected from
            Youni sales customers and prospects. We take the privacy of your Personal
            Data seriously. Because of that, we have created this Privacy Policy.
            Please read this Privacy Policy as it includes important information
            regarding your Personal Data and other information.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            "Non-Personal Data" as used in this Privacy Policy is therefore any
            information that does not relate to a person and/or cannot be used to
            identify a person. When you interact with the Services, we may collect
            Non-Personal Data. The limitations and requirements in this Privacy Policy
            on our collection, use, disclosure, transfer and storage/retention of
            Personal Data do not apply to Non-Personal Data.
            </span>
        </p>
        <h2>
            <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>3-4</span>
            </strong>
        </h2>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            3. Personal Data That We Collect.
            </span>
        </h3>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            When you use or interact with us through the Services or engage with Youni
            as a sales customer or prospect, we may collect Personal Data. Sometimes
            this will be on our own behalf and other times this will be on behalf of
            an Organiser using our Organiser Services. This is an important
            distinction for purposes of certain data protection laws and is explained
            in more detail below.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            3.1 Information Collected From All Users.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Information you provide to us: For all Users we collect Personal Data when
            you voluntarily provide such information to the Services, such as when you
            register for access to the Services, contact us with inquiries, respond to
            one of our surveys or browse or use certain parts of the Services. The
            Personal Data we may collect includes without limitation your name,
            address, email address, academic status, student/alumni details and any
            other information that you choose to provide and/or that enables Users to
            be personally identified.&nbsp;
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Information we automatically collect: We also automatically collect
            certain technical data that is sent to us from the computer, mobile device
            and/or browser through which you access the Services ("Automatic Data").
            Automatic Data, includes without limitation, a unique identifier
            associated with your access device and/or browser (including, for example,
            your Internet Protocol (IP) address) characteristics about your access
            device and/or browser, statistics on your activities on the Services,
            information about how you came to the Services and data collected through
            Cookies, Pixel Tags, Local Shared Objects, Web Storage and other similar
            technologies. You can find out more information about how we use Cookies
            and other similar tracking technologies in our&nbsp;
            </span>
            <span
            style={{
                color: "#39364f",
                fontSize: "10.5pt",
            }}
            >
            Cookie Statement
            </span>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            . When you register for the Services or otherwise submit Personal Data to
            us, we may associate other Non-Personal Data (including Non-Personal Data
            we collect from third parties) with your Personal Data. At such instance,
            we will treat any such combined data as your Personal Data until such time
            as it can no longer be associated with you or used to identify you.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            3.2 Information Collected From Organisers.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you are an Organiser we will collect additional Personal Data from
            you.&nbsp;
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Information you provide to us: In some cases, we may collect your credit
            card information (e.g., your credit card number and expiration date,
            billing address, etc.), some of which may constitute Personal Data, to
            secure certain payments. In addition, if you use our payment processing
            services, we will collect financial information from you (e.g., your bank
            account information or an address to send checks) as necessary to
            facilitate payments and information required for tax purposes (e.g., your
            taxpayer identification number).&nbsp;
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Information we obtain from other sources: We may also collect or receive
            Personal Data including your name, email address and other contact
            information from third party sources, such as third party websites and
            marketing partners, your bank, our payment processing partners and credit
            reporting agencies.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            3.3 Information Collected From Consumers.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you are a Consumer we will collect additional Personal Data from you,
            sometimes for our own purposes and other times on behalf of an Organiser
            (see Section 16 below for more information).
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Information you provide via Youni Properties or Applications: If you
            register for a paid event or subscribe to a paid group, you will provide
            financial information (e.g., your credit card number and expiration date,
            billing address, etc.) some of which may constitute Personal Data. In
            addition, for both paid and unpaid groups/events, Organisers collect
            virtually any information from Consumers in connection with registration
            for an Organiser's event or group listed on the Services. Youni does not
            control the Personal Data that Organisers collect. When you register for,
            or otherwise provide information to Youni in conjunction with an Organiser
            event, group or activity, whether that information is yours or a third
            party’s, in connection with a purchase, registration, or transfer, that
            Organiser will receive and may use the information you provide. Please see
            Section 5.5 below, titled “How We Disclose and Transfer Your Personal
            Data: Organisers” for more information about an Organiser’s receipt and
            use of your Personal Data.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Information we obtain from other sources: We may also collect or receive
            Personal Data from third party sources, such as Organisers, other
            Consumers, social media or other third party integrations, your credit
            card issuing bank, our payment processing partners or other third parties.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            3.4 Information Collected from Sales Clients and Leads
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you engage with the Youni sales team we may collect the following: your
            name, email, address, phone number, and other professional contact
            information relating to you and your organisation, business or team. If
            you engage with the sales team and consent to recording a meeting, the
            sales team may collect audio and/or video recordings of you.
            </span>
        </p>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            4. How We Use Your Personal Data.
            </span>
        </h3>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We collect and use the Personal Data we collect in a manner that is
            consistent with this Privacy Policy, and applicable privacy laws. We may
            use the Personal Data as follows:
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            4.1 Specific Reason.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you provide Personal Data for a certain purpose, we may use the
            Personal Data in connection with the purpose for which it was provided.
            For instance, if you contact us by email, we will use the Personal Data
            you provide to answer your question or resolve your problem and will
            respond to the email address from which the contact came.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            4.2 Access and Use.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you provide Personal Data in order to obtain access to or use of the
            Services or any functionality thereof, we will use your Personal Data to
            provide you with access to or use of the Services or functionality and to
            analyse your use of such Services or functionality. For instance, if you
            supply Personal Data relating to your identity or qualifications to use
            certain portions of the Services, we will use that information to make a
            decision as to granting you access to use such Services and to assess your
            ongoing qualification to use such Services.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            4.3 Internal Business Purposes.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may use your Personal Data for internal business purposes, including
            without limitation, to help us improve the content and functionality of
            the Services, to better understand our Users, to improve the Services, to
            protect against, identify or address wrongdoing, to enforce our Terms of
            Service, to manage your account and provide you with customer service, and
            to generally manage the Services and our business.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            4.4 Youni and Organiser Marketing.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may use your Personal Data for our marketing and advertising purposes,
            including (without limitation) by email, push notifications, SMS
            marketing, display media, and targeting other devices (such as tablets,
            mobile devices and televisions). We do this in order to inform you about
            services or events we believe might be of interest to you, develop
            promotional or marketing materials and display Youni or
            event-and-group-related content and advertising on or off the Services
            that we believe might be of interest to you. We may also do this on behalf
            of an Organiser, for example where your prior interactions with an
            Organiser suggest you may be interested in a particular type of event. See
            "Opt Out from Electronic Communications" below on how to opt out of
            various Youni marketing communications.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You may see advertisements for our Services on third party websites,
            including on social media platforms. We also provide Organisers with tools
            to help them display advertisements for their events on third party
            websites and social media platforms. Where you see an advertisement on a
            third party website or social media platform, this may be because we or
            the Organiser engaged the third party or social media platform to show the
            advertisement to Users, or to others who have similar attributes to our
            Users. In some cases, this involves sharing your email address or other
            contact details with the third party or social media platform so that they
            can identify you as one of our Users, or identify other individuals with
            similar attributes to you in order to show them advertisements for our
            Services (or for our Organiser’s events). If you no longer want your
            Personal Data to be used for these purposes please contact us at
            youni@tbcgroup.xyz. In the case of an Organiser’s advertising, you may
            need to contact the Organiser directly. For more information about how we
            and Organisers market on, and interact with social media sites, please
            refer to&nbsp;
            </span>
            
            <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>section 5.6</span>
            
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>.</span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            4.5 Organiser Emails and SMS.&nbsp;
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We allow Organisers to use our email tools and SMS tools to contact
            Consumers for their current and past events, and to contact Consumers who
            are Followers of their Group Page, so you may receive emails/SMS from our
            system that originate with such Organisers and that we send on their
            behalf. If you registered for an event or group on the Services, your
            email address, phone number, name, age, preferred pronouns, academic
            status and student/alumni status is available to that Organiser. However,
            Organisers may also import the information and data they have from
            external sources and send communications through the Services to those
            email addresses/phone numbers, and we will deliver those communications to
            those email addresses/phone numbers on the Organiser's behalf. The
            Organiser and not Youni is responsible for sending these communications.
            See "Opt Out from Electronic Communications" below on how to opt out of
            Organiser-initiated communications.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            4.6 Use of Interest-Based Data.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;We sometimes make inferences about the type of events, organisers,
            activities or businesses you may be interested in. We may use these
            inferences to help target advertising or customise recommendations to you,
            including on behalf of Organisers. We may do this on an aggregated or
            generalised basis. For instance, we may determine that our users who
            attend many comedy events also often attend or show interest in
            endurance-related events or content. We may direct (or assist in
            directing) content or recommendations related to both comedy and endurance
            to those Users.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            4.7 Other Purposes.&nbsp;
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If we intend to use any Personal Data in any manner that is not consistent
            with this Privacy Policy, you will be informed of such anticipated use
            prior to or at the time the Personal Data is collected or we will obtain
            your consent subsequent to such collection but prior to such use.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            4.8 Aggregated Personal Data.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            In an ongoing effort to understand and serve our Users better, we often
            conduct research on our customer demographics, interests and behaviour
            based on Personal Data and other information that we have collected. This
            research is typically conducted on an aggregate basis only that does not
            identify you. Once Personal Data is in an aggregated form, for purposes of
            this Privacy Policy, it becomes Non-Personal Data.
            </span>
        </p>
        <h2>
            <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>5-6</span>
            </strong>
        </h2>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            5. How We Disclose And Transfer Your Personal Data.
            </span>
        </h3>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            5.1 Background.&nbsp;
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We are not in the business of selling your Personal Data. We consider this
            information to be a vital part of our relationship with you. Therefore, we
            will not sell your Personal Data to third parties, including third party
            advertisers. There are certain circumstances in which we may disclose,
            transfer or share your Personal Data with certain third parties without
            further notice to you, as set forth in this Privacy Policy.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            5.2 Business Transfers.&nbsp;
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            As we develop our business, we might sell or buy businesses or assets. In
            the event of a corporate sale, merger, reorganisation, dissolution or
            similar event, Personal Data may be part of the transferred assets. We may
            also disclose your Personal Data in the course of due diligence for such
            an event. You acknowledge and agree that any successor to or acquirer of
            Youni (or its assets) will continue to have the right to use your Personal
            Data and other information in accordance with the terms of this Privacy
            Policy.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            5.3 Parent Companies, Subsidiaries and Affiliates.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may also share your Personal Data with our parent companies,
            subsidiaries and/or affiliates for purposes consistent with this Privacy
            Policy. Our parent companies, subsidiaries and affiliates will be bound to
            maintain that Personal Data in accordance with this Privacy Policy.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            5.4 Agents, Consultants and Service Providers.
            </span>
        </h4>
        <p>
            <span style={{ fontSize: "10.5pt" }}>
            We may share your Personal Data with our contractors and service providers
            who process Personal Data on behalf of Youni to perform certain
            business-related functions. These companies include our marketing
            agencies, online advertising providers, data enhancement and data services
            providers, database service providers, backup and disaster recovery
            service providers, email service providers, payment processing partners,
            customer support, tech support, hosting companies and others. When we
            engage another company to perform such functions, we may provide them with
            information, including Personal Data, in connection with their performance
            of such functions.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            5.5 Organisers.&nbsp;
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            In addition, when you register for an event or group, sign up for
            communications, enter a contest, or communicate with an Organiser or
            register and become a Follower of an Organiser Group Page, that Organiser
            will receive information you’ve provided us, such as your name, email,
            phone number, age, preferred pronouns, academic status and student/alumni
            information (identifying what you study/have studied and where). We are
            not responsible for the Organiser’s use of that information. For instance,
            when you register and become a Follower of your university’s law society,
            you become a member/Follower of that Organiser, who needs to be able to
            identify their members/Followers, and so you consent to that Organiser
            having access to your Personal Data. If you unfollow an Organiser, the
            Organiser will no longer have access to your Personal Data via Youni, but
            they may have stored or imported that data. We are not responsible for
            what they do with your data. Please check that the Organiser you are
            registering with have suitable GDPR policies in place. The Organiser may
            then send you marketing or other communications, which may be subject to
            its own, separate privacy policy. Likewise, if you provide your mobile
            phone number, you may receive information messages related to the service,
            event, activity or information in which you’ve expressed interest.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            When you purchase tickets to, register for or donate to an event, transfer
            an event ticket or registration to another person, enter a contest, become
            a Follower of an Organiser’s Group Page or otherwise input your Personal
            Data (such as through a web form), or otherwise communicate with an
            Organiser, or participate in or express interest in an Organiser, their
            Group Page or Organiser event or activity, you consent to that Organiser
            receiving the information you provide, including your Personal Data, and
            where a ticket or registration is transferred, the Personal Data of the
            transferee as well as data identifying you. By using our Services, and
            agreeing to our Terms of Service, you acknowledge that a necessary part of
            running a community event or Group Page is having access to your
            attendees’ or Followers’ Personal Data. Other third parties that are
            involved in or on whose behalf an event or activity is promoted, may
            receive that Personal Data as well. For example, as to fundraising pages,
            we may provide your Personal Data both to the Organiser of the fundraising
            page and the Organiser of the event to which the fundraising page is
            linked. In some instances, an Organiser may appoint a third party, which
            may or may not be affiliated with the Organiser, to create an event or
            fundraising page on its behalf, or to sponsor the Organiser’s event or
            Group Page (we call these third parties ("Third Party Organisers").
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            For example, and without limitation, a concert venue (the Organiser, in
            this case) may allow third party promoters or production companies (the
            Third Party Organisers) to create events that will be hosted at the
            Organiser's venue using its Youni account. In that case, we may provide
            your Personal Data to the Third Party Organisers on behalf of the
            Organisers. The same is true for contests and sweepstakes: we may provide
            your Personal Data to both the Organiser promoting the contest or
            sweepstakes, and any legal sponsor of the contest or sweepstakes (such as
            a brand sponsor).
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            For example, and without limitation, a society Group Page (the Organiser,
            in this case) may allow third party sponsors to co-host events or to
            sponsor and promote the Organiser and their Group Page. Youni may even
            help an Organiser and sponsor find one another, and facilitate a brand or
            businesses marketing itself via sponsoring an Organiser, their Group Page
            or an event. In that case, we may provide your Personal Data to the
            sponsor or Third Party Organisers on behalf of and on the instruction of
            the Organisers, but we are not liable for this, the Organiser is.&nbsp;
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We are not responsible for the actions of these Organisers, or their Third
            Party Organisers (or other downstream recipients of your Personal Data),
            with respect to your Personal Data. It is important that you review the
            applicable policies of the Organisers, and if applicable and available,
            their appointed Third Party Organisers or sponsors, of an event or group
            page (and the related fundraising page, if applicable) before signing up
            to an event or group page and in-so-doing consenting to giving your
            Personal Data or other information. Similarly, if you are a member of an
            Organiser's organisation within Youni, your Personal Data will be
            available to the Organiser and shared with those Third Party Organisers
            granted permission by the Organiser to view all members of the Organiser's
            organisation.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            5.6 Facebook, Social Media and Other Third Party Connections.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            a. Connecting Your Youni Account to Social Media Services. You may be able
            to connect your Youni account to your accounts on third party services
            like Facebook, in which case we may collect, use, disclose, transfer and
            store/retain information relating to your account with such third party
            services in accordance with this Privacy Policy. For example, if you
            connect with Facebook, we store your Facebook id, first name, last name,
            email, location, friends list and profile picture and use them to connect
            with your Facebook account to provide certain functionality on the
            Services, like recommending events that your Facebook friends are
            interested in and sharing the events you are interested in, or attending,
            with certain groups of people like your Facebook friends.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            b. “Liking” or “Following” Youni on Social Media. In addition, when you
            “like” or “follow” us on Facebook, Instagram, Twitter or other social
            media sites (to the extent we provide that capability), we may collect
            some information from you including your name, email address, and any
            comments or content you post relevant to us. We likewise may collect your
            information if you sign up for one of our promotions or submit information
            to us through social media sites.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            c. Facebook Plug-Ins and Links on Our Pages. Younis' own website may
            contain links to Facebook as well, such as through the Facebook “Like” or
            “Share” button or other social plug-ins. When you interact with these
            features and links, your browser will establish a direct link with the
            Facebook servers, and Facebook will receive information about your browser
            and activity, and may link it to your Facebook user account. For more
            information about how Facebook uses data, please see Facebook’s own
            policies.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            d. Additional Facebook Marketing and Connectivity by Organisers. In
            addition, if you are a member of Facebook (or another social media
            platform), and you provide Personal Data to an Organiser (such as in the
            ways described in Section 5.5), the Organiser may use that Personal Data
            to send you advertising and offers through Facebook (or another social
            media) platform, including when you are on Facebook or another social
            media platform. The Organiser may use tool that we provide and may also
            work with third parties to enable this data integration and advertising.
            Facebook and other social media platforms may provide a way to opt out of
            this type of advertising. Please review their user settings and support
            pages to learn more about how they help you to manage privacy and
            marketing choices.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            e. Third party services and integrations. Youni may provide you with
            opportunities to contract directly with third parties, and/or to integrate
            with third party services or applications, through our platform. In such
            instances, we will disclose your Personal Data to other entities in order
            to fulfil a request by you, or to provide services you have requested.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            5.7 Legal Requirements.&nbsp;
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may disclose your Personal Data if required to do so by law in order to
            (for example) respond to a subpoena or request from law enforcement, a
            court or a government agency (including in response to public authorities
            to meet national security or law enforcement requirements), or in the good
            faith belief that such action is necessary to (a) comply with a legal
            obligation, (b) protect or defend our rights, interests or property or
            that of third parties, (c) prevent or investigate possible wrongdoing in
            connection with the Services, (d) act in urgent circumstances to protect
            the personal safety of Users of the Services or the public, or (e) protect
            against legal liability.
            </span>
        </p>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            6. How We Store Your Personal Data.&nbsp;
            </span>
        </h3>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may store Personal Data itself or such information may be stored by
            third parties to whom we have transferred it in accordance with this
            Privacy Policy. We take what we believe to be reasonable steps to protect
            the Personal Data collected via the Services from loss, misuse,
            unauthorised use, access, inadvertent disclosure, alteration and
            destruction. However, no network, server, database or Internet or email
            transmission is ever fully secure or error free. Therefore, you should
            take special care in deciding what information you send to us
            electronically. Please keep this in mind when disclosing any Personal
            Data.
            </span>
        </p>
        <h2>
            <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>7-8</span>
            </strong>
        </h2>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            7. How You Can Access, Update, Correct or Delete Your Personal Data.
            </span>
        </h3>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You can access or delete your Personal Data stored by us. If you are a
            registered User, you can access your Personal Data stored by us by
            emailing youni@tbcgroup.xyz to download a copy of your Personal Data in
            machine readable form. If you are a registered User, you can request to
            delete your Personal Data stored by us by deleting your account and then
            contacting youni@tbcgroup.xyz. You can also edit some of your Personal
            Data directly through your account. In certain cases, you can ask us to
            correct and update any inaccurate Personal Data using the contact
            information below, and we will consider your request in accordance with
            applicable laws.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If a Consumer initiates a data deletion request, Youni is authorised to
            delete or anonymize Personal Data of the requesting Consumer from the
            Services even if that means removing its availability to the Organiser
            through the Services. However, if you are a Consumer, you understand that
            even if Youni deletes or anonymises your Personal Data upon your request
            or pursuant to this Policy, your Personal Data may still be available in
            the Organiser's own databases if transmitted to the Organiser prior to
            Youni receiving or taking action on any deletion or anonymisation
            activity.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Unregistered Users may also access, update, correct or delete Personal
            Data and exercise these rights using the contact information below. We
            will consider and respond to all requests in accordance with applicable
            law.
            </span>
        </p>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            8. How Long We Retain Your Personal Data.
            </span>
        </h3>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may retain your Personal Data as long as you are registered to use the
            Services. You may delete your account by visiting the Account Settings
            page. However, we may retain Personal Data for an additional period as is
            permitted or required under applicable laws. Even if we delete your
            Personal Data it may persist on backup or archival media for an additional
            period of time for legal, tax or regulatory reasons or for legitimate and
            lawful business purposes. For sales customers and prospects, we retain
            your data as long as we maintain a relationship with you and for a limited
            period of thereafter as necessary to meet business and legal requirements.
            </span>
        </p>
        <p>
            <br />
        </p>
        <h2>
            <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>9-10</span>
            </strong>
        </h2>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            9. Cookies, Pixels Tags, Local Shared Objects, Web Storage And Similar
            Technologies.&nbsp;
            </span>
        </h3>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Please refer to our
            </span>
            <span
            style={{
                color: "#39364f",
                fontSize: "10.5pt",
            }}
            >
            &nbsp;
            </span>
            <span
                style={{
                color: "#1155cc",
                fontSize: "10.5pt",
                }}
            >
                Cookie Statement
            </span>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;for more information about our use of cookies and other similar
            tracking technologies.
            </span>
        </p>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            10. Your Choices.
            </span>
        </h3>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You have several choices available when it comes to your Personal Data:
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            10.1 Limit the Personal Data You Share.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You can browse the Services without sharing any Personal Data with
            Organisers as you are not required to either follow/subscribe to a group
            or register for an event(other than Automatic Data to the extent it is
            considered Personal Data under applicable laws). If you choose not to
            provide any Personal Data or limit the Personal Data you provide, you may
            not be able to use certain functionality of the Services. For instance, in
            order to buy tickets as a Consumer, your name, email address and other
            Personal Data will be required by the Organiser.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            10.2 Opt Out from Electronic Communications.
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            (a) Youni Marketing Communications. Where it is in accordance with your
            marketing preferences, Youni may send you electronic communications
            marketing or advertising the Services themselves or events/Group Pages on
            the Services, to the extent you have registered for the Services or
            purchased a ticket and/or registration to an event/group listed on the
            Services. If via email, you can also "opt out" of receiving these
            electronic email communications by clicking on the "Unsubscribe" link at
            the bottom of any such email communication. In addition, you may also
            manage your email preferences at any time by logging in (or signing up and
            then logging in), clicking on "Account" and then "Email Preferences." If
            Youni sends you SMS electronic communications that are marketing or
            advertising Youni’s Services, and are not for instance One Time Passwords
            or important communications such as cancelled events, or that are not
            Organiser-initiated owing to you following that Organiser, or attending
            their events, that message will come with the ability to “Unsubscribe”,
            usually a link or an action at the end/bottom of the SMS.&nbsp;
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            (b) Organiser-initiated Communications. Organisers may use our
            email/SMS/push notification tools to send electronic communications to
            their Followers and their event attendees, including non-Follower
            Consumers who are registered for their upcoming events. Although these
            electronic communications are sent through our system, Youni does not
            determine the content or the recipients of these electronic
            communications. Organisers are required to use our electronic
            communication tools only in accordance with all applicable laws. With
            regards to email notifications owing to a User following an Organiser’s
            Group Page, Users can unfollow that Organiser via the app to opt out of
            said electronic communications. With regards to email notifications owing
            to a User attending one of the Organiser’s events, User’s can deregister
            from that event to opt out. There may also be an “Unsubscribe” link at the
            bottom of the email to opt out of electronic communications via email from
            that Organiser. With regards to push notifications, Consumers can control
            their notifications from account settings in the mobile app. With regards
            to any SMS received from Organisers, Consumers will be presented with an
            “Unsubscribe” link or call to action that will make it clear how to
            unsubscribe. In addition, in so far as communications relating to being a
            Follower of a Group Page, Consumers can "opt out" of electronic
            communications from the particular Organiser by unsubscribing to that
            Organiser.
            </span>
        </p>
        <p>
            <br />
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>.&nbsp;</span>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            <br />
            </span>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            <br />
            <br />
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            (c) Social Notifications. If you connect your Facebook account or sign up
            for other social media integrations whose product features include social
            notifications (i.e., updates on what your friends are doing on the
            Services), you will receive these social notifications. You can manage
            these social notifications by disconnecting such integration.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            (d) Transactional or Responsive Communications. Certain electronic
            communications from Youni or Youni Organisers are responsive to your
            requests. For instance, if you are a Consumer, we must email you your
            ticket or registration on behalf of the Organiser when you purchase such
            ticket or registration. As a further example, if you email our customer
            support department, we will return your email. Notwithstanding any
            unsubscribe election that you have made, you will still receive these
            transactional or responsive emails. You can stop receiving these types of
            emails only by&nbsp;
            </span>
            <span
            style={{
                color: "#39364f",
                fontSize: "10.5pt",
            }}
            >
            contacting us
            </span>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            . By electing to stop receiving all electronic communications from us or
            through our system, you will no longer receive any updates on events you
            have created (including payout issues) or on events you are registered to
            attend (including emails with your tickets). We do not recommend that you
            do this unless you plan to no longer use the Services, are not currently
            registered for an event, are not currently organising an event, are not
            currently a Follower of a Group Page, and will have no need to receive
            further communications from us or through our system.
            </span>
        </p>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            (e) Retention. It may take up to forty-eight (48) hours for us to process
            an unsubscribe request. Even after you opt out of all electronic
            communications, we will retain your Personal Data in accordance with this
            Privacy Policy, however, we will no longer use it to contact you. However,
            Organisers who have received your Personal Data in accordance with this
            Privacy Policy may still use that Personal Data to contact you in
            accordance with their own privacy policies, but they may not use our
            system to do so.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            10.3 Do Not Track.&nbsp;
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We currently do not participate in any "Do Not Track" frameworks that
            would allow us to respond to signals or other mechanisms from you
            regarding the collection of your Personal Data.
            </span>
        </p>
        <h2>
            <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>11-12</span>
            </strong>
        </h2>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            11. Exclusions.
            </span>
        </h3>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            11.1 Personal Data Provided to Others.&nbsp;
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            This Privacy Policy does not apply to any Personal Data that you provide
            to another User or visitor through the Services or through any other
            means, including to Organisers on event pages or information posted by you
            to any public areas of the Services.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            11.2 Third Party Links.&nbsp;
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            This Privacy Policy applies only to the Services. The Services may contain
            links to other websites not operated or controlled by us (the "Third Party
            Sites"). The policies and procedures we described here do not apply to the
            Third Party Sites. The links from the Services do not imply that we
            endorse or have reviewed the Third Party Sites. We suggest contacting
            those sites directly for information on their privacy policies.
            </span>
        </p>
        <h4>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
            12. Children - Children's Online Privacy Protection Act.&nbsp;
            </span>
        </h4>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We do not knowingly collect Personal Data from children under the age of
            thirteen (13). If you are under the age of thirteen (13), please do not
            submit any Personal Data through the Services. We encourage parents and
            legal guardians to monitor their children's Internet usage and to help
            enforce our Privacy Policy by instructing their children never to provide
            Personal Data through the Services without their permission. If you have
            reason to believe that a child under the age of 13 has provided Personal
            Data to us through the Services, please
            </span>
            <span
            style={{
                color: "#39364f",
                fontSize: "10.5pt",
            }}
            >
            &nbsp;contact us an
            </span>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            d we will endeavour to delete that information from our databases.
            </span>
        </p>
        <h2>
            <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>13-14</span>
            </strong>
        </h2>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            13. International Privacy Laws.&nbsp;
            </span>
        </h3>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you are visiting the Services from outside the United Kingdom, please
            be aware that you are sending information (including Personal Data) to the
            United Kingdom where we are located. That information may then be
            transferred within the United Kingdom or back out of the United Kingdom to
            other countries outside of your country of residence, depending on the
            type of information and how it is stored by us. These countries (including
            the United Kingdom) may not necessarily have data protection laws as
            comprehensive or protective as those in your country of residence;
            however, our collection, storage and use of your Personal Data will at all
            times continue to be governed by this Privacy Policy.&nbsp;
            </span>
        </p>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            14. Changes To This Privacy Policy.&nbsp;
            </span>
        </h3>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The Services and our business may change from time to time. As a result,
            at times it may be necessary for us to make changes to this Privacy
            Policy. We reserve the right, in our sole discretion, to update or modify
            this Privacy Policy at any time (collectively, "Modifications").
            Modifications to this Privacy Policy will be posted to the Site with a
            change to the "Updated" date at the top of this Privacy Policy. In certain
            circumstances Youni may, but need not, provide you with additional notice
            of such Modifications, such as via email or with in-Service notifications.
            Modifications will be effective thirty (30) days following the "Updated"
            date or such other date as communicated in any other notice to you. Please
            review this policy periodically, and especially before you provide any
            Personal Data. This Privacy Policy was updated on the date indicated
            above. Your continued use of the Services following the effectiveness of
            any Modifications to this Privacy Policy constitutes acceptance of those
            Modifications. If any Modification to this Privacy Policy is not
            acceptable to you, you should cease accessing, browsing and otherwise
            using the Services.
            </span>
        </p>
        <h2>
            <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>15-16</span>
            </strong>
        </h2>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            15. Dispute Resolution.&nbsp;
            </span>
        </h3>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you have a complaint about Youni's privacy practices you should write
            to us at: TBC GROUP LTD, 61 The Ridgeway, NW11 8QL, or by email
            youni@tbcgroup.xyz. We will take reasonable steps to work with you to
            attempt to resolve your complaint. Residents of the EEA, Switzerland or
            the UK who believe that their information has not been processed in
            compliance with the Privacy Shield Principles may raise their complaints
            in a number of ways. For further information, please see our&nbsp;
            </span>
            <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
                Privacy Shield Notice
            </span>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>.&nbsp;</span>
        </p>
        <h3>
            <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            16. EEA, SWITZERLAND AND UK ONLY&nbsp;
            </span>
        </h3>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The EU General Data Protection Regulation (GDPR) and UK GDPR law requires
            Us and Organisers using the Service to provide Users with more information
            about the processing of their Personal Data. Here is what you need to
            know: Legal grounds for processing your Personal Data. The GDPR requires
            us to tell you about the legal ground we're relying on to process any
            Personal Data about you. The legal grounds for us processing your Personal
            Data for the purposes set out in Section 4 above will typically be
            because:
            </span>
        </p>
        <ul>
            <li style={{ listStyleType: "disc", color: "#39364f", fontSize: "12pt" }}>
            <p>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                you provided your consent;
                </span>
            </p>
            </li>
            <li style={{ listStyleType: "disc", color: "#39364f", fontSize: "12pt" }}>
            <p>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                it is necessary for our contractual relationship;
                </span>
            </p>
            </li>
            <li style={{ listStyleType: "disc", color: "#39364f", fontSize: "12pt" }}>
            <p>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                the processing is necessary for us to comply with our legal or
                regulatory obligations; and/or
                </span>
            </p>
            </li>
            <li style={{ listStyleType: "disc", color: "#39364f", fontSize: "12pt" }}>
            <p>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                the processing is in our legitimate interest as a University Community
                &amp; Events organising and ticketing platform (for example, to
                protect the security and integrity of our systems and to provide you
                with customer service, etc.).
                </span>
            </p>
            </li>
        </ul>
        <p>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Transfers of Personal Data As TBC GROUP LTD is a global company, or is
            intending to be a global company, we may need to transfer your Personal
            Data outside of the country from which it was originally provided. This
            may be intra-group or to third parties that we work with who may be
            located in jurisdictions outside the EEA, Switzerland and the UK which
            have no data protection laws or laws that are less strict compared with
            those in Europe. Whenever we transfer Personal Data outside of the EEA,
            Switzerland or the UK, we take legally required steps to make sure that
            appropriate safeguards are in place to protect your Personal Data. This
            includes the use of Standard Contractual Clauses issued pursuant to
            Commission Implementing Decision (EU) 2021/914 of June 4, 2021 of the
            European Parliament and of the Council, and for transfers from the UK, the
            UK International Data Transfer Addendum (IDTA). Personal Data retention.
            We retain your Personal Data for as long as necessary to provide you with
            our Services, or for other important purposes such as complying with legal
            obligations, resolving disputes, and enforcing our agreements. If you have
            an account with us, we will typically retain your Personal Data for a
            period of ninety (90) days after you have requested that your account is
            closed or if it's been inactive for seven (7) years. Your rights. Data
            protection law provides you with rights in respect of Personal Data that
            we hold about you, including the right to request a copy of the Personal
            Data, request that we rectify, restrict or delete your Personal Data,
            object to profiling and unsubscribe from marketing communications. For the
            most part, you can exercise these rights by logging in and visiting the My
            Account page or changing the "cookie settings" in your browser (see
            our&nbsp;
            </span>
            <span
            style={{
                color: "#39364f",
                fontSize: "10.5pt",
            }}
            >
            Cookie Statemen
            </span>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            t for more information). If you can't find what you're looking for in the
            My Account page, please contact us using the contact information set out
            in Section 15 above. Please note that requests to exercise data protection
            rights will be assessed by us on a case-by-case basis. There may be
            circumstances where we are not legally required to comply with your
            request because of the laws in your jurisdiction or because of exemptions
            provided for in data protection legislation. If you have a complaint about
            how we handle your Personal Data, please get in touch with us as set forth
            in Section 15 to explain. If you are not happy with how we have attempted
            to resolve your complaint, you may contact the relevant data protection
            authority. Youni as a data controller and a data processor EU data
            protection law makes a distinction between organisations that process
            Personal Data for their own purposes (known as "data controllers") and
            organisations that process personal data on behalf of other organisations
            (known as "data processors"). If you have a question or complaint about
            how your Personal Data is handled, these should always be directed to the
            relevant data controller since they are the ones with primary
            responsibility for your Personal Data. Youni may act as either a data
            controller or a data processor in respect of your Personal Data, depending
            on the circumstances. For example, if you create an account with us to
            organise your events, Youni will be a data controller in respect of the
            Personal Data that you provide as part of your account. We will also be a
            data controller of the Personal Data that we have obtained about the use
            of the Applications or Youni Properties, which could relate to Organisers
            or Consumers. We use this to conduct research and analysis to help better
            understand and serve Users of the Services as well as to improve our
            platform and provide you with more targeted recommendations about events
            and groups we think may be of interest to you. However, if you register
            for an event or group as a Consumer, we will process your Personal Data to
            help administer that event or group on behalf of the Organiser (for
            example, sending confirmation, promotional and feedback emails, processing
            payments, etc.) and to help the Organiser target, and understand the
            success of, their event and event planning (for example, providing event
            reports, using analytics to gain insights into the effectiveness of
            various sales channels, etc.). In these circumstances, Youni merely
            provides the tools for Organisers; Youni does not decide what Personal
            Data to request on registration forms, nor is it responsible for the
            continued accuracy of any Personal Data provided. Any questions that you
            may have relating to your Personal Data and your rights under data
            protection law should therefore be directed to the Organiser as the data
            controller, not to Youni.
            </span>
        </p>
        <p>
            <br />
        </p>
        </>
    </div>
  )
}