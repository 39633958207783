import Member from '../../components/_contact/member';
import styles from '../../styles/contactTeam.module.scss';

const Team = () => {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1>Our team</h1>

        <div className={styles.members}>
          <Member
            img="/assets/omar.png"
            name="Omar"
            role="Co-Founder + CEO"
            email="omar@tbcgroup.xyz"
          />
          <Member
            img="/assets/georgia.png"
            name="Georgia"
            role="Co-Founder + CCO"
            email="georgia@tbcgroup.xyz"
          />
          {/* <Member
            img="/assets/toby.png"
            name="Toby"
            role="CTO"
            email="toby@tbcgroup.xyz"
          />
          <Member
            img="/assets/qu.png"
            name="Qu"
            role="Head of Events + Sales"
            email="qu@tbcgroup.xyz"
          /> */}
          <Member
            img="/assets/ali.png"
            name="Ali"
            role="Lead Developer"
            email="ali@tbcgroup.xyz"
          />
          <Member
            img="/assets/anita.png"
            name="Anita"
            role="Student Director"
            email=""
          />
        </div>
      </div>
    </section>
  );
};

export default Team;
