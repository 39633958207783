import Slider from "react-slick";
import styles from "../../styles/carouselTwo.module.scss";

const NextArrow = (props) => {
  const { onClick, currentSlide, slideCount } = props;
  return (
    <div className={styles.next} onClick={onClick}>
      <img
        src={
          currentSlide + 2 === slideCount
            ? "/assets/arrow_2_copy.svg"
            : "/assets/arrow_2.svg"
        }
        alt="arrow"
      />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick, currentSlide } = props;
  return (
    <div className={styles.prev} onClick={onClick}>
      <img
        src={
          currentSlide === 0
            ? "/assets/arrow_1_copy.svg"
            : "/assets/arrow_1.svg"
        }
        alt="arrow"
      />
    </div>
  );
};

const CarouselTwo = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.6,
          slidesToScroll: 2.6,
        },
      },
      {
        breakpoint: 1175,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2.1,
        },
      },
      {
        breakpoint: 878,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1.8,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 668,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 542,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 410,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <h1>
            Find your <span>people.</span>
          </h1>
        </div>

        <div className={styles.bottom}>
          <div className={styles.bottomWrapper}>
            <Slider {...settings}>
              <div>
                <img
                  src="/assets/card_2.svg"
                  alt="iphone"
                  className={styles.img}
                />
              </div>
              <div>
                <img
                  src="/assets/card_1.svg"
                  alt="iphone"
                  className={styles.img}
                />
              </div>
              <div>
                <img
                  src="/assets/card_3.svg"
                  alt="iphone"
                  className={styles.img}
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarouselTwo;
