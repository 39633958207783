import styles from "../../styles/about.module.scss";

const About = () => {
  return (
    <>
      <section className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <h3>
              What We Do
              <img src="/assets/eclipse_1.png" alt="eclipse" />
            </h3>
            <p>
              We provide the university community with a hub to build and boost
              their societies, groups and events. One centralised platform for
              all university extra-curricular life. <br /> <br /> We connect the
              community through specific features to tackle all university pain
              points.
            </p>
          </div>
          <div className={styles.right}>
            <h3>
              What we think
              <img src="/assets/eclipse_2.png" alt="eclipse" />
            </h3>
            <p>
              University is often called the best years of your life. So why are
              students increasingly feeling more isolated and disconnected from
              their community? <br /> <br />
              We believe we can change that. We make it as easy as possible for
              students to find their passions and people.
            </p>
          </div>
        </div>
      </section>
      <section className={styles.container}>
        <div className={styles.box}>
          <p className={styles.boxText}>
            We want to showcase and facilitate the best part the university
            experience;  the ability to try things out and connect with people
            IRL.{" "}
          </p>
        </div>
      </section>
    </>
  );
};

export default About;
