import styles from "../../styles/launch.module.scss";

const Launch = () => {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1>
          Propel your{" "}
          <span>
            project.
            <img src="/assets/launchVector.png" alt="vector" />
          </span>
        </h1>

        <button>Download now</button>
      </div>
    </section>
  );
};

export default Launch;
