import Layout from "../layouts/layout";
import Main from "../sections/_whyyouni/main";
import More from "../sections/_whyyouni/more";

const WhyYouni = () => {
  return (
    <Layout>
      <div>
        <Main />
        <More />
      </div>
    </Layout>
  );
};

export default WhyYouni;
