import Layout from '../layouts/layout';
import styles from '../styles/contact.module.scss';
import contactTouch from '../styles/contactTouch.module.scss';
import { TermsOfService } from '../sections/legal/termsOfService';
import { PrivacyPolicy } from '../sections/legal/privacyPolicy';
import { CommunityPolicy } from '../sections/legal/communityPolicy';
import { OrganiserAgreement } from '../sections/legal/organiserAgreement';
import { CookieStatement } from '../sections/legal/CookieStatement';
import { TrademarkPolicy } from '../sections/legal/TrademarkPolicy';
import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Legal = () => {
  const { hash } = useLocation();
  const privacyPolicyRef = useRef(null);
  const termsOfServiceRef = useRef(null);
  const organiserAgreementRef = useRef(null);
  const communityPolicyRef = useRef(null);
  const cookieStatementRef = useRef(null);
  const trademarkPolicyRef = useRef(null);

  const hashNav = {
    privacyPolicy: privacyPolicyRef,
    termsOfService: termsOfServiceRef,
    organiserAgreement: organiserAgreementRef,
    communityPolicy: communityPolicyRef,
    cookieStatement: cookieStatementRef,
    trademarkPolicy: trademarkPolicyRef
  };

  useEffect(() => {
    if (hash && hashNav.hasOwnProperty(hash.slice(1))) {
      // reason for setTimeout
      // https://github.com/facebook/react/issues/23396
      setTimeout(() => {
        hashNav[hash.slice(1)].current.scrollIntoView(true);
      }, 0);
    }
  }, [hash]);

  return (
    <Layout>
      <div className={styles.container}>
        <section className={contactTouch.container}>
          <div className={contactTouch.wrapper}>
            <h1>
              All things <span>legal.</span>
            </h1>

            <div className={contactTouch.text}>
              <img
                src="/assets/contactVector_1.svg"
                alt="vector"
                className={contactTouch.left}
              />
              <p className={contactTouch.lgScreen}>
                <a href="#termsOfService"> Terms of Service </a> <br />
                <a href="#privacyPolicy"> Privacy Policy </a> <br />
                <a href="#organiserAgreement"> Organiser Agreement </a> <br />
                <a href="#communityPolicy"> Community Guidelines </a> <br />
                <a href="#cookieStatement"> Cookie Statement </a> <br />
                <a href="#trademark"> Trademark & Copyright Policy</a>{' '}
              </p>
              <p className={contactTouch.mbScreen}>
                <a href="#termsOfService"> Terms of Service </a> <br />
                <a href="#privacyPolicy"> Privacy Policy </a> <br />
                <a href="#organiserAgreement"> Organiser Agreement </a> <br />
                <a href="#communityPolicy"> Community Guidelines </a> <br />
                <a href="#cookieStatement"> Cookie Statement </a> <br />
                <a href="#trademark"> Trademark & Copyright Policy</a>{' '}
              </p>
              <img
                src="/assets/contactVector_2.png"
                alt="vector"
                className={contactTouch.right}
              />
            </div>
          </div>
        </section>
        <section className={contactTouch.bottomContainer}>
          <div ref={termsOfServiceRef}>
            <h1 className={contactTouch.mainHeading}>
              Terms of{' '}
              <span className={contactTouch.coloredHeading}> Service</span>
            </h1>
            <TermsOfService />
          </div>
          <div ref={privacyPolicyRef}>
            <h1 className={contactTouch.mainHeading}>
              Privacy{' '}
              <span className={contactTouch.coloredHeading}> Policy</span>
            </h1>
            <PrivacyPolicy />
          </div>
          <div ref={organiserAgreementRef}>
            <h1 className={contactTouch.mainHeading}>
              Organiser{' '}
              <span className={contactTouch.coloredHeading}> Agreement</span>
            </h1>
            <OrganiserAgreement />
          </div>
          <div ref={communityPolicyRef}>
            <h1 className={contactTouch.mainHeading}>
              Community{' '}
              <span className={contactTouch.coloredHeading}> Guidelines</span>
            </h1>
            <CommunityPolicy />
          </div>
          <div ref={cookieStatementRef}>
            <h1 className={contactTouch.mainHeading}>
              Cookie{' '}
              <span className={contactTouch.coloredHeading}> Statement</span>
            </h1>
            <CookieStatement />
          </div>
          <div ref={trademarkPolicyRef}>
            <h1 className={contactTouch.mainHeading}>
              Trademark & Copyright{' '}
              <span className={contactTouch.coloredHeading}> Policy</span>
            </h1>
            <TrademarkPolicy />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Legal;
