import styles from "../../styles/studentMain.module.scss";

const Main = () => {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1>
          All you need
          <br />
          for <span>uni life.</span>
        </h1>

        <div className={styles.cards}>
          <div className={styles.row}>
            <div className={styles.card}>
              <img src="/assets/student_img_1.png" alt="discover" />
              <h2>Discover</h2>
              <p>All uni groups and events (or build your own).</p>
            </div>
            <div className={styles.card}>
              <img src="/assets/student_img_2.png" alt="life" />
              <h2>Get Involved</h2>
              <p>Sign up to groups, buy tickets to events.</p>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.card}>
              <img src="/assets/student_img_3.png" alt="wallet" />
              <h2>Communicate</h2>
              <p>Join discussions with your favourite groups.</p>
            </div>
            <div className={styles.card}>
              <img src="/assets/student_img_4.png" alt="profile" />
              <h2>Your space</h2>
              <p>Your calendar, your tix, your roles.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
