import Slider from "react-slick";
import styles from "../../styles/carouselOne.module.scss";

const NextArrow = (props) => {
  const { onClick, currentSlide, slideCount } = props;
  return (
    <div className={styles.next} onClick={onClick}>
      <img
        src={
          Math.round(currentSlide) + 2 === slideCount
            ? "/assets/arrow_2_copy.svg"
            : "/assets/arrow_2.svg"
        }
        alt="arrow"
      />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick, currentSlide, slideCount } = props;
  return (
    <div className={styles.prev} onClick={onClick}>
      <img
        src={
          currentSlide === 0
            ? "/assets/arrow_1_copy.svg"
            : "/assets/arrow_1.svg"
        }
        alt="arrow"
      />
    </div>
  );
};

const CarouselOne = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => (
      <div>
        <ul
          style={{
            position: "absolute",
            left: "-430px",
            bottom: "27px",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1.8,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <h1 className={styles.webHeading}>
            Less time
            <br />
            on here,
            <br />
            more time
            <br />
            <span>out there.</span>
          </h1>
          <h1 className={styles.mobHeading}>
            Less time on here,
            <br />
            more time <span>out there.</span>
          </h1>
        </div>

        <div className={styles.right}>
          <div>
            <Slider {...settings}>
              <div>
                <img src="/assets/iphone_1.svg" alt="iphone" />
              </div>
              <div>
                <img src="/assets/iphone_2.svg" alt="iphone" />
              </div>
              <div>
                <img
                  src="/assets/slider_3.svg"
                  alt="iphone"
                />
              </div>
              {/* <div>
                  <img src="/assets/iphone_1.png" alt="iphone" />
              </div> */}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarouselOne;
