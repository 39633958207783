import Layout from "../layouts/layout"
import Help from "../sections/_organisers/help"
import Hero from "../sections/_organisers/hero"
import styles from "../styles/organisers.module.scss"

const Organisers = () => {
    return (
        <Layout>
            <div className={styles.container}>
                <Hero />
                <Help />
            </div>
        </Layout>
    )
}

export default Organisers