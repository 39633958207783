import contactTouch from "../../styles/contactTouch.module.scss";

export const CommunityPolicy = () => {
  return (
    <div className={contactTouch.content}>
      <>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Last Updated: September 15, 2023.
          </span>
        </p>
        <h3>
          <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            Youni was created to help all students make the most out of their
            time at University. We believe in connecting through mutual
            interest, experience and in person activity. We want to foster an
            inclusive community platform, that allows for all students,
            regardless of their backgrounds, their identity and their views to
            live symbiotically, and with respect to everyone’s differing points
            of view, but without tolerance for bullying, hate speech and abusive
            material. &nbsp;
          </span>
        </h3>
        <h3>
          <span style={{ color: "#434343", fontSize: "13.999999999999998pt" }}>
            Our platform offers an open opportunity for anyone to gather for
            their chosen purpose — whether that be to explore passions, raise
            money, learn from others or protest. We may not agree with every
            view or opinion expressed at every event or by every group, but we
            strongly believe in the fundamental right to free speech and
            assembly. In addition, in the UK it is a legal requirement set by
            the Government to allow all points of view to be expressed at
            University. We have to comply with the spirit of this law, whilst
            striving to ensure we all have a bare minimum level of tolerance and
            respect towards one another. To help you understand what is
            acceptable on Youni and what crosses the line, we created these
            Community Guidelines. We aren’t perfect ourselves, but are striving
            to create a platform for University Communities that carefully
            balances the right to one’s point of view and freedoms with the
            necessity of protecting people from hate.&nbsp;
          </span>
        </h3>
        <p>
          <br />
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            The best way that our platform can ensure accountability and respect
            is by preventing fake accounts, anonymous accounts and troll
            accounts. We will soon be verifying student status once we can to
            ensure that everyone on your University’s Youni domain is who they
            say they are. That way, if anyone is abusive, you can report them to
            your University, who ultimately are the arbitrators of the rules at
            University, with a system in place to deal with these issues, and
            the tools to punish those that fall foul of their rules.&nbsp;
          </span>
          <span style={{ fontSize: "11pt" }}>
            <br />
          </span>
          <span style={{ fontSize: "11pt" }}>
            <br />
          </span>
          <span style={{ fontSize: "11pt" }}>
            We believe by creating a verified community space, we rip off the
            cloak of invisibility that fake accounts and anonymous trolls hide
            behind, ensuring that whatever you post or say on Youni you will be
            accountable for.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            That being said, we also will take action against accounts that
            break our Community Guidelines. The following is banned from our
            Youni Community:&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>Trolling and spamming&nbsp;</span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            Fake accounts/fake names/fake emails/fake university
            information&nbsp;
          </span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>Harassment</span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>Hate speech</span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            Breaching your University’s code of conduct or other regulation
          </span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            Use of copyright material that you don’t have legal authority to use
          </span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            Reselling of tickets to those outside of the verified community
            &nbsp;
          </span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>Hacking</span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            Providing account access to others
          </span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            Sex work, pornographic content and nudity;&nbsp;
          </span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            Racial slurs or other racist content
          </span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            Homophobic, transphobic, misogynistic slurs or other content
          </span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            Terrorism, banned substances, weapons, inciting violence, promoting
            violence or any other illicit or illegal activity&nbsp;
          </span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>Misinformation</span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            Users also warrant that they aren’t misrepresenting themselves. They
            warrant that they are in fact a student/alumni at the University
            they sign up for, and that should we require verification of their
            identity or academic credentials, it will be provided.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>
            <span style={{ fontSize: "11pt" }}>
              Warranty that you aren’t misrepresenting yourself
            </span>
          </strong>
          <span style={{ fontSize: "11pt" }}>&nbsp;</span>
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            Warranty that you are a student of the university you say you are,
            or that you are an alumni of the university you say you’re an alumni
            of&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            I agree to provide verification, as per Youni’s request, should it
            be asked of me of my University status. Failure to do so will result
            in account deletion, without any refunds or compensation for any
            lost transactions/tickets/memberships etc&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ fontSize: "11pt" }}>
            I understand that Youni is not liable for any fraudulent events or
            transactions that occur via its platform, it is simply a
            marketplace. Whilst Youni might take some action occasionally to try
            prevent fraud/breach of contract etc, I understand that this is in
            no way an admission of Youni’s legal or moral responsibility. When
            making a purchase of a ticket for an event or for any other goods
            and services that Youni facilitate the purchase of, I understand
            that in the case where the value being sought is provided by a third
            party, ie a society or group, my contract is with that society and
            group, and Youni is in no way responsible for ensuring that value is
            provided.&nbsp;
          </span>
          <span style={{ fontSize: "11pt" }}>
            <br />
          </span>
          <span style={{ fontSize: "11pt" }}>
            Youni’s only contractual duty is, when paid a booking fee, to
            immediately generate a QR code, which can be read by Youni’s QR code
            scanner. If a ticket is refunded for whatever reason, however,
            either by the event organiser or by Youni itself, I understand that
            Youni is fully entitled to keep the booking fee, regardless of the
            reason.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
      </>
    </div>
  );
}
