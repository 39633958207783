import contactTouch from "../../styles/contactTouch.module.scss";

export const CookieStatement = () => {
  return (
    <div className={contactTouch.content}>
      <>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Last Updated: September 15, 2023.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            This Cookie Statement explains how TBC GROUP LTD (“Youni,” “we,”
            “us,” or “our”) uses cookies and other similar technologies to
            recognise you when you visit our Sites or Applications ("Online
            Properties") and in the course of using any of our Services. It
            explains what these technologies are and why we use them, as well as
            your rights to control our use of them. Any capitalised terms that
            are not also defined in this Cookie Statement are defined in our
            Terms of Service.
          </span>
        </p>
        <p>
          <strong>
            <span style={{ color: "#13002d", fontSize: "18pt" }}>
              In this article
            </span>
          </strong>
        </p>
        <ul>
          <li
            style={{
              listStyleType: "none",
              color: "#3659e3",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#3659e3", fontSize: "12pt" }}>
                What are cookies?
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              color: "#3659e3",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#3659e3", fontSize: "12pt" }}>
                Why do we use cookies?
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              color: "#3659e3",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#3659e3", fontSize: "12pt" }}>
                Types of cookies we use
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              color: "#3659e3",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#3659e3", fontSize: "12pt" }}>
                Duration of cookies stored on your device
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              color: "#3659e3",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#3659e3", fontSize: "12pt" }}>
                Cookie management
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              color: "#3659e3",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#3659e3", fontSize: "12pt" }}>
                How often will you update this Cookie Statement?
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              color: "#3659e3",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#3659e3", fontSize: "12pt" }}>
                Where can I get further information?
              </span>
            </p>
          </li>
        </ul>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              What are cookies?
            </span>
          </strong>
        </h2>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Cookies are small data files that are placed on your computer or
            mobile device when you visit a website. The term “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>cookie</span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ” is used in this Cookie Statement in the broad sense to include
            cookies and all similar techniques and technologies, including web
            beacons. &nbsp;Web beacons (sometimes called "tracking pixels" or
            "clear gifs") are tiny graphics files that contain a unique
            identifier that enable us to recognize when someone has visited our
            Online Properties or taken an action in relation to our Online
            Properties.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Cookies are widely used by website owners in order to make their
            websites work, or to work more efficiently, as well as to provide
            reporting information.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Cookies set by the website owner (in this case, Youni) are called
            "first-party cookies." Cookies set by parties other than the website
            owner are called "third-party cookies." Third-party cookies enable
            third-party features or functionality to be provided on or through
            the website (e.g., advertising, interactive content, and analytics).
            The parties that set these third-party cookies can recognize your
            computer both when it visits the website in question and also when
            it visits certain other websites. For more information on cookies,
            go to&nbsp;
          </span>
          <a href="https://www.allaboutcookies.org/">
            <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
              allaboutcookies.org
            </span>
          </a>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>.</span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              Why do we use cookies?
            </span>
          </strong>
        </h2>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Youni uses first- and third-party cookies for several reasons. Some
            cookies are required for technical reasons in order for our Online
            Properties to operate, and we refer to these as "essential" or
            "strictly necessary" cookies. Other cookies enable us to better
            understand your use of our Online Properties, to track and target
            the interests of our Users, and to enhance the experience of Users
            on our Online Properties. Third parties serve cookies through our
            Online Properties for advertising, analytics and other purposes.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Organisers can also use cookies on our Online Property to learn more
            about your interests in their event(s) group(s). These practices are
            described in more detail below.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              Types of cookies we use
            </span>
          </strong>
        </h2>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              First-party cookies
            </span>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            These cookies are created by Youni. They are primarily used to allow
            you to browse our Online Properties and use their features. They may
            also assist us in our analytics activities. These cookies may use
            non-personal data (e.g., aggregate or de-identified information
            about your use of the Online Properties) or information that
            uniquely identifies the browser or device you are using to access
            the Online Properties.&nbsp;
          </span>
        </p>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Third-party cookies
            </span>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            In addition to the first-party cookies we use, some third-party
            companies may place cookies on your computer through our Online
            Properties, access those cookies, and associate web beacons with
            them. These cookies enable third-party features or functionalities
            to be provided on or through our Online Properties. The parties that
            set these third-party cookies can use them to recognise your device
            both when it visits our Online Properties and also when it visits
            certain other, unaffiliated websites.
          </span>
        </p>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Types of first- and third-party cookies we use
            </span>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The types of first- and third-party cookies we use on our Online
            Properties are detailed below. Please note that the specific cookies
            served to your browser may vary depending on the specific Youni
            Property you visit. &nbsp;These cookies may use non-personal data
            (e.g., aggregate or de-identified information about your use of the
            Online Properties) or information that uniquely identifies the
            browser or device you are using to access the Online Properties.
            &nbsp;
          </span>
        </p>
        <ul>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Essential cookies
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                : These cookies are strictly necessary to provide you with
                access to and the basic use of our Services or Online
                Properties, such as access to secure areas and setting your
                privacy preferences. &nbsp;These cookies cannot be turned off.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Performance and functionality cookies
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                : These cookies are used to enhance the performance and
                functionality of our Online Properties, but are not required for
                their use. However, without these cookies, certain functionality
                of the Online Properties may become limited or unavailable.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Analytics and customization cookies
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                : These cookies help us understand how our Online Properties are
                being used or how effective our marketing campaigns are, or to
                help us customise our Sites for you. Thanks to this information,
                we are able to improve the features offered on our Online
                Properties.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Targeting cookies
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                : These cookies are used to make advertising messages more
                relevant to you. They perform functions like preventing the same
                ad from continuously reappearing, ensuring that ads are properly
                displayed for advertisers, and in some cases selecting
                advertisements that are based on your interests.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Social Media cookies
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                : These cookies are used to enable you to share pages and
                content that you find interesting on our Online Properties
                through third-party social networking and other websites. These
                cookies may also be used for advertising purposes.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Organiser cookies
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                : Organisers selling tickets to events may use a variety of
                cookies on our Online Properties, including analytics and
                customization cookies, advertising cookies, and social media
                cookies. &nbsp;They may use these cookies to provide relevant
                advertisements about goods and services in which you may be
                interested and measure the effectiveness of advertisements and
                marketing campaigns. &nbsp;Please note that Youni does not have
                access to or control the cookies that Organisers chose to use.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            For more information on how to allow or refuse these various types
            of cookies, please see “Cookie management” below.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              Duration of cookies stored on your device
            </span>
          </strong>
        </h2>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The duration of storage of cookies on your device may vary,
            depending on the category to which they belong: temporary or
            permanent.
          </span>
        </p>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Temporary cookies
            </span>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We use temporary cookies on our Online Properties to allow you to
            browse our Online Properties without interruption. We may also use
            temporary cookies for internal reporting purposes. After your
            browsing session ends, this type of cookie does not store any
            information on your hard drive.
          </span>
        </p>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Persistent cookies
            </span>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Some of our first-party analytics cookies and certain third-party
            cookies are persistent cookies. They allow us and third parties to
            understand visitors’ browsing behaviour over a longer period of
            time, to provide you with additional features related to your use of
            the Online Properties, the content that you have saved or stored
            through them, as well as for advertising and marketing purposes.
            Depending on their function, this type of cookie may persist for
            different lengths of time ranging from several hours, to a week, or
            even several years. This type of cookie will persist after you end a
            browsing session and can be used by our Online Properties or third
            parties to recognise your device when you begin a new browsing
            session.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              Cookie management
            </span>
          </strong>
        </h2>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you want to control your cookie preferences, please follow the
            instructions below.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Additionally, for European Youni domains, you have the option to
            adjust what cookies you allow us to place on your browser through
            our Cookie Settings tool. You can modify these settings at any time.
            &nbsp;
          </span>
        </p>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              All cookies
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            : You can exercise your preference to receive all types of cookies
            by managing cookies using your web browser settings. As the means by
            which you may allow or refuse cookies using browser settings varies
            from one web browser to another (e.g., Chrome, Safari, or Firefox),
            you should visit your web browser’s help menu for more information
            on setting your browser-based cookie preferences. You can also
            configure your browser at any time to be notified of the receipt of
            a cookie, so that you can decide whether you want to accept it or
            not.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Cookies do not read other data on your hard drive, and you can
            delete them from your computer at any time using browser controls.
            At the moment, it is technically impossible for you to synchronise
            your settings between your browsers and your devices (your computer
            or your smartphone). You must set them on each browser/device that
            you currently use and each time you use a new browser or device.
            Please note that if you choose not to allow, or otherwise disable or
            deactivate all cookies, this will apply to all cookie types and will
            affect the functionality of our Online Properties. You may not be
            able to use all Site or Application features, and in some cases the
            Youni Property simply will not perform.
          </span>
        </p>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Analytics cookies
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            : To disable all analytics cookies managed by our third party
            analytics partners, please use the browser disablement mechanisms
            discussed immediately above. &nbsp;You can also use the following
            analytics opt out mechanisms provided by several of our analytics
            providers:&nbsp;
          </span>
        </p>
        <ul>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <a href="https://www.adobe.com/privacy/opt-out.html">
                <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
                  Adobe
                </span>
              </a>
            </p>
          </li>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <a href="https://tools.google.com/dlpage/gaoptout">
                <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
                  Google Analytics opt out browser add on
                </span>
              </a>
            </p>
          </li>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <a href="https://www.marinsoftware.com/privacy/marin-tracker-opt-out">
                <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
                  Marin Software
                </span>
              </a>
            </p>
          </li>
        </ul>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Targeting cookies
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            : For more information about tailored browser advertising and how
            you can generally control cookies from being put on your computer to
            deliver tailored advertising, you may visit the&nbsp;
          </span>
          <a href="http://optout.networkadvertising.org/?c=1#!/">
            <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
              Network Advertising Initiative’s Consumer Opt-Out link
            </span>
          </a>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            , the&nbsp;
          </span>
          <a href="http://optout.aboutads.info/?c=2#!/">
            <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
              Digital Advertising Alliance’s Consumer Opt-Out link
            </span>
          </a>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            , or&nbsp;
          </span>
          <a href="http://www.youronlinechoices.eu/">
            <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
              Your Online Choices
            </span>
          </a>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp; to opt-out of receiving tailored advertising from companies
            that participate in those programmes. To opt out of Google Analytics
            for display advertising or customise Google display network ads,
            visit the&nbsp;
          </span>
          <a href="https://adssettings.google.com/authenticated">
            <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
              Google Ads Settings
            </span>
          </a>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;page. &nbsp;If you do not allow these cookies, you will still
            see advertising online, but those advertisements may not be tailored
            to your interests.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You can also disable advertising cookies by using the browser
            controls explained in “All cookies” above.&nbsp;
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              How often will you update this Cookie Statement?
            </span>
          </strong>
        </h2>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may update this Cookie Statement from time to time in order to
            reflect, for example, changes to the cookies we use or for other
            operational, legal or regulatory reasons. Please revisit this Cookie
            Statement regularly to stay informed about our use of cookies and
            related technologies. The date at the top of this Cookie Statement
            indicates when it was last updated.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              Where can I get further information?
            </span>
          </strong>
        </h2>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you have any questions about our use of cookies or other
            technologies, please email us at youni@tbcgroup.xyz.
          </span>
        </p>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>NOTE:</span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;To learn more about Youni's Legal Terms,&nbsp;
          </span>
          <span
            style={{
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            take a look here.
          </span>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;For more information on Youni’s privacy practices, refer to
            our full&nbsp;
          </span>
          <span
            style={{
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            Privacy Policy here.
          </span>
        </p>
        <p>
          <br />
        </p>
      </>
    </div>
  );
};