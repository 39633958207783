import Layout from "../layouts/layout"
import Main from "../sections/_students/main"
import More from "../sections/_students/more"
import styles from "../styles/students.module.scss"

const Students = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <Main />
        <More />
      </div>
    </Layout>
  )
}

export default Students