import Slider from "react-slick";
import styles from "../../styles/commyounityThoughts.module.scss";

const NextArrow = (props) => {
  const { onClick, currentSlide, slideCount } = props;
  return (
    <div className={styles.next} onClick={onClick}>
      <img
        src={
          currentSlide + 2 === slideCount
            ? "/assets/arrow_2_copy.svg"
            : "/assets/arrow_2.svg"
        }
        alt="arrow"
      />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick, currentSlide } = props;
  return (
    <div className={styles.prev} onClick={onClick}>
      <img
        src={
          currentSlide === 0
            ? "/assets/arrow_1_copy.svg"
            : "/assets/arrow_1.svg"
        }
        alt="arrow"
      />
    </div>
  );
};

const Thoughts = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.6,
          slidesToScroll: 2.6,
        },
      },
      {
        breakpoint: 1175,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 2.3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2.1,
        },
      },
      {
        breakpoint: 878,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1.8,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1.6,
        },
      },
      {
        breakpoint: 668,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1.3,
        },
      },
      {
        breakpoint: 545,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1.4,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1.2,
        },
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1.1,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1.02,
        },
      },
    ],
  };

  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1>Our thoughts</h1>

        <div className={styles.bottom}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 50,
                padding: 10,
                borderRadius: 10,
                color: "white",
              }}
            >
              <img src="/assets/dot.png" />
              <div style={{ paddingLeft: 10 }}>Coming Soon</div>
            </div>
          </div>
          {/* <div className={styles.bottomWrapper}>
                        <Slider {...settings}>
                            <div>
                                <img src="/assets/commImg1.png" alt="iphone" className={styles.img} />
                            </div>
                            <div>
                                <img src="/assets/commImg2.png" alt="iphone" className={styles.img} />
                            </div>
                            <div>
                                <img src="/assets/commImg3.png" alt="iphone" className={styles.img} />
                            </div>
                            <div>
                                <img src="/assets/commImg1.png" alt="iphone" className={styles.img} />
                            </div>
                        </Slider>
                    </div> */}
        </div>
      </div>
    </section>
  );
};

export default Thoughts;
