import styles from "../../../styles/whyyouni.module.scss";

export const TicketPlatforms = () => {
  return (
    <div className={styles.mobileContent}>
    <div className={styles.mobileMainCellTitle}>
        <p className={styles.mobileSectionTitle}>Society Management</p>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Society Tailored Profile Pages <br />
            <span className={styles.subText}>
                Committee Member Profiles <br />
                Integrated Socials
                <br />
                Live Term Card
                <br />
                Live Society Noticeboard
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/cross.png" alt="logo" />
        </div>
        </div>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Follower/Member Management <br />
            <span className={styles.subText}>
                Live + Exportable Followers Lists <br />
                Push Notifications Direct to Followers
                <br />
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/cross.png" alt="logo" />
        </div>
        </div>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Society Handover Help <br />
            <span className={styles.subText}>
                add/remove team members <br />
                post roles to the student body
                <br />
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/cross.png" alt="logo" />
        </div>
        </div>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Society Analytics <br />
            <span className={styles.subText}>
                dashboard with engagement statistics and <br />
                followers breakdown (incl. college, degree, <br />
                undergrad/postgrad and more)
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/cross.png" alt="logo" />
        </div>
        </div>
        <div className={styles.mobileItemWrapper}>
        <div>All of the above in mobile app.</div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/cross.png" alt="logo" />
        </div>
        </div>
    </div>
    {/* Events Section */}
    <div className={styles.mobileMainCellTitle}>
        <p className={styles.mobileSectionTitle}>Events</p>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Events Feed <br />
            <span className={styles.subText}>
                Filter by Category
                <br />
                Explore/For You Page
                <br />
                Add to Wishlist
                <br />
                See Whose Going to an Event
                <br />
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/cross.png" alt="logo" />
        </div>
        </div>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Event Manager <br />
            <span className={styles.subText}>
                Create + Edit Events on app
                <br />
                Ticket Sales
                <br />
                Attendee Lists (exportable)
                <br />
                QR Code Scanner
                <br />
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/tick.png" alt="logo" />
        </div>
        </div>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Event Insights <br />
            <span className={styles.subText}>
                College Breakdown, Undergrad/Postgrad, <br />
                Average Age, etc.
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/cross.png" alt="logo" />
        </div>
        </div>
    </div>
    {/* Pricing Section */}
    <div className={styles.mobileMainCellTitle}>
        <p className={styles.mobileSectionTitle}>Pricing + Ticketing</p>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Always free for Societies <br />
            <span className={styles.subText}>No subscription charge</span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/cross.png" alt="logo" />
        </div>
        </div>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Commission <br />
            <span className={styles.subText}>
                Rate for avg Ticket Price
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <p>
            £0.49 + 4.99% <br />-<br /> £0.59 + 6.95%
            </p>
        </div>
        </div>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Tiered Ticketing <br />
            <span className={styles.subText}>
                Multiple Ticket Types
                <br />
                Scheduled releases
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/tick.png" alt="logo" />
        </div>
        </div>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Discount Codes <br />
            <span className={styles.subText}>
                Set and Share Discount Codes
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/tick.png" alt="logo" />
        </div>
        </div>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Private Tickets <br />
            <span className={styles.subText}>
                Set Private Codes for Private Events
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/tick.png" alt="logo" />
        </div>
        </div>
    </div>
    {/* Community Section */}
    <div className={styles.mobileMainCellTitle}>
        <p className={styles.mobileSectionTitle}>Community + Socialisation</p>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Student Profiles <br />
            <span className={styles.subText}>
                Integrated Socials <br />
                Society, Clude + Project Roles
                <br />
                Personalised Events Calendar
                <br />
                Ticket Wallet
                <br />
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/cross.png" alt="logo" />
        </div>
        </div>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Community Noticeboard <br />
            <span className={styles.subText}>
                Live Society Updates, Twitter Style
                <br />
                COMING SOON: Directory of Student Talent (need a photographer?
                Find one in seconds)
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/cross.png" alt="logo" />
        </div>
        </div>
    </div>
    {/* Funding Section */}
    <div className={styles.mobileMainCellTitle}>
        <p className={styles.mobileSectionTitle}>Funding - COMING SOON</p>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Sponsorship Matching <br />
            <span className={styles.subText}>
                List Society For Corporate Sponsorship <br />
                Share Analytics Instantly
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/cross.png" alt="logo" />
        </div>
        </div>
        <div className={styles.mobileItemWrapper}>
        <div>
            <p>
            Company Society & Events Fund <br />
            <span className={styles.subText}>
                Apply to Youni Events Fund
            </span>
            </p>
        </div>
        <div className={styles.mobileIconWrapper}>
            <img src="/assets/cross.png" alt="logo" />
        </div>
        </div>
    </div>
    </div>
  );
};
