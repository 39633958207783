import contactTouch from "../../styles/contactTouch.module.scss";

export const OrganiserAgreement = () => {
  return (
    <div className={contactTouch.content}>
      <>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Last Updated: September 15, 2023.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Youni’s Organiser Agreement (“Organiser Agreement”) and the rights
            and obligations contained in it are in addition to and are
            incorporated into the Youni’s&nbsp;
          </span>
          <span
            style={{
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            Terms of Service
          </span>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;(“Terms of Service”). Nothing in this Organiser Agreement will
            be interpreted to limit, change, or waive any terms of the Terms of
            Service or our&nbsp;
          </span>
          <span
            style={{
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            Privacy Policy
          </span>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            . However, if there is any inconsistency between the Terms of
            Service and this Organiser Agreement, this Organiser Agreement will
            control.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Capitalised terms that are not defined in this Organiser Agreement
            have the definition provided in the Terms of Service. In addition,
            the headings and subheadings throughout this Organiser Agreement,
            including the italicised text following each, are for convenience
            only and will not restrict or affect any provisions, and are not
            legal guidance. When we say Youni “may,” has the right, is
            permitted, is authorised, or is allowed to do something in this
            Organiser Agreement, it means we may, but are not obligated to,
            exercise the applicable rights or options or take the applicable
            action, as we determine in our sole discretion. Any determinations,
            decisions, or beliefs by us under this Organiser Agreement may be
            made by us in our sole discretion. As used in this Organiser
            Agreement, “including” means “including, but not limited to.” When
            this Organiser Agreement says that you “will” take an action, this
            means that you are agreeing to take the action and that you must
            take that action.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Please read this entire Organiser Agreement and our Terms of Service
            thoroughly, as they may affect your rights. This Organiser Agreement
            contains important information regarding payments, refunds, and
            restricted events, and the Terms of Service contain provisions for
            dispute resolution, including binding arbitration and a class action
            waiver, that apply to this Organiser Agreement.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              1. Who We Are.
            </span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              1.1 About Us.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                Welcome to Youni! We’re glad you’re here!
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We’re a university community and events management and growth
            platform that helps Organisers and Consumers thrive and connect
            through memorable lived experiences and virtual connection. Through
            our platform, mobile apps, and other services, we empower members of
            University Communities, local communities and business to create,
            discover, share, and register for groups and events.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Youni is owned and managed by TBC GROUP LTD, a limited company
            incorporated in the United Kingdom, &nbsp;Company. No. 12592201
            ("Youni,” "we,"us," or "our"). When this Organiser Agreement
            mentions&nbsp;
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              "Youni" "we," "us," or "our,"
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;it refers to TBC GROUP LTD. and its affiliates, and
            subsidiaries, and each of its and their respective officers,
            directors, agents, partners, and employees, as defined in the Terms
            of Service.&nbsp;
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              1.2 Youni Organiser Services.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                Here’s a summary of our services.
              </span>
            </em>
          </strong>
        </h3>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Event &amp; Ticketing Services
                </span>
              </em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                : We provide you and other Organisers a platform to host, manage
                and market your events to Users. This includes being able to
                sell tickets, registrations and other items, and receive
                payments for your events. In addition, you can monitor and
                manage the admin of event hosting (collectively, “
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Event &amp; Ticketing Services
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ”).&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Group &amp; Community Services
                </span>
              </em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                : We provide you and other Organisers the ability to create,
                host, manage and market your Group Page to Consumers. In
                addition, Consumers can subscribe/follow your Group Page to
                become followers or members of your Group Page (“
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Followers”
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ). You are able to provide details about yourself and your
                organisation. You are able to create a Group Page, where you can
                input your Organiser name ,description, what categories define
                you as an Organiser, links to social media accounts and other
                external websites, photos and the agents or team members that
                you have appointed as an Organiser (
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  “Team Members”
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ). Once created, your Group Page will also display all of your
                Group Events and a group forum page, allowing team members of
                your group and Users the ability to engage and communicate with
                one another via a forum (
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  “Group Forum”
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ). Once created, your Group Page will be accessible by any Users
                who can view your Group Page, your Group Events, your Group
                Forum and the other details inputted when becoming an Organiser.
                Users can follow your Group Page, and in so doing become
                Followers of your Group Page, and you as an Organiser. You can
                view and access your Followers and the data relevant to their
                status as Followers, including but not limited to their names,
                contact information and academic profiles. This must be done in
                a manner consistent with any local/state law, including GDPR,
                and our Terms of Service. You can also export this data out of
                our Platform. In doing so,, you are solely responsible for
                ensuring continued consent to use that data, and you must
                provide your Followers the ability to unsubscribe or have that
                data removed in compliance with all relevant data protection and
                other law. &nbsp;You can grow your following, as Users follow or
                subscribe to your Group Page, becoming Followers. Your Team
                Members are also responsible for all of the above, and you
                warrant upon adding any new Team Members that they abide by this
                Agreement, the Community Guidelines and Youni’s Terms of
                Service. The above services are collectively defined as “
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Group &amp; Community Services
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ”. All Group &amp; Community Services will be provided as we
                determine and will be subject to the Terms of Service, this
                Organiser Agreement, and any separate written agreement we
                determine is necessary. In the event of a conflict between the
                separate written agreement and this Organiser Agreement, the
                Organiser Agreement will control.&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Organiser Services
                </span>
              </em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                : When “
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Organiser Services
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ” is used in this Organiser Agreement, it means both the Event
                &amp; Ticketing Services and the Group &amp; Community Services.
                For clarity, Organiser Services, among other types of Services,
                are included in the meaning of “
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Services
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ” under the Terms of Service.
              </span>
            </p>
          </li>
        </ol>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              2. Our Organiser Agreement.
            </span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              2.1 Purpose and Scope.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                This is what you are agreeing to.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            This Organiser Agreement sets the terms and conditions for your use
            of the Organiser Services. Users of our Organiser Services may be
            collectively referred to in this Organiser Agreement as “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>you</span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>” or “</span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>your</span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            .” &nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            By using the Organiser Services or registering as an Organiser, you
            are agreeing to the terms and conditions of this Organiser
            Agreement, the Terms of Service, and our Privacy Policy, without
            modification, and entering into a binding contract with us that
            governs our Organiser Services and your use of the Organiser
            Services. Do not use the Organiser Services or register as an
            Organiser if you do not agree to the terms and conditions of this
            Organiser Agreement, the Terms of Service, or our Privacy Policy.
            &nbsp;
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              3. Eligibility for Organiser Services.
            </span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              3.1 Eligibility.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                Please make sure you’re eligible before using our Organiser
                Services.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            To use the Organiser Services, you must: (a) have the authority to
            enter into this Organiser Agreement on your own behalf or on behalf
            of the entity or University Organisation using the Organiser
            Services; (b) comply with our Terms of Service and all applicable
            laws; and (c) review and agree to the&nbsp;
          </span>
          <a href="https://stripe.com/legal/connect-account">
            <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
              Stripe Connected Account Agreement
            </span>
          </a>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            , which includes the&nbsp;
          </span>
          <a href="https://stripe.com/legal/ssa">
            <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
              Stripe Services Agreement
            </span>
          </a>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;(collectively, the “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Stripe Agreements
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ,” which Stripe may modify from time to time) and any other required
            third party agreement, if applicable.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            All information you submit must be truthful, accurate, and complete,
            and you must promptly notify us of any changes. We can also demand
            proof that the information you have submitted/provided is accurate,
            and that you do in fact represent the University Organisation or
            entity you claim to represent.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We can approve or deny your registration for the Organiser Services,
            limit, suspend, or terminate your access to the Organiser Services,
            and/or place transactional limits on payouts at any time, for any
            reason, with or without notice. We can also change these eligibility
            requirements at any time.
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              3.2 Additional Registration Data.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                We may need some additional information from you.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            After registering for the Organiser Services, we may require you to
            provide additional information about yourself, the entity you
            represent (if any), and the principals/beneficial owners, agents or
            leaders of the entity you represent (if any) (collectively, "
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Additional Registration Data
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            "). As an example, Additional Registration Data may include current
            address, verification of student status, doing business as (DBA)
            names, description of products, website address, bank account or
            other payment account information, tax identification numbers, date
            of birth, passport or drivers licence number, country of origin,
            copies of government identification documents, and other personal
            information.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may use this information to verify your identity, the validity
            and/or legality of your transactions, and/or whether you qualify to
            use the Organiser Services. You must: (a) promptly provide accurate
            and complete information and (b) regularly and quickly update this
            information to make sure it remains accurate and complete.
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              3.3 Disclosure Authorisation.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                We may need to share your information with third parties who
                help us provide services to you.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may share Registration Data, Additional Registration Data, and
            information about events and use of the Services with our Payment
            Processor Partners , the Card Schemes and Alternative Payment
            Frameworks (as each are defined below), and with your bank or other
            financial institution, if the Services involve these third parties.
            You also authorise us to verify your Registration Data and
            Additional Registration Data, and conduct due diligence on you
            through third parties, including third-party credit reporting
            agencies.
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              3.4 Failure to Provide.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                Be sure to provide us with all information that we request so
                you don’t lose access to our services or your payouts.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You are not entitled to receive any payments from tickets,
            registrations, or other items sold from us or our Payment Processor
            Partners unless and until you provide full and accurate Registration
            Data and Additional Registration Data. We reserve the right to
            suspend or terminate your Youni account and/or your access to the
            Organiser Services, and to withhold any payments otherwise due to
            you, if we believe that your Registration Data or Additional
            Registration Data is inaccurate or incomplete.&nbsp;
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              3.5 Prohibited Organisers; Prohibited Events; Prohibited
              Transactions.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                We can’t do business with certain people and entities.
              </span>
            </em>
          </strong>
        </h3>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Prohibited Organiser
                </span>
              </em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                . By registering for the Organiser Services and accepting this
                Organiser Agreement, you represent and warrant that:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you are not located in, and you are not a national or
                    resident of, any country for which the United States, United
                    Kingdom, European Union, Australia or Canada has embargoed
                    goods and/or services similar to our Services ("
                  </span>
                  <strong>
                    <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                      Restricted Countries
                    </span>
                  </strong>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    ");
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you are not a person or entity or under the control of or
                    affiliated with a person or entity that
                  </span>
                </p>
                <ol>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        appears on the U.S. Office of Foreign Assets Control's
                        Specially Designated Nationals List, Foreign Sanctions
                        Evaders List or Palestinian Legislative Council List;
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        appears on the U.S. Department of State's Terrorist
                        Exclusion List;
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        appears on the Bureau of Industry and Security's Denied
                        Persons List;
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        appears on the Consolidated List of Targets published by
                        the U.K. HM Treasury;
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        appears on the Consolidated List published by the A.U.
                        Department of Foreign Affairs and Trade; or
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        is subject to sanctions in any other country; and
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you are not listed in the MasterCard MATCH terminated
                    merchant database or Visa terminated merchant file and your
                    right to access each Card Scheme and each Alternative
                    Payment Framework is not revoked or suspended.
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If we determine that you fall into any of the categories above, you
            are a "
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Prohibited Organiser
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>."</span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Prohibited Events
                </span>
              </em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                . You may not post events to the Youni Properties or engage in
                activities through the Organiser Services that:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    violate or help violate any applicable local, state,
                    provincial, national or other law, rule or regulation;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    are prohibited under the Payment Scheme Rules;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    contain any Content that violate the Terms of Service or
                    the&nbsp;
                  </span>
                  <span
                    style={{
                      color: "#39364f",
                      fontSize: "10.5pt",
                    }}
                  >
                    Youni Community Guidelines;
                  </span>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    &nbsp;or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    take place in Restricted Countries or restricted regions,
                    which include (but are not limited to) Iran, North Korea,
                    Syria, Crimea, Donetsk People’s Republic, and Luhansk
                    People’s Republic. Also, you must have our prior written
                    approval to post events in Cuba or Russia because additional
                    licensing requirements may apply.&nbsp;
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If we determine that an event falls into any of the categories
            above, it is a “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Prohibited Event
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>.”</span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Prohibited Transactions
                </span>
              </em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                . You may not process any of the following transactions, and you
                represent and warrant that you will not submit for processing
                any of the following:&nbsp;
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    any transaction that violates or is considered “high risk”
                    (or another restricted category) by the Payment Scheme
                    Rules, including any transaction involving certain adult
                    content or activities, illegal goods or services (including
                    cannabis), paraphernalia associated with any illegal goods
                    or service, crypto-currency purchases, raffles, sweepstakes,
                    or gambling;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    any fraudulent or criminal transaction;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    any transaction that would be be legally classified in the
                    UK as gambling;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    any transaction that is unrelated to your events or
                    membership payments to become a paid Follower for your Group
                    Page. For clarity, our payment processing services may only
                    be used for the purchase of tickets or registrations for
                    your events on the Youni Properties, or to sell items or
                    solicit donations directly related to such events, or to pay
                    for membership to become a paid Follower of your Group Page,
                    or to receive sponsorship from sponsors.&nbsp;
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If we determine that a transaction falls into any of the categories
            above, it is a "
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Prohibited Transaction
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>."</span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              4. Suspension and Termination of Organiser Services; Survival of
              Obligations.
            </span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              4.1 Suspension and Termination.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                Here are reasons why we might need to suspend or terminate your
                access to the Organiser Services.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may suspend or terminate your and your Affiliates’ ability to
            participate on the Youni Properties as an Organiser and/or to
            receive payments at any time and for any reason, including if:
          </span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                you become ineligible for the Services or violate any provision
                of this Organiser Agreement, the Terms of Service, or any other
                Youni policy applicable to you;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                you or any of your affiliates do not make payments owed to us on
                time;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                our Payment Processor Partners or Card Schemes refuse to
                facilitate payments to you or to engage in services involving
                you;&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                we are served with legal process seeking to attach or garnish
                any of your funds or property in our possession;&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                we learn that you have granted a right to assignment of payments
                to any party, for any reason; or&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                you are a Prohibited Organiser, have organised Prohibited
                Events, and/or process or submit Prohibited Transactions.
              </span>
            </p>
          </li>
        </ol>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              4.2 Organiser Termination.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                You can delete your account, but you’ll still be bound by your
                agreements with us.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You may stop participating as an Organiser at any time by deleting
            your account in accordance with the Terms of Service. If you delete
            your account, you are still bound by this Organiser Agreement and
            any other Youni policy that applies to you, as well as any other
            written agreement you may have with us.
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              4.3 Effect of Termination.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                Let’s make sure we each have what we’re owed.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If either party terminates this Organiser Agreement, we (through our
            Payment Processor Partners) will, within a reasonable time, pay any
            amounts owed to you, as long as the payments are permitted by
            applicable laws, regulations, and orders. We may hold funds we owe
            you, pursuant to Section 6.2 “Deductions, Setoffs, and Reserves,” to
            make sure that there are no Chargebacks, refunds, credits, balances
            owed to us, or other deductions that should be made from the funds
            we owe you. We will otherwise have no further payment obligations to
            you. &nbsp;
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              4.4 Continued Obligations.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                Some terms will still be effective even after termination.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            All provisions of this Organiser Agreement that by their nature
            should survive termination of this Organiser Agreement will survive
            (including your obligations related to refunds, payments, and
            Chargebacks [defined below]).
          </span>
        </p>
        <p>
          <br />
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              5. Payment Processing and Refunds.
            </span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              5.1 Payment Processor Partners.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                We partner with third-party payment processors to facilitate
                your transactions.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            As part of the Organiser Services, we provide tools to help you sell
            tickets, registrations, membership and other items to Consumers
            interested in your events/Group Page. To facilitate payments for
            you, we partner with third-party payment processors and/or merchant
            acquirers (“
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Payment Processor Partners
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”). &nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You and we are subject to the rules and regulations of our Payment
            Processor Partners. In certain cases, you may be required to enter
            into separate agreements with our partners (“
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Payment Processor Agreements
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”) to receive payments. &nbsp;&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Stripe, Inc. (“
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>Stripe</span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”) is one of our Payment Processor Partners that may facilitate
            payment processing services for you. When you agree to this
            Organiser Agreement or continue to operate as an Organiser on Youni,
            you also agree to the&nbsp;
          </span>
          <a href="https://stripe.com/legal/connect-account">
            <span
              style={{
                color: "#1155cc",
                backgroundColor: "#ffff00",
                fontSize: "10.5pt",
              }}
            >
              Stripe Connected Account Agreement
            </span>
          </a>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;and the&nbsp;
          </span>
          <a href="https://stripe.com/legal/ssa">
            <span
              style={{
                color: "#1155cc",
                backgroundColor: "#ffff00",
                fontSize: "10.5pt",
              }}
            >
              Stripe Services Agreement
            </span>
          </a>
          <span
            style={{
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            .
          </span>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;For us to enable payment processing services through Stripe,
            you must provide us accurate and complete information about you and
            your University Organisation or business, and you authorise us to
            share it and transaction information with Stripe.&nbsp;
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              5.2 Youni Payment Processing and Refunds.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                Our role as limited payments agent and limited
                mediator/arbitrator agents in matters of dispute between you and
                your customers.&nbsp;
              </span>
            </em>
          </strong>
        </h3>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>YPP.</span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                <br />
              </span>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Here’s an overview of Youni Payment Processing.
                </span>
              </em>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            When you use Youni Payment Processing ("
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Youni Payment Processing
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>" or "</span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>YPP</span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            "), we act as your limited payments agent to facilitate payment
            transactions on your behalf using our Payment Processor Partners. As
            a limited mediator or arbitrator agent, we may provide limited
            mediation/arbitration between Consumers and you as Organisers for
            disputes regarding transactions on your behalf using our sole
            discretion and our Payment Processor Partners. As a limited agent,
            we process the total value of tickets, registrations, and other
            items, alongside the booking fee additionally paid by Consumers
            &nbsp;on each and any transaction (events or otherwise) (the “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Youni Payment Processing Fee
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”), purchased by Consumers for your events (“
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Event Proceeds
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”). As a limited agent, we also process the total value of any
            membership or Group Page subscription and/or any sponsorship or
            marketing deals Youni has facilitated for the Organiser, alongside
            the Youni Payment Processing Fee &nbsp;(“
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Group Proceeds”
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ). In return, for both Event Proceeds and Group Proceeds, in
            addition to other applicable Youni Fees, Youni will deduct the Youni
            Payment Processing fee for each ticket, registration, membership
            fee, sponsorship deal or other item sold or donation solicited, for
            facilitating the transaction and/or for providing a limited
            arbitration (see below) with regards to Disputed Event Transactions
            and/or Disputed Group Transactions (both defined below) between the
            Consumer and the Organiser. &nbsp;
          </span>
        </p>
        <ol start={2}>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Event Payouts.
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                <br />
              </span>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  This is when YPP users can expect to be paid for Event
                  Payouts.
                </span>
              </em>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            In the case of Event Payouts, Youni will instruct the Payment
            Processing Partners to send YPPs the Event Proceeds less the Youni
            Payment Processing Fee no later than five (5) business days after
            the conclusion of the event unless: (a) Consumers have alleged to
            Youni fraud or breach of the Terms of Service by the Organiser in
            relation to the event or Event Payouts; and/or (b), where we in our
            absolute discretion suspect the event in question breached the Terms
            of Service, Privacy Policy or this Organiser Agreement ((a) and (b)
            collectively, “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Disputed Event Transactions
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”). In the case of a Disputed Event Transaction, please see below.
            Otherwise, Youni will initiate payment via our Payment Processor
            Partners. Those who use YPP will receive the Event Proceeds, less
            the Youni Payment Processing Fee, Fees and Taxes, and Other
            Deductions and Setoffs (defined below), if applicable. At that time,
            our Payment Processor Partners will facilitate the payout to your
            given bank account on their own timeline. We will not be liable for
            delayed, rejected, or missed payouts that occur from the delay,
            failure, or contractual breach by you, a Payment Processor Partner
            or any other third-party provider. &nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Group Payouts.
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                <br />
              </span>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  This is when YPP users can expect to be paid for Group
                  Payouts.
                </span>
              </em>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            In the case of Group Payouts, Youni will initiate to send YPPs
            payout no later than five (5) business days after the transaction
            occurred unless: (a) Consumers or Users have alleged to Youni fraud
            or breach of the Terms of Service by the Organiser in relation to
            any transaction relating to Group Payouts facilitated by Youni for
            the Organiser (i.e. membership/subscription payments); and/or (b),
            where we in our absolute discretion suspect that transaction in
            question breached the Terms of Service, Privacy Policy or this
            Organiser Agreement ((a) and (b) collectively, “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Disputed Group Transactions
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”). In the case of a Disputed Group Transaction, please see below..
            Youni will initiate payment via our Payment Processor Partners.
            Those who use YPP will receive the Group Proceeds, less Fees and
            Taxes, and Other Deductions and Setoffs (defined below), if
            applicable. Otherwise, Youni will initiate payment via our Payment
            Processor Partners. Those who use YPP will receive the Group
            Proceeds, less the Youni Payment Processing Fee, Fees and Taxes, and
            Other Deductions and Setoffs (defined below), if applicable. At that
            time, our Payment Processor Partners will facilitate the payout to
            your given bank account on their own timeline. We will not be liable
            for delayed, rejected, or missed payouts that occur from the delay,
            failure, or contractual breach by you, a Payment Processor Partner
            or any other third-party provider. &nbsp;
          </span>
        </p>
        <ol start={4}>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Payout Methods.&nbsp;
              </span>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  These are the ways in which YPP users can receive payouts.
                </span>
              </em>
            </p>
            <ol>
              <li style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
                <p>
                  <em>
                    <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                      Acceptable Payout Methods.
                    </span>
                  </em>
                  <em>
                    <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                      <br />
                    </span>
                  </em>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    YPP users receive payouts via the bank account/information
                    given by said YPP users to our Payment Processor Partners.
                    If bank details are changed post transaction, the payout
                    might still be sent to the previous bank details.
                  </span>
                </p>
              </li>
              <li style={{ listStyleType: "lower-alpha", fontSize: "11pt" }}>
                <p>
                  <em>
                    <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                      False or Incorrect Information.
                    </span>
                  </em>
                  <em>
                    <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                      <br />
                    </span>
                  </em>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    We and our Payment Processor Partners rely on the
                    information you provide to facilitate payment transactions
                    on your behalf. If any bank account that you provide to us
                    or our Payment Processor Partners are incorrect, you must
                    reimburse, indemnify, and hold us and our Payment Processor
                    Partners harmless for any losses or expenses incurred by us
                    relying on the incorrect information.&nbsp;
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>&nbsp;</span>
        </p>
        <ol start={5}>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Transaction Limits.&nbsp;
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                <br />
              </span>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  We may need to set transaction limits for security reasons.
                </span>
              </em>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            For risk management, security, or to meet our Payment Processor
            Partners’ requirements, we may, from time to time, determine to
            impose a transaction limit on the amount of any given transaction
            that you process through YPP, and you authorise us to reject any
            transaction over that limit.&nbsp;
          </span>
        </p>
        <ol start={6}>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Currencies.
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                <br />
              </span>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  We only handle certain currencies and do not provide currency
                  conversion services.
                </span>
              </em>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  <br />
                </span>
              </em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We only provide YPP for certain currencies and for Organisers in
                the UK. In addition, Event Proceeds collected in a currency may
                only be paid out to you in the currency in which they are
                collected. We do not provide currency conversion services.&nbsp;
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={7}>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Disputed Event Transactions and Disputed Group Transactions -
                Youni’s service to Organisers as a limited arbitrator in matters
                of dispute relating to transactions between the Consumer and the
                Organiser
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "lower-alpha",
                  color: "#39364f",
                  fontSize: "10.5pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    Should Youni in its sole discretion deem the transaction(s)
                    in question as Disputed Event Transactions and Disputed
                    Group Transactions, we will pause on Event Payouts and Group
                    Payouts respectively as explained above.&nbsp;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "lower-alpha",
                  color: "#39364f",
                  fontSize: "10.5pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    Youni will inform the Organiser in question via the given
                    email to your Team Members and/or via a notification on the
                    App itself.&nbsp;
                  </span>
                </p>
                <ol>
                  <li
                    style={{
                      listStyleType: "lower-roman",
                      color: "#39364f",
                      fontSize: "10.5pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        This email will state that Youni has determined that
                        there is either a Disputed Event Transaction or a
                        Disputed Group Transaction and that Youni has paused the
                        Event Payout or Group Payout.
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "lower-roman",
                      color: "#39364f",
                      fontSize: "10.5pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        The notification will state that there is a Disputed
                        Event Transaction or a Dispited Group Transaction and
                        that your Team Members ought to check their emails for
                        more information. &nbsp;&nbsp;
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "lower-roman",
                      color: "#39364f",
                      fontSize: "10.5pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        You will have one week to respond via email to
                        “youni@tbcgroup.xyz” and present arguments for why the
                        Event Payout or Group Payout should be resumed.&nbsp;
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  listStyleType: "lower-alpha",
                  color: "#39364f",
                  fontSize: "10.5pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    After a week of informing you, Youni will then decide in its
                    sole discretion whether or not the Consumer and/or customer
                    should be refunded for the Disputed Event Transaction or the
                    Disputed Group Transaction (collectively, “
                  </span>
                  <strong>
                    <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                      Youni Special Refund
                    </span>
                  </strong>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    ”.&nbsp;
                  </span>
                </p>
                <ol>
                  <li
                    style={{
                      listStyleType: "lower-roman",
                      color: "#39364f",
                      fontSize: "10.5pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        Should Youni trigger a Youni Special Refund, we will
                        inform the Consumer in question and the Organiser in
                        question of its decision and refund the transaction,
                        less the Youni Payment Processing Fee to the Consumer
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "lower-roman",
                      color: "#39364f",
                      fontSize: "10.5pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        Should Youni decide to resume the Event Payout or the
                        Group Payout in question, we will inform your Team
                        Members, and resume the Group Payout or Event Payout
                        timeline.&nbsp;
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  listStyleType: "lower-alpha",
                  color: "#39364f",
                  fontSize: "10.5pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    Youni’s actions as a limited arbitrator are done so as a
                    service to you as part of the consideration for providing
                    the Services and/or for receiving the Youni Payment
                    Processing Fee. &nbsp;All sales are ultimately made by you,
                    and the Consumers are your customers. You are responsible
                    for the funding and processing of refunds. In the event of a
                    dispute, Youni may trigger a Youni Special Refund on your
                    behalf, and this decision cannot be challenged by you as you
                    grant us full discretion to do so by agreeing to the Youni
                    Organiser Agreement. Where Youni has decided not to trigger
                    a Youni Special Refund to your customers, you still are
                    obligated to settle disputes, and may still be legally
                    obligated to refund your customers.&nbsp;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "lower-alpha",
                  color: "#39364f",
                  fontSize: "10.5pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    You acknowledge and agree that Youni’s service to Organisers
                    as a limited arbitrator in matters of dispute relating to
                    transactions between the Consumer and the Organiser does not
                    absolve you of your legal obligations to your customers and
                    you warrant that Youni is in no way liable or responsible
                    for resolving your disputes with your customers.&nbsp;
                  </span>
                </p>
                <ol>
                  <li
                    style={{
                      listStyleType: "lower-roman",
                      color: "#39364f",
                      fontSize: "10.5pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        Even when Youni does trigger a Youni Special Refund,
                        this in no way guarantees that Youni has covered your
                        legal obligations to refund or cover losses for your
                        customers.
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "lower-roman",
                      color: "#39364f",
                      fontSize: "10.5pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        If Youni does not trigger a Youni Special Refund, this
                        in no way guarantees that Youni has covered your legal
                        obligations to refund or cover losses for your
                        customers, and we do not represent or warrant that the
                        use or lack of use of our discretion is in any way legal
                        advice on your obligations to refund or cover losses for
                        your customers.
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "lower-roman",
                      color: "#39364f",
                      fontSize: "10.5pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        In either case, you may also be liable to cover the cost
                        of Youni’s Payment Processing Fee to your customers.
                        This cannot be claimed against Youni itself.&nbsp;
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Cancelled Events&nbsp;
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "lower-alpha",
                  color: "#39364f",
                  fontSize: "10.5pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    If you cancel a paid event, Youni will automatically refund
                    all customers, regardless of the reason for cancellation.
                    This refund will not include Youni’s Payment Processing Fee.
                    You may still be liable for repayment on Youni’s Payment
                    Processing Fee to your customers.&nbsp;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "lower-alpha",
                  color: "#39364f",
                  fontSize: "10.5pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    You are responsible for refunds to your customers, but you
                    acknowledge that you pay us the Youni Payment Processing Fee
                    to act as limited agents and to process refunds on your
                    behalf to customers should you cancel an event, regardless
                    of the reason for event cancellation.&nbsp;
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              6. Fees, Deductions, and Reserves.
            </span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              6.1 Fees.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                Here’s an overview of the fees we charge, which will be deducted
                from your sales.
              </span>
            </em>
          </strong>
        </h3>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Fees and Taxes.
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                <br />
              </span>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  You are responsible for all fees and taxes arising from your
                  use of our Organiser Services.
                </span>
              </em>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You are solely responsible for setting prices for tickets,
            registrations, membership and other items for your events and group.
            You must pay us all applicable fees for the Organiser Services (the
            “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Youni Fees
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”), including transactional service fees and payment processing fees
            for the Ticketing Services and charged or invoiced fees for the
            Marketing and Operational Services. You are solely responsible for
            any and all payment processing fees, including the Youni Payment
            Processing Fee, credit card fees, applicable Taxes (described in
            Section 9 “Taxes”), and any other amounts due to any other party
            arising from the Organiser Services (collectively with Youni Fees, “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Fees and Taxes
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”). Fees and Taxes vary by jurisdiction and currency. The current
            Youni Payment Processing Fee is £0.49 + 3% on each and every
            transaction within the Youni Properties. This may change from time
            to time. You will be informed when it changes. For information on
            credit card fees and applicable Taxes and other payment processing
            fees, we recommend checking out Stripe’s pricing, contacting your
            bank and/or consulting a tax advisor. &nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may also charge you, at standard and reasonable rates, for (i)
            research costs and/or legal fees that we incur in order to respond
            to any third party or government subpoena, levy or garnishment on
            your account; and (ii) research and activities that are necessary
            for us to verify and execute any change of payee (collectively, “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Research Fees
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”). &nbsp;
          </span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Delays and Omissions.&nbsp;
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                <br />
              </span>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  We strive to inform you in a timely manner of amounts due, but
                  our failure to do so will not relieve you of your payment
                  obligations.
                </span>
              </em>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If we fail to send a timely invoice, you are still responsible for
            your payment obligations. If we omit in a statement or invoice a
            payment that you owe to us or any third party, it will not
            constitute a waiver of the right to that payment. You will still owe
            that payment and we may include it in a subsequent statement or
            invoice.&nbsp;
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              6.2 Deductions, Setoffs, and Reserves.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                We have the right to reserve and/or offset payout amounts in
                certain situations.
              </span>
            </em>
          </strong>
        </h3>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Deductions and Setoffs.&nbsp;
              </span>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  We may deduct or withhold amounts that you owe.
                </span>
              </em>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    Without limiting Section 6.1 above, we will deduct the
                    following from the Event Proceeds and Group Proceeds:&nbsp;
                  </span>
                </p>
                <ol>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        Youni Payment Processing Fees;&nbsp;
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        billing and payments costs and all costs associated with
                        transactions, which may include processing charges and
                        bank fees;&nbsp;
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        all applicable Taxes; and&nbsp;
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        amounts for returns, refunds, Chargeback Costs,
                        discounts, and credits.
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You will only be entitled to payments of the amount of your sales
            after these deductions have been made. Additionally, we may deduct
            all other deductions authorised under this Organiser Agreement and
            we may set off for any debts, fees, or other amounts that you or any
            of your Affiliates owe to us under this Organiser Agreement or any
            other agreement between or among you and/or any of your Affiliates
            and us and/or any of our Affiliates (“
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Affiliated Agreement
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”), including Chargeback Costs, refunds, Youni fees other Services
            you sign-up to from time to time, Research Fees, reserves, and
            customer complaints ("
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Other Deductions and Setoffs
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”). We are not liable to you for any claims resulting from our
            decision to make Other Deductions and Setoffs.
          </span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                For clarity, we may exercise our setoff rights against payouts
                related or unrelated to the amount owed. If the exercise of our
                setoff right does not fully cover the amount of funds that you
                or any of your Affiliates owe to us under this Organiser
                Agreement or any Affiliated Agreement, then that amount of funds
                will be deemed due and owing to us until you have fully
                satisfied the amount and, in this case, we may collect the
                amount pursuant to Section 11 “Non-Exclusive Remedies and
                Collections."
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We may also deduct from your Event Proceeds and/or Group
                Proceeds as required by law. In addition, we may block, reject,
                freeze, or turn over to law enforcement agencies any portion of
                payments involving the Youni Properties that are made to you,
                owed by you, or otherwise involve you as permitted and/or
                required by applicable laws and regulations.&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Reserves.&nbsp;
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                <br />
              </span>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  We can retain payout amounts for anticipated refunds and
                  similar disputes.
                </span>
              </em>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We reserve the right to retain all or a certain percentage of
                Event Proceeds and/or Group Proceeds and any other fees for
                Services that we provide to you or any of your Affiliates (with
                such percentage being determined by us) to fund a reserve:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    at any time we determine is necessary based upon the level
                    (or expected level) of refunds, disputed charges,
                    Chargebacks, customer complaints, allegations of fraud, or
                    changes in your credit profile or the relevant event(s)'
                    risk profile(s); and
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    as otherwise necessary to secure the performance of your or
                    any of your Affiliates’ obligations under this Organiser
                    Agreement or any Affiliated Agreement, or to protect us
                    against illegal, fraudulent, or improper activity.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Our right to hold a reserve will continue following the
                completion of your applicable event(s) and until either:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you and your Affiliates have discharged all obligations
                    under this Organiser Agreement and any Affiliated Agreement
                    and the applicable periods for refunds, disputed charges,
                    Chargebacks, and complaints have passed; or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you and your Affiliates have otherwise provided us with
                    adequate security (as determined by us) for your and your
                    Affiliates’ obligations under this Organiser Agreement and
                    any other Affiliated Agreement, whether matured or
                    unmatured, contingent or non-contingent, or liquidated or
                    unliquidated.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We may use any amounts that we hold in reserve to set-off
                amounts that you or your Affiliates owe us, as detailed above in
                Section 6.2(a).&nbsp;
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              8. Refunds.
            </span>
          </strong>
        </h2>
        <p>
          <br />
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              8.1 Handling Refunds, Disputes, and Fake Tickets.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                It is your obligation to handle refunds and settle refund
                disputes. We are not responsible for fake tickets.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            All sales are ultimately made by you, and the Consumers are your
            customers. You are responsible for the funding and processing of
            refunds. Regardless of what payment method is selected, all disputes
            regarding refunds are between you and your Consumers. In the event
            of a dispute, we may try to mediate, and we may, pursuant to Section
            5 and Section 6, refund your customers, but ultimately it is your
            obligation to settle the dispute. For YPP transactions, we still
            have the right to make Youni Special Refunds on your behalf.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Further, you are the only one responsible for making sure that your
            events are ticketed correctly, and that only valid tickets are
            accepted. We are not responsible for any fake or invalid tickets, or
            any costs associated with your decision to accept or reject tickets
          </span>
        </p>
        <p>
          <br />
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              8.2 Refund Policy.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                You must have a refund policy that complies with our Organiser
                Agreement.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You must set a refund policy and communicate it to Consumers for
            each paid event. This refund policy must align with this Organiser
            Agreement, in particular with regards to Youni Special Refunds. Your
            refund policy must make it clear that the Youni Payment Processing
            Fee is non-refundable by Youni. &nbsp;&nbsp;
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              9. Taxes.
            </span>
          </strong>
        </h2>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            In most cases, you are responsible for collecting and remitting all
            taxes to the appropriate governmental authorities. We have the
            ability to request information and withhold taxes from you in
            certain instances.
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              9.1 Your Tax Responsibility.
            </span>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You are solely responsible for determining which, if any, sales,
            use, amusement, value added, consumption, excise and other taxes,
            duties, levies and charges (collectively, “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>Taxes</span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”) apply to your use of the Organiser Services and to sales you make
            using the Organiser Services. It is your sole responsibility to, and
            you will, collect and remit the correct amounts of all such Taxes to
            the applicable governmental authorities (“
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Tax Authorities
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>”).</span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              9.2 Request for Information.&nbsp;
            </span>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Despite what is stated in Section 9.1, we may, in certain
            jurisdictions, be required to collect and remit Taxes relating to
            your sales of tickets, registrations, or other items that are made
            using the Organiser Services. To determine whether we must collect
            any Taxes on your behalf, we may request certain information when
            you create an event using the Organiser Services. Such information
            may relate to your tax exempt status, the nature of your event
            and/or other similar information. If we request such information,
            you represent and warrant that the information you provide is true
            and correct. We cannot give you legal or tax advice, so please be
            sure to check with your own legal and/or tax advisor about any
            information you provide through the Youni Properties. If a Tax
            Authority requires us to pay any Taxes attributable to your event as
            a result of the information you provided us being incorrect, you
            must promptly and fully reimburse us for such Taxes upon demand and
            all associated costs, penalties, interest and expenses.&nbsp;
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              9.3 Collection by Youni.&nbsp;
            </span>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Despite what is stated in Section 9.1, we may, in certain
            jurisdictions, be required to collect and remit Taxes on Youni Fees
            and Youni Payment Processing Fees to the Tax Authorities. In such
            jurisdictions, we will collect from you Taxes on Youni Fees, and you
            must pay such Taxes. We may, at our sole election, invoice you for
            Taxes on Youni Fees or withhold (from amounts we would otherwise pay
            to you) the amount of Taxes on Youni Fees. With the exception of
            Taxes on Youni Fees that we collect from you pursuant to this
            paragraph and Taxes collected and remitted pursuant to Section 9.2,
            you remain responsible for collecting and remitting the correct
            amount of any Taxes that apply to your use of the Services and to
            sales you make using the Services.&nbsp;
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              9.4 VAT Taxes.
            </span>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            To comply with sales tax regulations of the European Union (the “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>EU</span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”), we may be required to (i) collect Value Added Tax (“
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>VAT</span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”) from you on the Youni Fees for paid tickets, registrations, and
            other items sold on or through the Site if you reside in the EU and
            have not provided us with your VAT Identification Number (“
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>VAT ID</span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”); and (ii) remit any such VAT collected to the relevant tax
            authority.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you provide us with your VAT ID, we will not collect VAT on the
            Youni Fees. It is your sole responsibility to make sure that the VAT
            ID you provide to us (if any) is accurate, complete and properly
            formatted. Please be aware that falsification or misrepresentation
            of information to evade payment of VAT may result in fines and/or
            criminal prosecution. You must promptly and fully reimburse us for
            all out-of-pocket costs and expenses arising out of your
            falsification or misrepresentation of a VAT ID on the Site. We do
            not require you to have a VAT ID. However, if you reside in the EU,
            and you do not have a VAT ID or you do not provide us with your VAT
            ID, we may collect and remit VAT on the Youni Fees on your
            behalf.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            In the interest of clarity, we do not collect and remit VAT on the
            Youni Payment Processing Fee or on the price of the tickets,
            registrations, and other items you sell on or through the
            Site.&nbsp;
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              9.5 Right to Withhold.
            </span>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We reserve the right to withhold the payment of any amounts that we
            owe to you and pay such amounts as required by applicable local,
            state, provincial, national or other law, rule, regulation,
            judgement or order, in each case as determined by us, or to seek
            later payment from you of any amounts of Taxes uncollected and
            unremitted that are related to your events.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              10. Representations and Warranties.
            </span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              10.1 Authority.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                You promise that you have the authority to agree to these terms.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            In addition to the representations and warranties throughout this
            Organiser Agreement, you represent and warrant to us, on behalf of
            yourself and the entity you represent (if applicable), that:
          </span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                the entity you represent is properly organised and in good
                standing under applicable laws;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                you have all the power and authority necessary to enter into
                this Organiser Agreement and to fulfil your obligations;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                entering into and performing under this Organiser Agreement will
                not cause you to breach any laws, rules, court orders, or other
                agreements that you must follow; and
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                you have the full authority and legal power to bind the entity
                you represent to this Organiser Agreement.
              </span>
            </p>
          </li>
        </ol>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              11. Non-Exclusive Remedies and Collections.
            </span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              11.1 Non-Exclusive Remedies.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                We charge interest on overdue amounts and may withhold your
                payouts and/or invoice you for such amounts.
              </span>
            </em>
          </strong>
        </h3>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Interest on Overdue Amounts.
                </span>
              </em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                &nbsp;Overdue amounts that you or your Affiliates owe under this
                Organiser Agreement or any Affiliated Agreement will bear
                interest calculated from the due date until paid in full, at a
                rate equal to the lesser of:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    one percent (1%) per month, compounded monthly; or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    the maximum amount permitted by applicable law.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Setoffs and Invoices.
                </span>
              </em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                &nbsp;If you or any of your Affiliates owe us any amounts under
                this Organiser Agreement or any Affiliated Agreement, we may, to
                the extent allowed by applicable law:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    withhold and/or set-off any amounts that we would otherwise
                    pay to you or any of your Affiliates (as described in
                    Section 6.2 “Deductions, Setoffs, and Reserves”); and/or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    send an invoice to you or any of your Affiliates for overdue
                    amounts, to the extent that the amount we could withhold is
                    insufficient to cover the amounts you owe us, in which case
                    you or any of your Affiliates will pay the invoice within
                    thirty (30) days after the date of the invoice.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <em>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Non-Exclusive.
                </span>
              </em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                &nbsp;
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                <br />
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Our rights and remedies in this Organiser Agreement are
                non-exclusive (that is, our remedies are cumulative and any can
                be exercised by us in addition to, and do not prevent us from
                exercising, any other rights or remedies available to us now or
                in the future under law, this Organiser Agreement, any
                Affiliated Agreements, other agreements, or otherwise). Our
                failure or delay in enforcing or exercising any right, remedy or
                provision of this Organiser Agreement will not be considered a
                waiver of those rights. &nbsp;
              </span>
            </p>
          </li>
        </ol>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              11.2 Collections; Costs of Recovery.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                You will reimburse us for the costs of collecting overdue
                amounts from you.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We have the right to pursue collection of any late and unpaid
            amounts due to us if such amounts are not paid within thirty (30)
            days after the date of the invoice.&nbsp;
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              We have the right to send you collection notices; sending such a
              notice will not be a requirement for taking legal or other action
              to collect overdue sums.
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;In addition, you must promptly reimburse us upon demand for
            all out-of-pocket costs (including reasonable attorneys' fees and
            costs) incurred by us in collecting overdue amounts or any other
            amounts that you or any of your Affiliates owe under this Organiser
            Agreement or any Affiliated Agreement. You and your Affiliates agree
            that if we must seek recovery of past due amounts and associated
            fees and costs, we have the right to pursue unpaid amounts through
            judicial proceedings, and such actions will not be arbitrated
            regardless of any arbitration provisions in the Terms of Service.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
      </>
    </div>
  );
};
