import styles from "../../styles/commyounityJoin.module.scss";

const Join = () => {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1>
          Join Our
          <br />
          <span>commyounity.</span>
        </h1>

        <p>
          We donate + reinvest a percentage of our profits back into societies,
          organisations + student funds.
        </p>
      </div>
    </section>
  );
};

export default Join;
