import Form from "../../components/_contact/form";
import styles from "../../styles/contactTouch.module.scss";

const Touch = () => {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1>
          Get in <span>touch.</span>
        </h1>

        <div className={styles.text}>
          <img
            src="/assets/contactVector_1.svg"
            alt="vector"
            className={styles.left}
          />
          <p className={styles.lgScreen}>
            Want to join our team? <br />
            Want us to come to your uni next? <br />
            Have an idea for a feature? <br />
            Have something you need to get off your chest?{" "}
          </p>
          <p className={styles.mbScreen}>
            Want to join our team? <br />
            <br />
            Want us to come to your uni next? <br />
            <br />
            Have an idea for a feature? <br />
            <br />
            Have something you need to get off your chest?{" "}
          </p>
          <img
            src="/assets/contactVector_2.png"
            alt="vector"
            className={styles.right}
          />
        </div>

        <Form />
      </div>
    </section>
  );
};

export default Touch;
