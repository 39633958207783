import Layout from "../layouts/layout"
import Join from "../sections/_commyounity/join"
import Partnership from "../sections/_commyounity/partnership"
import Thoughts from "../sections/_commyounity/thoughts"
import styles from "../styles/commyounity.module.scss"

const Commyounity = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <Join />
        <Thoughts />
        <Partnership />
      </div>
    </Layout>
  )
}

export default Commyounity