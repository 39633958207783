import { useNavigate } from "react-router-dom";
import styles from "../styles/footer.module.scss"
import { paths } from "../routes/paths";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.left}>
            <img src="/assets/logo_2.svg" alt="logo" />
            <p>uni life, your way.</p>
            <ul>
              <li onClick={() => navigate(paths.organisers)}>
                Organisers
                <span />
              </li>
              <li onClick={() => navigate(paths.students)}>
                Students
                <span />
              </li>
              <li onClick={() => navigate(paths.commyounity)}>
                Commyounity
                <span />
              </li>
              <li onClick={() => navigate(paths.contact)}>
                Get in touch
                <span />
              </li>
            </ul>
          </div>

          <div className={styles.right}>
            <p>Get the app</p>
            <img src="/assets/appStore.svg" alt="app-store" />
            <img src="/assets/googlePlay.svg" alt="app-store" />

            <div className={styles.icons}>
              <a href="https://www.instagram.com/youni.ox/">
                <img src="/assets/insta.svg" alt="instagram" />
              </a>
              <a href="https://www.linkedin.com/company/youni-tbc/?viewAsMember=true">
                <img src="/assets/linkedin.svg" alt="linkedin" />
              </a>
              <a href="https://open.spotify.com/user/31f7yxx2ljmdhpoycuefvnde5gq4?si=419ca8a30e024592">
                <img src="/assets/spotify.svg" alt="spotify" />
              </a>
            </div>
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.left}>
            <p>© 2023 Youni was made with 💜 All rights reserved.</p>
          </div>

          <div className={styles.right}>
            <p onClick={() => navigate(paths.legal)}>Legal</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer