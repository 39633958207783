import styles from "../../styles/commyounityPartnership.module.scss";

const Partnership = () => {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1>Our beta partnerships</h1>

        {/* <img src="/assets/brands.png" /> */}

        <div className={styles.logos}>
          <img src="/assets/_logo1.png" alt="logo" />
          <img src="/assets/_logo2.png" alt="logo" />
          <img src="/assets/_logo3.png" alt="logo" />
          <img src="/assets/_logo4.png" alt="logo" />
          <img src="/assets/_logo5.png" alt="logo" />
          <img src="/assets/_logo6.png" alt="logo" />
          <img src="/assets/_logo7.png" alt="logo" />
          <img src="/assets/_logo8.png" alt="logo" />
          <img src="/assets/_logo9.png" alt="logo" />
          <img src="/assets/_logo10.png" alt="logo" />
          <img src="/assets/_logo11.png" alt="logo" />
          <img src="/assets/_logo12.png" alt="logo" />
          <img src="/assets/_logo13.png" alt="logo" />
          <img src="/assets/_logo14.png" alt="logo" />
          <img src="/assets/_logo15.png" alt="logo" />
          <img src="/assets/_logo16.png" alt="logo" />
          <img src="/assets/_logo17.png" alt="logo" />
          <img src="/assets/_logo18.png" alt="logo" />
          <img src="/assets/_logo19.png" alt="logo" />
          <img src="/assets/_logo20.png" alt="logo" />
          <img src="/assets/_logo21.png" alt="logo" />
          <img src="/assets/_logo22.png" alt="logo" />
          <img src="/assets/_logo23.png" alt="logo" />
          <img
            src="/assets/_logo24.png"
            alt="logo"
            className={styles.bigLogo}
          />
          <img src="/assets/_logo25.png" alt="logo" />
          <img src="/assets/_logo26.png" alt="logo" />
          <img src="/assets/_logo27.png" alt="logo" />
          <img src="/assets/_logo28.png" alt="logo" />
          <img src="/assets/_logo29.png" alt="logo" />
          <img src="/assets/_logo30.png" alt="logo" />
          <img src="/assets/_logo31.png" alt="logo" />
          <img src="/assets/_logo32.png" alt="logo" />
          <img src="/assets/_logo33.png" alt="logo" />
          <img src="/assets/_logo34.png" alt="logo" />
          <img src="/assets/_logo35.png" alt="logo" />
          <img src="/assets/_logo36.png" alt="logo" />
          <img src="/assets/_logo37.png" alt="logo" />
          <img src="/assets/_logo38.png" alt="logo" />
          <img src="/assets/_logo39.png" alt="logo" />
          <img src="/assets/_logo40.png" alt="logo" />
          <img src="/assets/_logo41.png" alt="logo" />
          <img src="/assets/_logo42.png" alt="logo" />
          <img src="/assets/_logo43.png" alt="logo" />
          <img src="/assets/_logo44.png" alt="logo" />
          <img src="/assets/_logo45.png" alt="logo" />
          <img src="/assets/_logo46.png" alt="logo" />
        </div>
      </div>
    </section>
  );
};

export default Partnership;
