import contactTouch from "../../styles/contactTouch.module.scss";

export const TermsOfService = () => {
  return (
    <div className={contactTouch.content}>
      <>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Last Updated: September 15, 2023.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Welcome to Youni! We know as university community organisers and
            participants &nbsp;you want your university community and events to
            run safely and smoothly. We want the same thing for our platform.
            We're excited you are here. Please read these Terms of Service (“
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>Terms</span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”) carefully as they contain important information about your legal
            rights, remedies and obligations. By accessing or using Youni’s
            Services, you agree to comply with and be bound by these Terms, as
            applicable to you.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Terms of Service
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            These Terms of Service ("
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>Terms</span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ") govern your use of the mobile application "Youni" ("App") and the
            website "https://thisisyouni.co.uk" and all related subdomains
            ("Website"), collectively referred to as the "Platform" as well as
            your use or interaction with any Services (defined below). The
            Platform is owned and operated by TBC GROUP LTD ("Youni"), a
            registered UK company with Company number 12592201.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            By accessing or using Youni’s Services, you are contracting with
            Youni and agreeing to the terms and conditions of these Terms (which
            incorporate and include our&nbsp;
          </span>
          <span
            style={{
              color: "#39364f",

              fontSize: "10.5pt",
            }}
          >
            Privacy Policy
          </span>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;and all other terms, policies, and agreements referenced
            throughout these Terms), without modification, and entering into a
            binding contract with us that governs our Services and your use of
            the Services. Do not access or use the Services if you do not agree
            to the terms and conditions of these Terms.
          </span>
        </p>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              IMPORTANT NOTICE:
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;SECTION 9 OF THESE TERMS OF SERVICE CONTAINS A BINDING
            ARBITRATION AND CLASS ACTION WAIVER THAT MAY AFFECT YOUR LEGAL
            RIGHTS. PLEASE READ SECTION 9 VERY CAREFULLY.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Welcome to Youni! We know as university community organisers,
            students or other participants in that university community, you
            want your university community and events to run safely and
            smoothly. We want the same thing for our platform. We're excited you
            are here. Please read these Terms carefully because they contain
            important information about your legal rights, remedies, and
            obligations.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>1-2</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              1. Accepting These Terms
            </span>
          </strong>
        </h3>
        <h4>
          <strong>
            <span style={{ color: "#666666", fontSize: "12pt" }}>1.1</span>
          </strong>
          <span style={{ color: "#666666", fontSize: "12pt" }}>&nbsp;</span>
          <strong>
            <em>
              <span style={{ color: "#666666", fontSize: "12pt" }}>
                What's what. Here are some important definitions to help you
                navigate these Terms.
              </span>
            </em>
          </strong>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>“</span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Consumers
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ” means consumers using and/or accessing our Services for any
                reason, including to interact with Organisers (see below),
                consume information and/or attend events.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>“</span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Youni Properties
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ” means Youni's Platform, products, features and offerings that
                are available:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    online and offline through Youni’s Platform, including the
                    App and the Website
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    off Platform, including via interaction with Youni’s social
                    media, third-party software and services that Youni makes
                    available to you, sponsorship services, surveys, and
                    marketing or distribution services.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>“</span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Material
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ” includes information, data, text, editorial content, design
                elements, look and feel, formatting, graphics, images,
                photographs, videos, music, sounds, and other content and
                materials.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            <p>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  “Student Organisation”&nbsp;
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                includes an organisation, group, collective, society, club,
                business, band, assembly and any other body of students that is
                majority run by students at a University in the United Kingdom.
                &nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            <p>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  “Group Page”
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                &nbsp;describes features on the Youni Platform that allow
                leaders, agents or representatives of a Student Organisation to
                host, manage and market their Student Organisation and events to
                Consumers. &nbsp; &nbsp;&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            <p>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  “Team Member”&nbsp;
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                means any agent or representative of a Student Organisation that
                creates a Group Page and any User that is appointed and listed
                on a Group Page as a Team Member. It also includes any User that
                uses our Services to create, manage or promote events or Student
                Organisations for Consumers. &nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>"</span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Organiser
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                " means any Team Member as well as any actors or agents acting
                on behalf of any Team Member.&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>“</span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Services
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ” include the Youni Properties and the Organiser Services
                (defined in&nbsp;
              </span>
              <span
                style={{
                  color: "#39364f",

                  fontSize: "10.5pt",
                }}
              >
                Youni’s Organiser
              </span>
                <span
                  style={{
                    color: "#1155cc",

                    fontSize: "10.5pt",
                  }}
                >
                  &nbsp;
                </span>
              <span style={{ fontSize: "10.5pt" }}>Agreement</span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ).&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>“</span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Site Content
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ” refers to Material contained in or delivered via the Services
                or otherwise made available by Youni in connection with the
                Services.&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>“</span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Your Content
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ” is any Material that you contribute, provide, post, or make
                available using the Services, or that you otherwise contribute,
                provide, post or make available to us, or that you authorise us
                to use. This includes any events you post and the content of
                your Organiser/personal Profile page.&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>“</span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Your Trademarks
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ” are the trademarks, trade names, service marks, and logos that
                you contribute, provide, post, or make available using the
                Services, or that you otherwise contribute, provide, post or
                make available to us, or that you authorise us to use.
                &nbsp;&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Organisers, Consumers, and third parties accessing or using our
                Services are all referred to in these Terms collectively as "
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Users
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>," "</span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  you
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ," or "
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  your.
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>"</span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                When these Terms use "
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Youni
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>," "</span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>we</span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>," "</span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>us</span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ," or "
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  our
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ," that refers to Youni and its affiliates, and subsidiaries,
                and each of its and their respective officers, directors,
                agents, partners, and employees.&nbsp;
              </span>
            </p>
          </li>
        </ol>
        <h4>
          <strong>
            <span style={{ color: "#666666", fontSize: "12pt" }}>1.2</span>
          </strong>
          <span style={{ color: "#666666", fontSize: "12pt" }}>&nbsp;</span>
          <strong>
            <em>
              <span style={{ color: "#666666", fontSize: "12pt" }}>
                What else? There may be other terms that apply to you.
              </span>
            </em>
          </strong>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                By agreeing to these Terms, you acknowledge you have read and
                agree to the&nbsp;
              </span>
              <span
                style={{
                  color: "#39364f",

                  fontSize: "10.5pt",
                }}
              >
                Privacy Policy and Cookie Statement
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                &nbsp;applicable to all Users, which are incorporated by
                reference into, and are part of, these Terms.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                All Users must abide&nbsp;
              </span>
              <span
                style={{
                  color: "#39364f",

                  fontSize: "10.5pt",
                }}
              >
                by Youni’s Community Guidelines&nbsp;
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                which are incorporated by reference into, and are part of, these
                Terms. Please read these carefully, as they affect what types of
                content and conduct are permitted when using the Services.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Users may be bound by the following additional terms, which are
                incorporated by reference into, and are part of, these Terms:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    If you are an Organiser, Youni's Organiser
                  </span>
                  <span
                    style={{
                      color: "#39364f",

                      fontSize: "10.5pt",
                    }}
                  >
                    &nbsp;Agreement applies to you.&nbsp;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    If you purchase, use, or participate as an Advertiser in the
                    Youni Ads Services (as such terms are defined in the Youni
                    Ads Terms and Conditions), the agreements made between you
                    and Youni upon becoming an Advertiser also apply to you.
                    &nbsp;
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We may sometimes provide you with services that are not
                described in these Terms and unless we have entered into a
                separate, signed agreement that expressly supersedes these
                Terms, these Terms will apply to those services as well.
              </span>
            </p>
          </li>
        </ol>
        <h4>
          <strong>
            <span style={{ color: "#666666", fontSize: "12pt" }}>1.3</span>
          </strong>
          <span style={{ color: "#666666", fontSize: "12pt" }}>&nbsp;</span>
          <strong>
            <em>
              <span style={{ color: "#666666", fontSize: "12pt" }}>
                Using our Services on behalf of an entity will bind that entity
                to these Terms.
              </span>
            </em>
          </strong>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you will be using the Services on behalf of an entity (such as on
            behalf of your Organiser, organisation, employer or University), you
            agree to these Terms on behalf of that entity and its Affiliates and
            you represent that you have the authority to do so. In that case,
            "you" and "your" will refer to that entity as well as yourself.
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              2. Youni's Services and Role
            </span>
          </strong>
        </h3>
        <h4>
          <strong>
            <span style={{ color: "#666666", fontSize: "12pt" }}>2.1</span>
          </strong>
          <span style={{ color: "#666666", fontSize: "12pt" }}>&nbsp;</span>
          <strong>
            <em>
              <span style={{ color: "#666666", fontSize: "12pt" }}>
                What we do.
              </span>
            </em>
          </strong>
          <span style={{ color: "#666666", fontSize: "12pt" }}>&nbsp;</span>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We’re a university community and events management and growth
            platform that helps Organisers and Consumers thrive and connect
            through memorable lived experiences. Through our Services,
            Organisers can create group pages to connect and communicate with
            their following, &nbsp;grow their following to new Users, collect
            and manage their following’s data, &nbsp;receive sponsorship,
            process membership payments and generally promote, communicate with
            and manage their group. Through our Services, Organisers can also
            post, manage and market online or in-person events, sell tickets,
            solicit donations and stay connected to Consumers through a suite of
            marketing tools.
          </span>
        </p>
        <h4>
          <strong>
            <span style={{ color: "#666666", fontSize: "12pt" }}>2.2</span>
          </strong>
          <span style={{ color: "#666666", fontSize: "12pt" }}>&nbsp;</span>
          <strong>
            <em>
              <span style={{ color: "#666666", fontSize: "12pt" }}>
                How we fit in.
              </span>
            </em>
          </strong>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We are not the creator, organiser, or owner of the events listed on
            the Services nor are we the seller of tickets, registrations or any
            merchandise on the Services. Instead, we provide our Services, which
            allow Organisers to manage their group and following, ticketing and
            registrations and promote their events/group.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            When creating, running and managing their group and their group
            page, the Organiser is solely responsible for ensuring that their
            group page and any page representing themselves complies with any
            applicable laws, rules, and regulations, and that the information,
            content, goods and services described on the group page are
            delivered as described and in an accurate, satisfactory manner. This
            must also be done in a manner consistent with all other relevant
            terms incorporated or referred to in these Terms. Organisers are
            also solely responsible for assuring any agents of their group or
            organisation (regardless of the legal status of their group or
            organisation) are aware of their rights, responsibilities,
            obligations and liabilities. Organisers warrant that on electing a
            new team member, they have the approval, authority and consent of
            that team member to bind that team member to the obligations of both
            a Consumer and a Organiser.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            When hosting an event, the Organiser is solely responsible for
            ensuring that their event and any page displaying an event complies
            with any applicable laws, rules, and regulations, and that the goods
            and services described on the event page are delivered as described
            and in an accurate, satisfactory manner. This must also be done in a
            manner consistent with all other relevant terms incorporated or
            referred to in these Terms.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If the Organiser is hosting an event with paid tickets, or is
            selling membership to Users, or is otherwise soliciting donations or
            transactions for value from Users, the Organiser must do so as
            described in&nbsp;
          </span>
          <span
            style={{
              color: "#39364f",

              fontSize: "10.5pt",
            }}
          >
            Youni’s Organiser Agreement
          </span>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            . We are never the contracting party where payments occur between
            Organisers and Consumers.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If the Organiser uses&nbsp;
          </span>
          <span
            style={{
              color: "#39364f",

              fontSize: "10.5pt",
            }}
          >
            Youni Payment Processing (defined in Youni’s Organiser Agreement)
          </span>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            , we act as the Organiser's limited agent to process payments from
            Consumers on the Organiser's behalf using our third-party payment
            service providers.&nbsp;
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>3-4</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              3. Privacy and Consumer Information
            </span>
          </strong>
        </h3>
        <h4>
          <strong>
            <span style={{ color: "#666666", fontSize: "12pt" }}>3.1</span>
          </strong>
          <span style={{ color: "#666666", fontSize: "12pt" }}>&nbsp;</span>
          <strong>
            <em>
              <span style={{ color: "#666666", fontSize: "12pt" }}>
                We follow our Privacy Policy when handling and protecting your
                personal data.
              </span>
            </em>
          </strong>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We are committed to protecting your personal data that you provide
            or that we collect through Youni Properties as set forth in
            our&nbsp;
          </span>
          <span
            style={{
              color: "#39364f",

              fontSize: "10.5pt",
            }}
          >
            Privacy Policy.
          </span>
        </p>
        <h4>
          <strong>
            <span style={{ color: "#666666", fontSize: "12pt" }}>3.2</span>
          </strong>
          <span style={{ color: "#666666", fontSize: "12pt" }}>&nbsp;</span>
          <strong>
            <em>
              <span style={{ color: "#666666", fontSize: "12pt" }}>
                We expect you to follow the rules when dealing with information
                about Consumers.
              </span>
            </em>
          </strong>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you are an Organiser, you will at all times comply with all
            applicable local, state, provincial, national, and other laws,
            rules, and regulations with respect to information you collect from
            (or receive about) Consumers or other individuals, and comply with
            any applicable policies posted by us on the Services with respect to
            information you collect from (or receive about) Consumers. If you
            are processing Consumer data via our Services you will at all times
            ensure you are processing that data in accordance with UK GDPR and
            data protection legislation and law.&nbsp;
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              4. Term; Termination
            </span>
          </strong>
        </h3>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>4.1&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              These Terms apply when you access our Services.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            These Terms apply to you as soon as you access the Services by any
            means and will continue to apply until they are terminated. There
            may come a time when either you or Youni decides it's best to part
            ways as described in Sections 4.2 or 4.3 below. When that happens,
            these Terms will generally no longer apply. However, as described in
            Section 4.4, certain provisions will always remain applicable to
            both you and us.
          </span>
        </p>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>4.2&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              We can revoke your access to our Services.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We may suspend or terminate your right to use the Services at
                any time, including if:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you violate or breach these Terms or any other agreement
                    between you and us, or if you fail to pay any amounts when
                    due;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you misuse or abuse the Services, or use the Services in a
                    way not intended or permitted by us;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you engage in any conduct on or off the Youni Properties
                    that jeopardises the safety of our community or integrity of
                    the Youni Properties or interferes with the experience of
                    our community or the Youni Properties; or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    allowing you to access and use the Services would violate
                    any applicable local, state, provincial, national, or other
                    laws, rules and regulations or court order or would expose
                    us to legal liability;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "10.5pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    Your Content or use of our Services is in breach of any
                    University Regulation that binds either you, or if you are
                    an Organiser, your following, you yourself or your team
                    members.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We may choose to stop offering the Services, or any particular
                portion of the Services, or modify or replace any aspect of the
                Services, at any time. We will use reasonable efforts to provide
                you with notice of our termination of your access to the
                Services, if we believe that failure to do so would materially
                prejudice you. You agree that we will not be liable to you or
                any third party as a result of our termination of your right to
                use or otherwise access the Services.
              </span>
            </p>
          </li>
        </ol>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>4.3&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              We hope you want to continue using our Services, but please refer
              to this paragraph if you don’t.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Except as agreed otherwise in a separate written agreement between
            you and us or other&nbsp;
          </span>
          <span
            style={{
              color: "#39364f",

              fontSize: "10.5pt",
            }}
          >
            Affiliated Agreement (defined in Youni’s Organiser Agreement)
          </span>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            , you may terminate your access to the Services and the general
            applicability of Terms by
          </span>
          <span
            style={{
              color: "#39364f",

              fontSize: "10.5pt",
            }}
          >
            &nbsp;deleting your account
          </span>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            . If you are a Consumer using the Services without a registered
            Youni account, your only option for these Terms to no longer apply
            is to stop accessing the Services indefinitely. So long as you
            continue to access the Services, even without an account, these
            Terms remain in effect. If there is a separate agreement between you
            and us governing your use of the Services and that agreement
            terminates or expires, these Terms (as unmodified by such agreement)
            will govern your use of the Services after such termination or
            expiration.
          </span>
        </p>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>4.4&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              Certain provisions will always remain applicable to both you and
              us.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            All provisions of these Terms that by their nature should survive
            termination of these Terms will survive (i.e., they will continue to
            apply to you) including all limitations on liability, releases,
            indemnification obligations, disclaimers of warranties, agreements
            to arbitrate, choices of law and judicial forum, and intellectual
            property protections and licences.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>5-6</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              5. Export Controls and Restricted Countries We’re not allowed to
              provide our Services to certain people.
            </span>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            As a company based in the United Kingdom, we comply with certain
            export controls and economic sanctions laws. You represent and
            warrant that:
          </span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                you are not located in, and you are not a national or resident
                of, any country to which the United States, United Kingdom,
                European Union, Australia, or Canada has embargoed goods and/or
                services of the same type as the Services, including Cuba, Iran,
                North Korea, Syria or the Crimea region of Ukraine; and
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                you are not a person or entity, or owned by, under the control
                of, or affiliated with, a person or entity that:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    appears on the U.S. Office of Foreign Assets Control's
                    Specially Designated Nationals List, Foreign Sanctions
                    Evaders List or Palestinian Legislative Council List; the
                    U.S. Department of State's Terrorist Exclusion List; the
                    Bureau of Industry and Security's Denied Persons, Entity or
                    Unverified List; the Consolidated List of Targets published
                    by the U.K. HM Treasury; the Consolidated List published by
                    the A.U. Department of Foreign Affairs and Trade; or any
                    similar or related UK lists.&nbsp;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    is subject to sanctions in any other country; or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    is engaged in the design, development or production of
                    nuclear, biological or chemical weapons, missiles or
                    unmanned aerial vehicles.
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              6. Release and Indemnification
            </span>
          </strong>
        </h3>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>6.1&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              Release. You won’t bring us into any disputes between you and a
              third party.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You hereby agree to release us (collectively with our affiliates
                and subsidiaries, and each of our and their respective officers,
                directors, agents, co-branders, licensors, payment processing
                partners, vendors, other partners, independent contractors and
                employees, the “
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Youni Released Parties
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ”) from all damages (whether direct, indirect, incidental,
                consequential, or otherwise), losses, liabilities, costs, and
                expenses of every kind and nature, known and unknown, arising
                out of a dispute between you and a third party (including other
                Users) in connection with any of the following:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    the Services or any event listed on the Services, including
                    Your Youni events and group content;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    your Licensure (defined below), any failure to obtain or
                    maintain any Licensure, or any error in obtaining or
                    maintaining any Licensure;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    any Feedback (defined below) that you give or receive; or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    Your Content or Your Trademarks.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                In addition, you waive any applicable law or statute, which
                says, in substance: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS
                WHICH THE RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN
                HIS FAVOUR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN
                BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE
                RELEASED PARTY."
              </span>
            </p>
          </li>
        </ol>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>6.2&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              Indemnification. You agree to have our back if a third party comes
              after us because of something you did or failed to do.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You agree to defend, indemnify, and hold harmless the Youni
                Released Parties from any and all damage (whether direct,
                indirect, incidental, consequential or otherwise), loss,
                liability, cost and expense (including reasonable legal and
                accounting fees) resulting from any claim, demand, suit,
                proceeding (whether before an arbitrator, court, mediator or
                otherwise) or investigation made by any third party (each a "
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Claim
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ") relating to or arising out of:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    your breach of these Terms (including any terms or
                    agreements or policies incorporated into these Terms);
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    your unauthorised use of the Services;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    your Licensure, any failure to obtain or maintain any
                    Licensure, or any error in obtaining or maintaining any
                    Licensure;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    any Feedback that you give or receive;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    your breach of any applicable local, state, provincial,
                    national or other law, rule or regulation or the rights of
                    any third party, or breach of any University regulation that
                    you, or if you are an Organiser your following or your team
                    members, are bound by;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    our collection and remission of taxes; and
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    if you are an Organiser, Your Content (including where we
                    have provided Services with respect to events) and Your
                    Content and Your Trademarks, provided that in the case of
                    (vii) this indemnification will not apply to the extent that
                    the Claim arises out of Youni's gross negligence or willful
                    misconduct.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We will provide notice to you of any such Claim, but our failure
                or delay in providing such notice will not limit your
                indemnification obligations except to the extent you are
                materially prejudiced by such failure. Also, in certain
                circumstances, we may choose to handle the Claim ourselves, in
                which case you agree to cooperate with us in any way we request.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>7-8</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              7. Disclaimer of Warranties and Assumption of Risks by You&nbsp;
            </span>
          </strong>
        </h3>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Youni uses commercially reasonable efforts to provide the
                Services without interruptions and such that transmissions are
                error-free. However, by nature of the Internet, this cannot
                always be achieved. In addition, Youni may take some or all of
                the Services down for planned repair or maintenance from time to
                time. Youni will use commercially reasonable efforts to notify
                you prior to such planned repairs or maintenance.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Youni does not assume any warranty for the content, products,
                services, actions or inactions of any User or third party
                before, during and/or after an event. You acknowledge that Youni
                has no control over and does not guarantee the quality, safety
                or legality of events or groups advertised, the truth or
                accuracy of any Users’ (including Attendees’, other
                non-Organisers’ and Organisers’) content or listings, or the
                ability of any User (including Attendees and Organisers) to
                perform or actually complete a transaction and Youni is not
                affiliated with, and has no agency or employment relationship
                with, any third party service provider used by Users in
                conjunction with the Services, and Youni has no responsibility
                for the acts or omissions of any such third parties. However,
                Youni wants its customers to report any misconduct in connection
                with the Services by
              </span>
              <span
                style={{
                  color: "#39364f",

                  fontSize: "10.5pt",
                }}
              >
                &nbsp;contacting us.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>7.1&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              We strive to provide Services in the way you need them, but there
              are some things we can’t promise.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                To the extent allowed under applicable laws, the Services
                (including the Beta Services, defined below) are provided on an
                "as is" and "as available" basis. Youni expressly disclaims all
                warranties of any kind, express or implied, including implied
                warranties of merchantability, title, non-infringement, and
                fitness for a particular purpose. For example, we make no
                warranty that:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    the Services (or any portion of the Services) will meet your
                    requirements or expectations;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    the Services will be uninterrupted, timely, secure, or
                    error-free; or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    the results that may be obtained from the use of the
                    Services will be accurate or reliable.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We have no control over and do not guarantee the (i) quality,
                safety, success, accuracy, or legality of any event or Site
                Content associated with an event, (ii) accuracy of any
                information provided by Users (including Feedback and Consumers’
                personal information shared with Organisers in connection with
                events), or (iii) ability of any User to complete a
                transaction.&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We are not liable for the acts or omissions of any third
                parties, including third parties that help us provide the
                Services, that an Organiser chooses to assist with an event, or
                that you choose to use or contract with when using the Services.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              7.2&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You must assume risks that are inherent in attending live
                events.
              </span>
            </em>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Some events may carry inherent risk and by participating in those
            events, you choose to assume those risks voluntarily. For example,
            some events may carry risk of illness, bodily injury, disability, or
            death, and you freely and willfully assume those risks by choosing
            to participate in those events.
          </span>
        </p>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              7.3&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Most events on our platform are not hosted by Youni, but if and
                when you participate in one that is, you assume all risks.
              </span>
            </em>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            When you attend an event hosted by us, you waive any and all claims
            and causes of action against the Youni Released Parties, the event
            producers and presenters, and their insurers, for liability,
            including for personal injury, property damage or wrongful death in
            connection with your attendance of the event.
          </span>
        </p>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              7.4&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You may have an opportunity to try out certain beta services
                from time to time.
              </span>
            </em>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may make available certain beta and other pre-release software,
            services, equipment, and related documentation, materials, and
            information to you from time to time, for your use on a voluntary
            basis, as part of an early-release, early-access program
            (collectively, the “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Beta Services
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”) for the purposes of us gathering information and Users providing
            us with Feedback on the quality and usability of the Beta Services.
            The Beta Services may not meet the same level of performance as that
            of a commercially available product offering, and the Beta Services
            may not operate correctly and may be subject to substantial
            modification, including deprecation, during and after the period in
            which you may use them. We are not liable to you in connection with
            your use of our Beta Services, and we may revoke your access to the
            Beta Services at any time with or without reason or notice.&nbsp;
          </span>
        </p>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              7.5&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                The disclaimers will apply so long as they are allowed under
                law.
              </span>
            </em>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The disclaimers in these Terms apply to the maximum extent permitted
            by law. If any warranties are required by applicable law, they will
            be limited to the shortest duration allowed.&nbsp;
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              8. Limitation of Liability
            </span>
          </strong>
        </h3>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>8.1&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              In order to provide our Services on a large scale, we have to
              limit our liability to you.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                To the extent permitted by applicable laws, the Youni Released
                Parties, will not be liable to you or any third party, for:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    Any indirect, incidental, special, consequential, punitive
                    or exemplary damages, including damages for loss of profits,
                    goodwill, use, data, opportunity costs, intangible losses,
                    or the cost of substitute services (even if we have been
                    advised of the possibility of such damages);
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    any Feedback that you give or receive; or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    Your Content or Your Trademarks.&nbsp;
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                In addition, other than our obligation to pay out Event Proceeds
                in certain circumstances to certain Organisers under
                Youni’s&nbsp;
              </span>
              <span
                style={{
                  color: "#39364f",

                  fontSize: "10.5pt",
                }}
              >
                Organiser Agreement
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                , and only in accordance with those terms, the Youni Released
                Parties’ maximum aggregate liability is limited to the
                following:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    For Users on our Site with paid tickets:
                  </span>
                </p>
                <ol>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        The Youni Fees (net of any Youni Payment Processing
                        Fees) that the User paid us in the three (3) month
                        period immediately preceding the circumstances giving
                        rise to their claim.
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    For Organisers:
                  </span>
                </p>
                <ol>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        The Youni Fees (net of any Youni Payment Processing
                        Fees) that the Organiser paid us in the three (3) month
                        period immediately preceding the circumstances giving
                        rise to their claim.
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        If no Youni Fees were paid, one hundred GBP (GBP £100).
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "10.5pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    EU/UK specific&nbsp;
                  </span>
                </p>
                <ol>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "10.5pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        Section 8.1, 1-2 (Limitation of Liability) of the Terms
                        of Service does not apply in the EU/UK to (a) any
                        damages caused by any personal injury or death caused by
                        Youni’s negligence, and (b) any damages caused by
                        Youni’s gross negligence or intentional misconduct, in
                        each case, for which Youni’s liability shall be limited
                        by the laws of England and Wales. In addition, for
                        failing to discharge obligations the nature of which is
                        essential to the performance of the Services and the
                        purposes of the Terms of Service, Youni’s liability
                        shall be subject to Section 8.1 (Limitation of
                        Liability) of the Terms of Service, but the limits shall
                        be amended to include typical and foreseeable damages
                        arising from such failures.
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>8.2&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              All of our Terms are meant to comply with the law.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Some jurisdictions do not allow the exclusion of certain warranties
            or conditions on, or the limitation of, liability for loss or damage
            caused by willful acts, negligence, breach of contract or breach of
            implied terms, or incidental or consequential damages. Only those
            liability and other limitations which are lawful in your
            jurisdiction (if any) will apply to you, and our liability is
            limited to the maximum extent permitted by law.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>9-10</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              9. IMPORTANT: BINDING ARBITRATION AND CLASS ACTION WAIVER
              PROVISIONS.
            </span>
          </strong>
        </h3>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS. ANY
              DISPUTE OR CLAIM UNDER THESE TERMS OR WITH RESPECT TO THE SERVICES
              WILL BE SETTLED BY BINDING ARBITRATION OR IN SMALL CLAIMS COURT
              (TO THE EXTENT THE CLAIM QUALIFIES) AND WILL TAKE PLACE ON AN
              INDIVIDUAL BASIS ONLY; YOU AGREE THAT CLASS, CONSOLIDATED OR
              REPRESENTATIVE ARBITRATIONS AND CIVIL ACTIONS ARE NOT PERMITTED
              AND ANY RIGHTS TO BRING SUCH ACTIONS ARE WAIVED BY EACH PARTY.
            </span>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The parties understand that, absent this mandatory provision, they
            would have the right to sue in court and they might have the right
            to a jury trial. They further understand that, in some instances,
            the costs of arbitration could exceed the costs of litigation and
            the right to discovery may be more limited in arbitration than in
            court.
          </span>
        </p>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>9.1&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              Contact us first if you have an issue with our Services.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You agree that if you have a question or concern about the Services,
            you will&nbsp;
          </span>
          <span
            style={{
              color: "#39364f",

              fontSize: "10.5pt",
            }}
          >
            contact us
          </span>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;first, prior to initiating any legal proceeding. Our customer
            support team will try to answer your question or resolve your
            concern.
          </span>
        </p>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>9.2&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              If a dispute can’t be resolved between us, it must be resolved
              through arbitration.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            In the unlikely event that our customer support team is unable to
            resolve your concerns, you and we each agree to resolve all disputes
            and claims between you and us, including any arising under or
            relating to these Terms, your use of the Services, or your and our
            relationship, through binding arbitration or (to the extent the
            claim qualifies) in small claims court, instead of in courts of
            general jurisdiction. As explained below, the appointed arbitrator
            will have authority to enter all relief that would be available in
            court, to the extent warranted by the claims. All arbitrations and
            small claims proceedings will proceed only on an individual basis.
            In no event may either we or you seek to resolve a dispute with the
            other as part of any purported class, consolidated or representative
            proceeding. Binding arbitration is subject to very limited review.
            Only the appointed arbitrator – and not any court – will have the
            authority to resolve any dispute relating to this Section, including
            any dispute regarding the scope, enforceability, and arbitrability
            of these Terms. This arbitration provision will survive termination
            of these Terms and any other agreement between you and us.
          </span>
        </p>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>9.3&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              Our agreement to arbitrate applies to almost all claims.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                This agreement to arbitrate applies to all legal disputes
                between you and us. It includes, but is not limited to: (i) all
                claims relating to any aspect of the relationship between you
                and us, whether based in contract, tort, statute, fraud,
                misrepresentation, or any other legal theory; (ii) all claims
                that arose before this or any prior agreement (including claims
                relating to advertising); and (iii) all claims that may arise
                after termination of these Terms and/or your use of the
                Services.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Notwithstanding this agreement to arbitrate, you or we may
                choose to bring:&nbsp;
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    an action on an individual basis in small claims court (to
                    the extent the applicable claim qualifies); or&nbsp;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    enforcement actions, validity determinations or claims
                    relating to theft, piracy or unauthorised use of
                    intellectual property.
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Intellectual Property Rights
            </span>
          </strong>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              <br />
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            " means patents, copyrights, moral rights, trademarks, and trade
            secrets, but not privacy or publicity rights.
          </span>
        </p>
        <ol start={3}>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                In addition, the portion of any dispute or complaint relating to
                our participation in the US-EU or US-Swiss Privacy Shield
                Frameworks is subject to the Dispute Resolution section of our
                Privacy Policy before being subject to this Section.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Any dispute arising out of or in connection with this contract,
            including any question regarding its existence, validity or
            termination, shall be referred to and finally resolved by
            arbitration under the LCIA Rules, which Rules are deemed to be
            incorporated by reference into this clause.
          </span>
        </p>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>9.4&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              Appointed Arbitrator
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We each agree that any arbitration required as per above shall be
            referred to and finally resolved by arbitration under the LCIA Rules
            and the terms stated below, in so far as they comply with the LCIA
            Rules, which Rules are deemed to be incorporated by reference into
            this clause.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The number of arbitrators shall be one.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The seat, or legal place, of arbitration shall be the United
            Kingdom.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The language to be used in the arbitral proceedings shall be
            English.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The governing law of the contract shall be the substantive law of
            the United Kingdom.
          </span>
        </p>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>9.5&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              We both agree to not bring a class action.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                YOU AND YOUNI AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
                ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
                CLAIMANT/PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS,
                CONSOLIDATED OR REPRESENTATIVE PROCEEDING. THE ARBITRATOR MAY
                NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS, AND MAY NOT
                PRESIDE OVER ANY FORM OF CLASS, CONSOLIDATED OR REPRESENTATIVE
                PROCEEDING. THE ARBITRATOR MAY (IF WARRANTED) ISSUE ALL OF THE
                SAME RELIEF THAT WOULD BE AVAILABLE IN COURT, INCLUDING PUBLIC
                INJUNCTIVE RELIEF, IN FAVOUR OF THE PARTY SEEKING SUCH RELIEF,
                BUT ONLY TO THE EXTENT AUTHORISED BY LAW AND WARRANTED BY THE
                PARTY’S CLAIMS.&nbsp;
              </span>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You and we each acknowledge that different arbitrations may present
            overlapping factual or legal issues. As such, to the fullest
            allowable extent, you and we each agree that in the event one of you
            or us initiates an arbitration against the other and we determine,
            in our sole discretion, that such arbitration presents one or more
            questions of fact or law that are also at issue in a pending
            arbitration between us and a third party (a “
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Similar Arbitration
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ”), the arbitration involving you will, at our request, be assigned
            to the same arbitrator presiding over the Similar Arbitration and/or
            be paused until the Similar Arbitration is resolved. Any rulings in
            any Similar Arbitration will not be binding in the arbitration
            involving you.
          </span>
        </p>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>9.6&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              Notice must be given when one of us intends to seek arbitration.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            A party who intends to seek arbitration must first send to the other
            a written Notice of Dispute ("
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Dispute Notice
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            "). The Dispute Notice to Youni must be addressed to the following
            address ("
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              Notice Address
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            ") and must be sent by certified mail: TBC GROUP LTD., 61 The
            Ridgeway, London, NW11 8QL. Dispute Notices to you will be addressed
            to a mailing, home, or payment address currently on record with
            Youni and must be sent by certified mail. If we have no records of a
            physical address, our Dispute Notice may be delivered to your Youni
            account email address. The Dispute Notice must (i) describe the
            nature and basis of the claim or dispute; and (ii) set forth the
            specific relief sought. If Youni and you do not reach an agreement
            to resolve the claim within sixty (60) calendar days after the
            Dispute Notice is sent, you or Youni may commence an arbitration
            proceeding.&nbsp;
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              9.7&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                These are the rules that will govern any arbitration proceedings
                in so far as they comply with the laws of England and Wales, and
                the LCIA Rules
              </span>
            </em>
          </strong>
          <em>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>.</span>
          </em>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              10. Licence to the Youni Services
            </span>
          </strong>
        </h3>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>10.1&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              Your right to use our Services is limited to the licence we grant
              you.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We grant you a limited, non-exclusive, non-transferable,
                non-sublicensable (except to sub-Users registered via the
                Services), revocable right to use our Services solely to:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    browse the Services and search for, view, register for, or
                    purchase tickets or registrations to an event listed on the
                    Services; to browse the Services and search for, view,
                    register for, follow, or subscribe to membership of an
                    Organiser’s group page; to create a profile for yourself; to
                    engage with community discussions; and/or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    create event registration, an Organiser page and profile and
                    other webpages/mobile app pages to promote, market, manage,
                    track, and collect sales proceeds for an event and your
                    Organiser page.
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "10.5pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    all other services advertised or presented from time to time
                    within and by the Youni Properties, including but not
                    limited to general socialisation features and general
                    features related to engaging with your university community.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Your use of the Services must comply with these Terms and all
                applicable local, state, provincial, national and other laws,
                rules and regulations. In addition, by using any search
                functionality or address auto-population tools, you are bound by
                the&nbsp;
              </span>
              <a href="https://maps.google.com/help/terms_maps.html">
                <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
                  Google Maps/Google Earth Additional Terms of Service
                </span>
              </a>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                &nbsp;(including the&nbsp;
              </span>
              <a href="https://www.google.com/intl/ALL/policies/privacy/index.html">
                <span style={{ color: "#1155cc", fontSize: "10.5pt" }}>
                  Google Privacy Policy
                </span>
              </a>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>).</span>
            </p>
          </li>
        </ol>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>10.2&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              You can’t copy, sell, or use our Services in a way that is
              damaging to Youni.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            In addition to any other restrictions, limitations, and prohibitions
            that we impose (in these Terms or elsewhere), you agree you will not
            directly or indirectly:
          </span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                copy, modify, reproduce, translate, localise, port, or otherwise
                create derivatives of any part of the Services;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                reverse engineer, disassemble, decompile, or otherwise attempt
                to discover the source code or structure, sequence, and
                organisation of all or any part of the Services;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                rent, lease, resell, distribute, use the Services for other
                commercial purposes not contemplated or otherwise exploit the
                Services in any unauthorised manner;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                remove or alter any proprietary notices on the Services;
                or&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                engage in any activity that interferes with or disrupts the
                Services.
              </span>
            </p>
          </li>
        </ol>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>10.3&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              Our property remains our property.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You agree that all Site Content may be protected by copyrights,
            trademarks, service marks, trade secrets or other intellectual
            property and other proprietary rights and laws. We may own the Site
            Content, or portions of the Site Content may be made available to us
            through arrangements with third parties. Site Content that is
            included in or made available through the Services is our exclusive
            property and is protected by copyright laws. You agree to use the
            Site Content only for purposes that are permitted by these Terms and
            any applicable local, state, provincial, national, or other law,
            rule, or regulation. Any rights not expressly granted in these Terms
            are reserved.
          </span>
        </p>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>10.4&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              You can’t use trademarks that aren’t yours unless we say so.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                The trademarks, service marks and logos of Youni (the "
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                <br />
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Youni Trademarks
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ") used and displayed in connection with the Services are
                registered and unregistered trademarks or service marks of
                Youni. Other company, product, and service names used in
                connection with the Services may be trademarks or service marks
                owned by third parties (the "
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Third Party Trademarks
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ," and, together with Youni Trademarks, the "
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Trademarks
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                "). Your licence to use the Services does not include, by
                implication, estoppel, or otherwise, any licence or right to use
                any Trademark displayed in connection with the Services without
                our prior written consent specifically for each such
                usage.&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <h4>
              <span style={{ color: "#666666", fontSize: "12pt" }}>
                You must not use the Trademarks to disparage us, any third
                party, or our or such third party's products or services, or in
                any manner that may damage any goodwill in the Trademarks. You
                must not use any Trademarks as part of a link to or from any
                website unless we approve such use by prior written consent
                specifically for each such link. All goodwill generated from the
                use of any Youni Trademark will inure to Younis benefit. Certain
                issued patents and patents pending apply to the Services. Site
                Content may also be protected by copyrights owned by us and/or
                third parties. Please note that if you copy portions of the
                Services, you are violating these patent rights and copyrights.
              </span>
            </h4>
          </li>
        </ol>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>10.5&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              Any sub-domains connected to our website will be owned by us.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may provide you with the right to use a sub-domain within the
            Site (e.g., [sub-domain prefix].thisisyouni.co.uk) for one or more
            of Your Youni Events. All such sub-domains are solely our property
            and we reserve the right to determine the appearance, design,
            functionality, and all other aspects of such sub-domains. If we
            provide you with a sub-domain, your right to use it will continue
            only for so long as you are actively selling tickets for Your Youni
            Events on the Services and provided you are in compliance with the
            Terms. If we terminate your right to use a sub-domain for any other
            reason, we will provide you with a new sub-domain.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>11-12</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              11. Licences and Permits Organisers Must Obtain Organisers must
              obtain all applicable licences, permits, and authorizations for
              their events.
            </span>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you are an Organiser, along with your other representations and
            warranties, you represent and warrant to us that:
          </span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You will obtain, before starting ticket sales, all applicable
                licences, permits, and authorisations required by law
                (individually and collectively, "
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Licensure
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                ") for Your Youni Events. Licensure includes state, county,
                council, municipal, or other local authority's authorisation of
                the event, traffic engineering authorisations, fire department
                inspection reports, fire marshal permits, authorisation to
                receive minors, sanitary authorisation, and property operation
                permits;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You will comply, and will ensure that the venues for Your Youni
                Events will comply, with all applicable laws, regulations,
                rules, and ordinances;&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You will maintain throughout the use of the Services the
                applicable Licensure to promote, produce, sponsor, host, and
                sell tickets for all of Your Youni Events; and
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You will provide evidence of Licensure and related information
                prior to offering tickets or registrations for Your Youni Events
                and promptly upon our reasonable request from time to time.
              </span>
            </p>
          </li>
        </ol>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              12. Your Rights to Submit a Copyright Takedown Notice What to do
              if you believe your copyrights are being violated.
            </span>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you are a copyright owner or an agent of a copyright owner and
            you believe that any content on the Sites infringes your copyrights,
            you must submit a notice by following the directions we provided
            in&nbsp;
          </span>
          <span
            style={{
              color: "#39364f",

              fontSize: "10.5pt",
            }}
          >
            Youni's Trademark and Copyright Policy.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>13-14</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              13. Scraping or Commercial Use of Site Content is Prohibited
            </span>
          </strong>
        </h3>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>13.1&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              You can’t use our content for your own purposes.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You have no right to use, and you agree not to use, any Site Content
            for your own commercial purposes. You have no right to, and you
            agree not to, scrape, crawl, or employ any automated means to
            extract data from the Sites.
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              14. Fees and Refunds.
            </span>
          </strong>
        </h3>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>14.1&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              We charge the following fees.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Creating an account, Organiser page and otherwise using the platform
            is free. However, we charge fees on paid tickets, membership fees
            and other financial transactions. These fees vary from time to time.
            The fees charged to Consumers may include other charges, including
            facility fees, royalties, taxes, processing fees, and fulfilment
            fees. Youni’s fees are presented and listed at checkout, and can be
            accepted or not at that moment. &nbsp;In addition, some additional
            fees may be charged to defray costs incurred by us, but may in some
            cases include an element of profit and in some cases include an
            element of loss. We do not control (and cannot disclose) fees levied
            by your bank and/or credit card company, including fees for
            purchasing tickets/membership and registrations in foreign
            currencies or from foreign persons. Be sure to check with your bank
            or credit card company prior to purchasing to understand all
            applicable fees, credit card surcharges, and currency conversion
            rates.
          </span>
        </p>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>14.2&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              You may be able to transfer your ticket.&nbsp;
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you wish to transfer tickets to an event you have purchased on
            the Site, please contact the Organiser of the event to arrange for
            ticket transfer. It is the exclusive discretion of the Organiser to
            either permit another person, the transferee, from utilising your
            ticket.&nbsp;
          </span>
        </p>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>14.3&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              Consumers requesting a refund should contact the Organiser.
              Consumers must not use a ticket that has been refunded, and
              Organisers must not accept invalid tickets.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Because all transactions are between an Organiser and its
                Consumers, we ask that all Consumers contact the applicable
                Organiser of their event with any refund requests.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                If you are a Consumer and you receive a refund for your ticket,
                you will discard the ticket and will not use it (or any copy of
                it) to attend the event. Violation of this is fraud.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                If you are an Organiser, you acknowledge that the applicable
                procedure to check the validity of the ticket must always be
                followed, including the scanning of the ticket via the in-app
                ticket scanner.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We will not be liable under any circumstances for any costs
                arising from Organisers’ non-compliance with the procedures that
                must be implemented by Organisers to check the validity of
                tickets. We will not be liable under any circumstances for costs
                and/or damage arising from ticket-related fraud and/or the
                purchase of the ticket through non-official means, such as third
                parties.
              </span>
            </p>
          </li>
        </ol>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>15-16</span>
          </strong>
        </h3>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              15. Your Account with Youni
            </span>
          </strong>
        </h3>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>15.1&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              When you create an account with us or use our Services, you must
              provide us with accurate information and follow these rules.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may require you to create an account to access certain features
            or functions of the Services. You agree to the following:
          </span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You must be at least 18 years of age, or the legal age of
                majority where you reside, to use the Services. If you are 13 or
                older, you may only use the Services under the supervision of a
                parent or legal guardian who manages your use of the Services
                and/or account. Those under the age of 13 are not permitted to
                use the Services at all.&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You must provide accurate, current, and complete information
                about yourself, and your academic status, including but not
                limited to the University you attend (if applicable) and other
                information relating to your degree as demanded by the platform,
                or if you are using the Services on behalf of an entity, that
                entity (the "
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                <br />
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Registration Data
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                "). You also must update this Registration Data if it changes.
                You must also update the Registration Data as required by Youni.
                Notice will be provided via email to your registered email
                address if any Registration Data needs updating, verification,
                and/or if we are updating the content requirements of
                Registration Data and further information about yourself is
                required. Failure to provide this accurate, up-to-date and from
                time to time updated requirements for Registration Data will
                result in your account being terminated. We may also require you
                to verify your student status.&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                If there is a dispute between two or more persons or entities
                regarding account ownership, we will be the sole arbiter of that
                dispute and our decision (which may include termination or
                suspension of the account) will be final and binding.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                If you are using the Services on behalf of a company or other
                entity, you represent and warrant that you have the authority to
                legally bind that entity and grant us all permissions and
                licences provided in these Terms.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We may provide you the ability to implement certain permissions
                within your account to third parties including, "sub-users,"
                "sub-accounts," or other credentialed account users. If we do
                so, you are solely responsible for all activity that occurs
                under your account (including actions by sub-users). You must
                maintain the confidentiality of your password and account
                details. All rules applicable to your account will apply to all
                third parties to whom you grant access to your account.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You will immediately notify us of any unauthorised use of your
                password or account, or any other breach of security. You are
                responsible for any activities that occur under your account.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You will not use the Services to collect any sensitive personal
                information, such as health information, national insurance,
                financial information, payment card numbers, driver's licence
                numbers, and passport numbers, unless otherwise permitted by
                these Terms or we have consented to the collection of the
                information in writing.
              </span>
            </p>
          </li>
        </ol>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              16. Your Content and Your Trademarks.
            </span>
          </strong>
        </h3>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>16.1&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              We have certain rights to use Your Content and Your Trademarks.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You are solely responsible for Your Content and Your Trademarks.
                You hereby grant us a non-exclusive, worldwide, perpetual,
                irrevocable, royalty-free, transferable, sublicensable right and
                licence to access, use, reproduce, transmit, adapt, modify,
                perform, display, distribute, translate, publish, and create
                derivative works based on:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    Your Content, in whole or in part, in any media, for
                  </span>
                </p>
                <ol>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        the purpose of operating the Services (including our
                        promotional and marketing services, which may include
                        promotion of you, your Organisation and Your Youni
                        Events on a third-party website or other media,
                        including our event distribution providers and our
                        social media properties);
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        Youni’s internal purposes (such as employee or
                        shareholder communications); and
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        when you give your permission, for the purposes of
                        promoting Youni or our Services; and
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    Your Trademarks,
                  </span>
                </p>
                <ol>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        in connection with our use of Your Content; and
                      </span>
                    </p>
                  </li>
                  <li
                    style={{
                      listStyleType: "decimal",
                      color: "#39364f",
                      fontSize: "12pt",
                    }}
                  >
                    <p>
                      <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                        for the purpose of identifying you as an existing or
                        past customer of Youni both on the Services and in
                        marketing, advertising and promotional materials.&nbsp;
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We don’t claim ownership to Your Content or Your Trademarks, but
            these licences are essential to provide you the Services. Consider
            the following examples: if you submit your logo or other images
            associated with your Organiser page or events, we can display them
            on our website and resize or enhance them so that they look good on
            our Applications, or use them with our marketing tools to help you
            promote your Organiser page or events; we may feature your Organiser
            details in a blog, case study, or shareholder letter to highlight
            the impact made by you.
          </span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You hereby waive any and all moral rights in connection with our
                use of Your Content in accordance with the licences in this
                Section 16. Aside from these licences, we do not claim, and you
                do not transfer, any ownership rights in any of Your Content or
                Your Trademarks and nothing in these Terms will restrict any
                rights that you may have to use and exploit Your Content and
                Your Trademarks outside of the Services.
              </span>
            </p>
          </li>
        </ol>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>16.2&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              You represent that you are able to grant us the rights described
              above.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You represent and warrant that you have all the rights, power and
            authority necessary to grant the foregoing licence, and that all
            Your Content and Your Trademarks:
          </span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                do not infringe, violate, misappropriate or otherwise conflict
                with the rights of any third party;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                comply with all applicable local, state, provincial, national,
                and other laws, rules and regulations; and
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                do not violate these Terms.
              </span>
            </p>
          </li>
        </ol>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>16.3&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              Your Content must comply with our rules and guidelines.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Your Content must be accurate and truthful. We reserve the right
                to remove Your Content from the Services if we believe that Your
                Content violates these Terms, our&nbsp;
              </span>
              <span
                style={{
                  color: "#39364f",

                  fontSize: "10.5pt",
                }}
              >
                Community Guidelines
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                , or for any other reason. Your Content and Your Trademarks may
                be displayed on the Services in proximate distance to any Site
                Content or any third-party content, including content that is
                substantially similar or competing with yours, and we do not
                guarantee your exclusivity as an Organiser in any category.
                Without limiting the generality of the foregoing, the Services
                are provided on a nonexclusive basis. We may preserve Your
                Content, Your Trademarks, Registration Data, and all other data
                associated with your account, and may also disclose Your
                Content, Your Trademarks, Registrations Data, and all other data
                associated with your account account if required to do so by law
                or in the good faith belief that such preservation or disclosure
                is reasonably necessary to:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    comply with legal process;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    respond to claims that any of Your Content or Your
                    Trademarks violate the rights of third parties;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    enforce or administer these Terms; and/or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    protect the rights, property, and/or personal safety of us,
                    our Users and/or the public, including fraud prevention.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You understand that the technical processing and transmission of
                the Services, including Your Content and Your Trademarks, may
                involve transmissions over various networks and/or changes to
                conform and adapt to technical requirements of connecting
                networks or devices.
              </span>
            </p>
          </li>
        </ol>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>17-18</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              17. Rules for Using our Messaging Tools
            </span>
          </strong>
        </h3>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>17.1&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              You must follow these rules when using our Messaging Tools.
            </span>
          </em>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We may provide features and tools that allow you to contact your
                Consumers, other users of the Services, or third parties via
                email, push notification or other messaging tools (the "
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Messaging Tools
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                "). If you use Messaging Tools, you agree that:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you have the right and authority to send messages to the
                    email addresses and other contact methods on your recipient
                    list, and such addresses and information were gathered in
                    accordance with applicable email and/or other marketing
                    regulations in the recipient's country of residence;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    your emails and other messages do not violate any privacy
                    policy under which the recipient addresses or other
                    information were gathered;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you will comply with all applicable local, state,
                    provincial, national, and other laws, rules and regulations,
                    including those relating to spam and email, and including
                    the U.S. CAN-SPAM Act, the Canadian CASL, and the EU GDPR
                    and e-privacy directive when using the Messaging Tools;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you will only use the Messaging Tools to advertise, promote,
                    and/or manage Your Content;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    your use of the Messaging Tools and the content of your
                    emails and other messages complies with these Terms;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you will not use false or misleading headers or deceptive
                    subject lines in emails sent using the Messaging Tools;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    if we send you a Consumer’s request to modify such
                    Consumer's email and other messaging preferences, you will
                    respond immediately and in accordance with instructions; and
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    you will provide an accessible and unconditional unsubscribe
                    link in every email where one is required, and you will not
                    send any emails or other messages to any recipient who has
                    unsubscribed from your applicable mailing/messaging list.
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "10.5pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    As an Organiser, you have your own GDPR and/or EU GDPR rules
                    that govern how you manage the data of your followers, and
                    the data of any Users that you receive via our Services,
                    Youni or otherwise, and that you and you alone are liable
                    for that data.
                  </span>
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <h4>
          <span style={{ color: "#666666", fontSize: "12pt" }}>17.2&nbsp;</span>
          <em>
            <span style={{ color: "#666666", fontSize: "12pt" }}>
              If you don’t comply, we may terminate your access to our Messaging
              Tools.
            </span>
          </em>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you violate any of these Terms or if your use of the Messaging
            Tools results in bounce rates, complaint rates, or unsubscribe
            requests in excess of industry standards, or if your emails cause
            disruption to the Services, we may (in addition to other remedies we
            have) limit or suspend your access to the Messaging Tools.
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              18. Notices.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                Here’s how to notify us.
              </span>
            </em>
          </strong>
        </h3>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Notices may be sent to you by email or regular mail at your
                business address listed in Youni's records. We may also provide
                notices of changes to these Terms or other matters by displaying
                notices or links to notices on the Services.&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                If you wish to contact us or deliver any notice(s), you can do
                so at the following address: &nbsp;TBC GROUP LTD., 61 The
                Ridgeway, London, NW11 8QL,; or via email to youni@tbcgroup.xyz
              </span>
            </p>
          </li>
        </ol>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>19-20</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              19. Modifications to the Terms or Services.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                We may modify these Terms from time to time and will notify you
                of material changes.
              </span>
            </em>
          </strong>
        </h3>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We reserve the right to modify these Terms (including the&nbsp;
              </span>
              <span
                style={{
                  color: "#39364f",

                  fontSize: "10.5pt",
                }}
              >
                Privacy Policy, Youni’s Organiser Agreement
              </span>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                , and other Terms referred to in this document) from time to
                time (collectively, "
              </span>
              <strong>
                <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                  Modifications
                </span>
              </strong>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                "). If we believe any Modifications are material, we will let
                you know by one (or more) of the following methods:
              </span>
            </p>
            <ol>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    posting the changes through the Services;
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    updating the "Updated" date at the top of this page; or
                  </span>
                </p>
              </li>
              <li
                style={{
                  listStyleType: "decimal",
                  color: "#39364f",
                  fontSize: "12pt",
                }}
              >
                <p>
                  <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                    sending you an email or message about the Modifications.
                  </span>
                </p>
              </li>
            </ol>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Modifications that are material will be effective thirty (30)
                days following the "Updated" date, unless a different date is
                communicated in our notice to you. All other Modifications will
                be effective immediately. &nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You are responsible for reviewing any Modifications. Your
                continued use of the Services after any Modification will be
                considered your acceptance of that Modification and the updated
                Terms. In certain circumstances, we may seek a Modification to
                these Terms that will only apply to you. This type of
                Modification must be in writing and signed by both parties (you
                and Youni).
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                We are constantly evolving our products and services to better
                meet the needs of our Users. Because of this, we cannot
                guarantee the availability of certain product features or
                functionality. We reserve the right to modify, replace, or
                discontinue any part of the Services or the entire Services at
                any time and for any reason.
              </span>
            </p>
          </li>
        </ol>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              20. Assignment.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                We can assign our rights and obligations under these Terms.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We may, without your consent, freely assign these Terms and our
            rights and obligations under these Terms, whether to an Affiliate or
            to another entity, in connection with a corporate transaction or
            otherwise.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>21-22</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              21. Entire Agreement.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                These Terms make up our entire agreement with you unless we also
                enter into a separate written agreement.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Except as otherwise stated in these Terms, these Terms are the
            entire agreement between you and us and govern your use of the
            Services, superseding any prior or contemporaneous agreements,
            proposals, discussions, or communications between you and Youni on
            the subject matter of these Terms, other than any written agreement
            for Services between you and us relating to a specified event or
            events.
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              22. Applicable Law and Jurisdiction.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                Disputes that can’t be resolved under our arbitration agreement
                will be resolved in the courts of England &amp; Wales under the
                laws of England &amp; Wales.
              </span>
            </em>
          </strong>
        </h3>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                These Terms are governed by the laws of England &amp; Wales,
                without regard to its conflict of laws rules. These laws will
                apply no matter where in the world you live, but if you live
                outside of the United Kingdom, you may be entitled to the
                protection of the mandatory consumer protection provisions of
                your local consumer protection law.&nbsp;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Youni is based in the United Kingdom. Any legal action against
                us related to our Services and not subject to the arbitration
                provisions in Section 9 of these Terms will take place in the
                United Kingdom. By agreeing to these Terms, you submit to the
                personal jurisdiction of the courts (as applicable) of the
                United Kingdom.
              </span>
            </p>
          </li>
        </ol>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>23-24</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              23. Feedback.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                We have a right to use any feedback you provide us.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            We welcome and encourage you to provide feedback, comments, and
            suggestions for improvements to the Services, as well as feedback,
            comments, suggestions, and ratings regarding the services and events
            of third parties such as the Organisers of events you attend
            (collectively, "Feedback"). Any Feedback you submit to us will be
            considered non-confidential and non-proprietary to you. By
            submitting Feedback to us, you grant us a non-exclusive, worldwide,
            royalty-free, irrevocable, sub-licensable, perpetual licence to use,
            publish, and exploit those ideas and materials for any purpose, with
            or without your name, without compensation to you. We may collect
            testimonials, ratings, and reviews about Site Content, the Services,
            and, if you are an Organiser, Your Content and events. These
            testimonials, ratings and reviews will be considered Feedback. We
            retain full discretion on whether, when, where, with whom, and how
            Feedback is shared or published. &nbsp;
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              24. Third Party Websites; Linked Accounts; Third Party
              Offers.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                We are not responsible for third-party websites or materials
                that you access.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The Services or Users may provide links to other Internet websites
            or resources. Because we have no control over such websites and
            resources, you agree that we are not responsible for the
            availability of such websites or resources. We do not endorse and
            are not responsible or liable for any Site Content, advertising,
            offers, products, services, or other materials on or available from
            such websites or resources and are not responsible for any damages
            or losses related to them, even if such websites or resources are
            connected with our partners or third-party service providers. For
            example, if you purchase ticket insurance on the Site from a third
            party, your contractual relationship is with the third-party ticket
            insurance provider, not with us.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>25-26</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              25. Additional Miscellaneous Provisions.&nbsp;
            </span>
          </strong>
          <strong>
            <em>
              <span style={{ color: "#39364f", fontSize: "13pt" }}>
                Here’s some more legal stuff before you go.
              </span>
            </em>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If we fail to enforce any part of these Terms, that will not amount
            to a waiver of our right to later enforce that or any other part of
            these Terms. Except as expressly set out in these Terms, the
            exercise by us of any of our remedies under these Terms will not
            preclude us from exercising our other remedies under these Terms or
            otherwise. No oral waiver, amendment, or modification of these Terms
            will be effective. If any provision of these Terms is found to be
            unenforceable, that part will be limited to the minimum extent
            necessary and the other provisions of these Terms remain in full
            force and effect. Section titles and subtitles in these Terms, along
            with the italicised text following them, are for convenience only
            and have no legal or contractual effect and do not amount to legal
            advice. When we say Youni “may” or has the right, is permitted,
            authorised, or allowed to do something in these Terms, it means we
            may, but are not obligated to, exercise the applicable rights or
            options or take the applicable action, as we determine in our sole
            discretion. Any determinations, decisions, or beliefs by us under
            these Terms may be made by us in our sole discretion. As used in
            these Terms, “including” means “including, but not limited to.” When
            these Terms say that you “will” take an action, this means that you
            are agreeing to take the action and that you must take that action.
            Your obligations, duties, warranties, representations, releases, and
            waivers throughout these Terms are also the obligations, duties,
            warranties, representations, releases, and waivers of your
            Affiliates. No independent contractor, agency, partnership, joint
            venture, or other such relationship is created by these Terms. We
            may freely assign any of our rights and obligations under these
            Terms. We may translate these Terms into other languages for your
            convenience. If there is a conflict between the English version and
            a translated version, the English version will control.
          </span>
        </p>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              26. Additional Clauses for Users in Certain Locations
            </span>
          </strong>
        </h3>
        <p>
          <span style={{ fontSize: "11pt" }}>
            Youni is a national company offering Organisers and Consumers the
            opportunity to benefit from our Services in the UK. We do not offer
            our services to other Users, but it is impossible for us to limit
            access, and therefore should you access our services elsewhere, you
            do so under the law and jurisdiction of the UK.&nbsp;
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>27</span>
          </strong>
        </h2>
        <h3>
          <strong>
            <span style={{ color: "#39364f", fontSize: "13pt" }}>
              27. Legal Cancellation Rights for Services Performed by Youni
            </span>
          </strong>
        </h3>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The following applies only to Organisers that are consumers (i.e.,
            individuals acting for purposes which are wholly or mainly outside
            their trade, business, craft or profession while using the
            Services):
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You may have a legal right to withdraw your agreement without giving
            any reason within a 14 day withdrawal period. If you have said legal
            right, in the case of a contract for services this withdrawal period
            expires 14 days from the day the contract was concluded. If you have
            said legal right, for goods, the withdrawal period expires 14 days
            from the day on which you acquire, or a third party other than the
            carrier indicated by you acquires, physical possession of the goods
            (or of the last good in case of multiple goods; or of the last lot
            or piece in case of goods consisting of multiple lots or pieces; or
            on the first good in case of the regular delivery of goods). By
            creating an event or by charging for membership and commencing the
            sale of tickets or registrations or memberships, you consent to and
            expressly request that the performance of the Services begins before
            the 14 day withdrawal period has expired and that you will not
            benefit from a legal right to withdraw once the related Service
            (e.g., the sale of a ticket or registration on the Services) has
            been fully provided.
          </span>
        </p>
        <h4>
          <strong>
            <span style={{ color: "#39364f", fontSize: "12pt" }}>
              27.1 Information on the Withdrawal Right
            </span>
          </strong>
        </h4>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Right of withdrawal
              </span>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You have the right to withdraw from this contract within 14 days
            without giving any reason. The withdrawal period will expire after
            14 days from the day of the conclusion of the contract. To exercise
            the right of withdrawal, you must inform us via notice as described
            above of your decision to withdraw from this contract by an
            unequivocal statement (e.g. a letter sent by post or e-mail).To meet
            the withdrawal deadline, it is sufficient for you to send your
            communication concerning your exercise of the right of withdrawal
            before the withdrawal period has expired.
          </span>
        </p>
        <ol start={2}>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Effects of withdrawal
              </span>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you withdraw from this contract, we shall reimburse to you all
            payments received from you, including the costs of delivery (with
            the exception of the supplementary costs resulting from your choice
            of a type of delivery other than the least expensive type of
            standard delivery offered by us), without undue delay and in any
            event not later than 14 days from the day on which we are informed
            about your decision to withdraw from this contract. We will carry
            out such reimbursement using the same means of payment as you used
            for the initial transaction, unless you have expressly agreed
            otherwise; in any event, you will not incur any fees as a result of
            such reimbursement. We may withhold reimbursement until we have
            received the goods back, if any, or you have supplied evidence of
            having sent back the goods, if any, whichever is the earliest.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You shall send back any goods ordered without undue delay and in any
            event not later than 14 days from the day on which you communicate
            your withdrawal from this contract to us. The deadline is met if you
            send back the goods before the period of 14 days has expired. You
            will have to bear the direct cost of returning any goods. You are
            only liable for any diminished value of the goods resulting from the
            handling other than what is necessary to establish the nature,
            characteristics and functioning of the goods.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you requested to begin the performance of services during the
            withdrawal period, you shall pay us an amount which is in proportion
            to what has been provided until you have communicated your
            withdrawal from this contract, in comparison with the full coverage
            of the contract.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            You will not benefit from a legal right to cancel your contract with
            Youni if your contract with Youni is for the provision of services
            related to leisure activities for a specific date or period.
          </span>
        </p>
        <h4>
          <strong>
            <span style={{ color: "#39364f", fontSize: "12pt" }}>
              27.2 Attendees’ Legal Withdrawal Rights: Contact the Organiser of
              Your Event
            </span>
          </strong>
        </h4>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you are an Attendee, your contract for tickets and registrations
            is between you and the Organiser. You pay the entire ticket price
            plus the cost that the organiser bears for Services with Youni (the
            booking fee etc). You must therefore inform the Organiser of the
            applicable event that you wish to withdraw from your contract.
          </span>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you benefit from a legal right to withdraw your purchase of
            tickets or registrations, you will need to inform the Organiser to
            exercise it. Please see the information the Organiser has provided
            to you for further details. However, please note that you will not
            benefit from a legal right to withdraw your contract if:
          </span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Your contract with the Organiser is for the provision of
                services related to leisure activities for a specific date or
                period (e.g., a ticket for a concert on a specified date);
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                The Organiser is an individual acting for purposes which are
                wholly or mainly outside their trade, business, craft or
                profession; or
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                You are not an individual acting for purposes which are wholly
                or mainly outside your trade, business, craft or profession.
              </span>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Your contract with the Organiser is fully performed by the Organiser
            within the 14 day withdrawal period. (e.g. if you purchase a ticket
            for an event taking place in 7 days’ time, you cannot attend that
            event and then exercise your legal right to withdraw).
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
      </>
    </div>
  );
};