import Layout from "../layouts/layout"
import Team from "../sections/_contact/team"
import Touch from "../sections/_contact/touch"
import styles from "../styles/contact.module.scss"

const Contact = () => {
    return (
        <Layout>
            <div className={styles.container}>
                <Touch />
                <Team />
            </div>
        </Layout>
    )
}

export default Contact