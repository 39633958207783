import styles from "../../styles/contactForm.module.scss"
import { PopupButton } from "@typeform/embed-react";

const Form = () => {
    return (
      <div className={styles.container}>
        <PopupButton
          id="X8VpZkxX"
          className={styles.sendBtn}
        >
          Contact Us
        </PopupButton>
        {/* <form>
              <div className={styles.field}>
                  <label>Your Name</label>
                  <input type="text" placeholder="Your name" />
              </div>
              <div className={styles.field}>
                  <label>Email</label>
                  <input type="text" placeholder="Your email" />
              </div>
              <div className={styles.field}>
                  <label>Message</label>
                  <textarea placeholder="Type here..."></textarea>
              </div>

              <div className={styles.bottom}>
                  <div className={styles.attach}>
                      <img src="/assets/attach.png" alt="attach" />
                      <p>Attach file</p>
                  </div>

              </div>
                  <button>Send</button>
          </form> */}
      </div>
    );
}

export default Form