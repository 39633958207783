import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import styles from "../styles/header.module.scss";

const Header = ({ setIsOpen }) => {
  const path = window.location.pathname;

  const navigate = useNavigate();

  const headerClass =
    path === paths.home || path === paths.contact
      ? styles.primary
      : styles.secondary;

  return (
    <header className={`${styles.header} ${headerClass}`}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <img
            src={
              path === paths.home || path === paths.contact
                ? "/assets/logo_1.svg"
                : "/assets/logo_3.svg"
            }
            alt="logo"
            onClick={() => navigate(paths.home)}
          />
        </div>

        <div className={styles.right}>
          <nav>
            <li
              onClick={() => navigate(paths.organisers)}
              className={path === paths.organisers ? styles.active : ""}
            >
              Organisers
              <span />
            </li>
            <li
              onClick={() => navigate(paths.students)}
              className={path === paths.students ? styles.active : ""}
            >
              Students
              <span />
            </li>
            <li
              onClick={() => navigate(paths.whyyouni)}
              className={path === paths.whyyouni ? styles.active : ""}
            >
              Why Youni
              <span />
            </li>
            <li
              onClick={() => navigate(paths.commyounity)}
              className={path === paths.commyounity ? styles.active : ""}
            >
              Commyounity
              <span />
            </li>
            <li onClick={() => navigate(paths.contact)}>
              <button>Get in touch</button>
              <span />
            </li>
          </nav>

          <img
            src={
              path === paths.home
                ? "/assets/menuWhite.png"
                : "/assets/menuBlack.png"
            }
            alt="menu"
            className={styles.menu}
            onClick={() => setIsOpen(true)}
          />
        </div>
      </div>

      {path === paths.contact && (
        <img
          src="/assets/contactHeaderVectorWeb.png"
          alt="ellipse"
          className={styles.vector}
        />
      )}
      {path === paths.contact && (
        <img
          src="/assets/contactHeaderVectorMob.png"
          alt="ellipse"
          className={styles.vectorMob}
        />
      )}
    </header>
  );
};

export default Header;
