import styles from "../../styles/hero.module.scss"

const Hero = () => {
    return (
        <section className={styles.container}>
            <div className={styles.wrapper}>
                <h1>
                    <span>uni life,</span>
                    <br />
                    <span className={styles.translate}>your way.</span>
                </h1>

                <div className={styles.imgContainer}>
                    <img src="/assets/hero_img.png" alt="hero" />
                    <img src="/assets/glow_mob.png" alt="glow" className={styles.glowMob} />
                </div>
            </div>
            <img src="/assets/hero_glow.png" alt="glow" className={styles.glow} />
        </section>
    )
}

export default Hero