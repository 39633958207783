import styles from "../../styles/member.module.scss"

const Member = ({ img, name, role, email }) => {
    return (
        <div className={styles.container}>
            <img src={img} alt={name} />
            <h4>{name}</h4>
            <p>{role}</p>
            {email && <p>{email}</p>}
        </div>
    )
}

export default Member