import Card from "../../components/_organisers/card";
import styles from "../../styles/orgHero.module.scss";

const Hero = () => {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1>
          Run a society,
          <br />
          event, collective?
        </h1>

        <div className={styles.cards}>
          <Card
            title="Make"
            text="your page and events, communicate directly with your market."
            img="/assets/iphone_3.svg"
            className="smallAndCenter"
            id="one"
          />
          <Card
            title="Monitor"
            text="analytics on engagement, process membership fees and sell tickets."
            img="/assets/mac.svg"
            id="two"
          />
          <Card
            title="Maximise"
            text="your time doing what matters."
            img="/assets/concert.svg"
            id="three"
          />
        </div>

        <div className={styles.row}>
          <span />
          <button>Make an account + try me out</button>
          <span />
        </div>
      </div>
    </section>
  );
};

export default Hero;
