import { useRef, useState } from "react";
import styles from "../../styles/whyyouni.module.scss";
import Slider from "react-slick";
import { Youni } from "./mobile-sections/youni";
import { TicketPlatforms } from "./mobile-sections/ticket-platforms";
import { UniPlatforms } from "./mobile-sections/uni-platforms";

const Main = () => {
  const width = useRef(window.innerWidth);

  const [slideNumber, setSlideNumber] = useState(0);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "80px",
    dots: true,
    initialSlide: 0,
    useCSS: true,
    slidesToShow: 1,
    beforeChange: (current, next) => setSlideNumber(current),
    afterChange: (current) => setSlideNumber(current),
  };


  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1>
          Why <span>Youni'</span>d us
        </h1>

        <div className={styles.content}>
          {width.current >= 1023 ? (
            <>
              <div
                className={styles.row}
                style={{ justifyContent: "space-between", margin: "0 1rem" }}
              >
                {/* 1st Column */}
                <div>
                  {/* Empty Card */}
                  <div className={styles.topCellEmpty}></div>

                  {/* First Card */}
                  <div className={styles.mainCellTitle}>
                    <p className={styles.sectionTitle}>Society Management</p>
                    <div>
                      <p>
                        Society Tailored Profile Pages <br />
                        <span className={styles.subText}>
                          Committee Member Profiles <br />
                          Integrated Socials
                          <br />
                          Live Term Card
                          <br />
                          Live Society Noticeboard
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Follower/Member Management <br />
                        <span className={styles.subText}>
                          Live + Exportable Followers Lists <br />
                          Push Notifications Direct to Followers
                          <br />
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Society Handover Help <br />
                        <span className={styles.subText}>
                          add/remove team members <br />
                          post roles to the student body
                          <br />
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Society Analytics <br />
                        <span className={styles.subText}>
                          dashboard with engagement statistics and <br />
                          followers breakdown (incl. college, degree, <br />
                          undergrad/postgrad and more)
                        </span>
                      </p>
                    </div>
                    <div>All of the above in mobile app.</div>
                  </div>

                  {/* Second Card */}
                  <div className={styles.mainCellTitle}>
                    <p className={styles.sectionTitle}>Events</p>
                    <div>
                      <p>
                        Events Feed <br />
                        <span className={styles.subText}>
                          Filter by Category
                          <br />
                          Explore/For You Page
                          <br />
                          Add to Wishlist
                          <br />
                          See Whose Going to an Event
                          <br />
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Event Manager <br />
                        <span className={styles.subText}>
                          Create + Edit Events on app
                          <br />
                          Ticket Sales
                          <br />
                          Attendee Lists (exportable)
                          <br />
                          QR Code Scanner
                          <br />
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Event Insights <br />
                        <span className={styles.subText}>
                          College Breakdown, Undergrad/Postgrad, <br />
                          Average Age, etc.
                        </span>
                      </p>
                    </div>
                  </div>

                  {/* Prcing & Ticketing */}
                  <div className={styles.mainCellTitle}>
                    <p className={styles.sectionTitle}>Pricing + Ticketing</p>
                    <div>
                      <p>
                        Always free for Societies <br />
                        <span className={styles.subText}>
                          No subscription charge
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Commission <br />
                        <span className={styles.subText}>
                          Rate for avg Ticket Price
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Tiered Ticketing <br />
                        <span className={styles.subText}>
                          Multiple Ticket Types
                          <br />
                          Scheduled releases
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Discount Codes <br />
                        <span className={styles.subText}>
                          Set and Share Discount Codes
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Private Tickets <br />
                        <span className={styles.subText}>
                          Set Private Codes for Private Events
                        </span>
                      </p>
                    </div>
                  </div>

                  {/* Community */}
                  <div className={styles.smallCellTitle}>
                    <p className={styles.sectionTitle}>
                      Community + Socialisation
                    </p>
                    <div>
                      <p>
                        Student Profiles <br />
                        <span className={styles.subText}>
                          Integrated Socials <br />
                          Society, Clude + Project Roles
                          <br />
                          Personalised Events Calendar
                          <br />
                          Ticket Wallet
                          <br />
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Community Noticeboard <br />
                        <span className={styles.subText}>
                          Live Society Updates, Twitter Style
                          <br />
                          COMING SOON: Directory of Student Talent (need a
                          photographer? Find one in seconds)
                        </span>
                      </p>
                    </div>
                  </div>

                  {/* Funding */}
                  <div className={styles.smallCellTitle}>
                    <p className={styles.sectionTitle}>Funding - COMING SOON</p>
                    <div>
                      <p>
                        Sponsorship Matching <br />
                        <span className={styles.subText}>
                          List Society For Corporate Sponsorship <br />
                          Share Analytics Instantly
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Company Society & Events Fund <br />
                        <span className={styles.subText}>
                          Apply to Youni Events Fund
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                {/* Second Column */}
                <div>
                  {/* Top header */}
                  <div className={styles.topCell}>Youni</div>

                  <div className={styles.mainCellOptions}>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" ß alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                  </div>

                  {/* Events */}
                  <div className={styles.mainCellOptions}>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                  </div>

                  {/* Prcing & Ticketing */}
                  <div className={styles.mainCellOptions}>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <p>£0.49 + 3%</p>
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" ß alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                  </div>

                  {/* Community */}
                  <div className={styles.smallCellOptions}>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                  </div>

                  {/* Funding */}
                  <div className={styles.smallCellOptions}>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                  </div>
                </div>
                {/* 3rd Column */}
                <div>
                  <div className={styles.topCell}>
                    Ticketing Platforms <br /> <span>(You know the ones)</span>
                  </div>

                  <div className={styles.mainCellOptions}>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" ß alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                  </div>
                  {/* Events */}
                  <div className={styles.mainCellOptions}>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                  </div>

                  {/* Pricing and Ticketing */}
                  <div className={styles.mainCellOptions}>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <p>
                        £0.49 + 4.99%
                        <br />
                        -<br />
                        £0.59 + 6.95%
                      </p>
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" ß alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                  </div>

                  {/* Community */}
                  <div className={styles.smallCellOptions}>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                  </div>

                  {/* Funding */}
                  <div className={styles.smallCellOptions}>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                  </div>
                </div>

                {/* 4th Column */}
                <div>
                  <div className={styles.topCell}>
                    "Uni" <br /> Platforms <br />{" "}
                    <span>
                      (You probably don't know them <b>😉</b>)
                    </span>
                  </div>

                  <div className={styles.mainCellOptions}>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" ß alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                  </div>

                  {/* Events */}
                  <div className={styles.mainCellOptions}>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                  </div>

                  {/* Pricing and Ticketing */}
                  <div className={styles.mainCellOptions}>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <p>Varies</p>
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" ß alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/tick.png" alt="logo" />
                    </div>
                  </div>

                  {/* Community */}
                  <div className={styles.smallCellOptions}>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                  </div>

                  {/* Funding */}
                  <div className={styles.smallCellOptions}>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                    <div>
                      <img src="/assets/cross.png" alt="logo" />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Slider {...settings}>
                {/* Society Management section */}
                <div>
                  <div className={styles.mobileCellBase}>
                    <p>Youni</p>
                  </div>
                </div>

                {/* Ticketing platforms section */}
                <div>
                  <div className={styles.mobileCellBase}>
                    <p>Ticketing Platforms</p>
                    <span className={styles.mobileHeadingSubText}>
                      (You know the ones)
                    </span>
                  </div>
                </div>
                <div>
                  <div className={styles.mobileCellBase}>
                    <p>“Uni” Platforms </p>
                    <span className={styles.mobileHeadingSubText}>
                      (You probably don't know them 😉)
                    </span>
                  </div>
                </div>

                {/* Uni platforms section */}
              </Slider>

              {/* Society Management  */}
              { (() => {
              if (slideNumber === 0){
                return (
                  <Youni/>
                )
              }
              else if(slideNumber === 1){
                return (
                  <TicketPlatforms/>
                ) 
              }
              else if(slideNumber === 2){
                return (
                  <UniPlatforms/>
                ) 
              }
              })()
            }

              {/* Society Management Section */}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Main;
