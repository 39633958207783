import styles from "../../styles/orgHelp.module.scss";

const Help = () => {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1>Need a hand?</h1>

        <div className={styles.cards}>
          <div className={styles.card}>
            <h3>
              <a className="no-link" href="#n">
                <svg
                  width="193"
                  height="69"
                  viewBox="0 0 193 69"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M72.1129 2C120.498 3.12736 191 27.6325 191 41.1565C191 55.4295 148.691 67 96.5 67C44.3091 67 2 55.4295 2 41.1565C2 27.6325 39.9857 16.5347 88.371 15.4074"
                    stroke="url(#paint0_linear_1396_23274)"
                    strokeWidth="4"
                    strokeLinecap="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1396_23274"
                      x1="262.352"
                      y1="84.2785"
                      x2="246.153"
                      y2="-29.4364"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.0572917" stopColor="#BC61F5" />
                      <stop offset="0.370964" stopColor="#BC61F5" />
                      <stop offset="0.994792" stopColor="#FAF4FD" />
                    </linearGradient>
                  </defs>
                  <text textAnchor="middle" x="96.5" y="50" fill="#000000">
                    Page support
                  </text>
                </svg>

                {/* <img src="/assets/page_support_title.png" alt="vector" /> */}
              </a>
            </h3>
            <p className={`${styles.bold} ${styles.first}`}>
              Our design team is your design team.
            </p>
            <p>
              Send us a message and we can make sure your page pops with custom
              made logos + posters, for free.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Help;
