import styles from "../../styles/orgCard.module.scss"

const Card = ({ title, img, text, id, className }) => {
    return (
        <div
            className={`${id === "one" ? styles.one : id === "two" ? styles.two : styles.three} ${styles.container}`}
            style={{ background: id === "three" ? `url(${img})` : "" }}
        >
            <h2>{title}</h2>
            <p>{text}</p>
            {/* <span className={className}> */}
                {id !== "three" && <img id={`img-${id}`} src={img} alt={title} />}
            {/* </span> */}
        </div>
    )
}

export default Card