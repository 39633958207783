import contactTouch from "../../styles/contactTouch.module.scss";

export const TrademarkPolicy = () => {
  return (
    <div className={contactTouch.content}>
      <>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Last Updated: September 15, 2023.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#13002d", fontSize: "18pt" }}>
              In this article
            </span>
          </strong>
        </h2>
        <ul>
          <li
            style={{
              listStyleType: "none",
              color: "#3659e3",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#3659e3", fontSize: "12pt" }}>
                1. Our Policy.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "none",
              color: "#3659e3",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#3659e3", fontSize: "12pt" }}>
                2. Removing And Re-Posting Of Third Party Content.
              </span>
            </p>
          </li>
        </ul>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              1. Our Policy.
            </span>
          </strong>
        </h2>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Youni takes intellectual property rights seriously. For that reason,
            we have created a simple process to deal with complaints of alleged
            infringement of certain third party intellectual property rights on
            the Youni platform. This process covers allegations of infringement
            of copyrights (e.g., written text, photographs, sound recordings and
            video clips) and trademarks/servicemarks (e.g., logos, slogans and
            names identifying a good or service). Capitalised terms used but not
            defined in this Agreement are defined in the Terms of Service. All
            information, data, text, editorial content, design elements, look
            and feel, formatting, graphics, images, photographs, videos, music,
            sounds and other content relating to an event on the Services is
            posted by Organisers, Consumers, or other third parties, and not by
            Youni (collectively, "Third Party Content"). The following policy
            sets forth the process by which any third party who believes that
            their trademark and/or copyrights are being infringed by Third Party
            Content may request that Youni remove the allegedly infringing Third
            Party Content.
          </span>
        </p>
        <h2>
          <strong>
            <span style={{ color: "#39364f", fontSize: "18pt" }}>
              2. Removing And Re-Posting Of Third Party Content.
            </span>
          </strong>
        </h2>
        <p>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              2.1 How to Get Allegedly Infringing Content Removed from Youni.
            </span>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you believe in good faith that any Third Party Content that Youni
            makes available in connection with the Services infringes your
            copyright or trademark rights, you (or your agent) may send us a
            takedown notice requesting that the Third Party Content be removed,
            or access to it be blocked. Your takedown notice needs to contain
            the following information in order for Youni to be able to take
            action:
          </span>
        </p>
        <ul>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Information that allows Youni to contact you (i.e., name and
                address, telephone number and/or email address);
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Sufficient information to identify the copyrighted works or
                trademarks that are allegedly being infringed, including
                registration number and registration office if applicable
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Sufficient information for us to identify and locate the
                allegedly infringing material including its Internet location
                (e.g., URL address and a description of the protected work, such
                as “the photo used in the event header");
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                A statement that you have a good faith belief that the use of
                the Third Party Content on Youni is not authorised by the owner
                of the Content, its agent or the law;
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                A statement of the accuracy of the takedown notice, and under
                penalty of perjury, a statement that you are the owner of the
                allegedly infringed material or you are authorised to act on
                behalf of such owner; and
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "disc",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                A physical or electronic signature of the person submitting the
                takedown notice.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            The simplest and most efficient way to submit a written takedown
            notice is by reporting the infringement to our team by emailing us
            at youni@tbcgroup.xyz. This is the fastest method for reporting
            alleged copyright and/or trademark infringement. Please note that by
            submitting a takedown notice, the information you provide is
            generally subject to&nbsp;
          </span>
          <span
            style={{
              color: "#39364f",
              fontSize: "10.5pt",
            }}
          >
            Youni's Privacy Policy
          </span>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            , you also agree that Youni may provide copies of such takedown
            notice to the alleged infringer, which may include your name, and
            that the alleged infringer may contact you directly to discuss the
            allegation. In addition, you will be liable for any damages
            (including costs and attorneys' fees) incurred by Youni or the
            alleged infringer in the event you knowingly and materially
            misrepresent that Third Party Content is infringing (taking into
            consideration copyright defences (such as fair use) and exceptions).
            If you are unsure whether the material you are reporting is
            infringing, you should contact a legal professional before filing a
            takedown notice. Youni cannot provide you with legal advice as to
            whether or not you are entitled to file a takedown notice. Upon
            receiving a valid and fully completed takedown notice that
            identifies an infringement of copyright or trademark rights, Youni
            will remove or disable access to the allegedly infringing material
            and notify the alleged infringing party.&nbsp;
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              2.2 Getting Content Reposted That Is Not Infringing.
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;If you have a good faith belief that material removed or
            disabled by Youni as a result of a notification of an alleged
            infringement of intellectual property rights involves a
            misidentification or mistake, you may send a counter-notice via
            email to youni@tbcgroup.xyz. A counter-notice must include all of
            the following information:
          </span>
        </p>
        <ol>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Your signature, which may be provided electronically by typing
                your name
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Identification of the material that has been removed or to which
                access has been disabled
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                A statement made under penalty of perjury that you have a good
                faith belief that the material was removed or disabled as a
                result of mistake or misidentification of the material
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                Your name, address, telephone number, and email address.
              </span>
            </p>
          </li>
          <li
            style={{
              listStyleType: "decimal",
              color: "#39364f",
              fontSize: "12pt",
            }}
          >
            <p>
              <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
                A statement that you consent to the jurisdiction of England
                &amp; Wales,, and that you will accept service of process from
                the person who provided the original notification or an agent of
                such person&nbsp;
              </span>
            </p>
          </li>
        </ol>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            If you misrepresent that material is not infringing, you may be
            liable for damages (including costs and attorneys’ fees). If you are
            not sure whether or not the material is infringing, please contact
            an attorney before submitting a counter-notice to Youni. Fraudulent
            or abusive counter notices or other misuse of Youni’s Intellectual
            Property Policy may result in account termination or other legal
            consequences.&nbsp;
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              2.3 Status of Content After Receipt of Counter-Notice
            </span>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Following Youni’s receipt of a counter-notice in compliance with
            Section 2.2 above, Youni will send a copy of the counter-notice to
            the sender of the takedown notice. &nbsp;For &nbsp;a period of ten
            (10) days following Youni’s receipt of the counter-notice, Youni
            will maintain the status quo by keeping access to the allegedly
            infringing material disabled. If, during this ten (10) day period
            the sender of the takedown notice sues the alleged infringer for
            trademark and/or copyright infringement and provides Youni with a
            court-stamped copy of the complaint, the allegedly infringing
            material will remain disabled. &nbsp;However, if no suit is filed
            during the ten (10) period, Youni will re-activate and allow access
            to the allegedly infringing material. &nbsp;
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              2.4 Repeat Infringer Policy
            </span>
          </strong>
        </p>
        <p>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            Youni enforces a repeat infringer policy, where we may disable or
            terminate accounts of users who repeatedly post infringing
            content.&nbsp;
          </span>
          <strong>
            <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
              2.5 Notice Address.
            </span>
          </strong>
          <span style={{ color: "#39364f", fontSize: "10.5pt" }}>
            &nbsp;If you do not wish to fill out and submit your takedown notice
            or counter-notice electronically, you may submit either in writing
            to the registered office address of TBC GROUP LTD.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
      </>
    </div>
  );
};