import Layout from "../layouts/layout"
import About from "../sections/_home/about"
import CarouselOne from "../sections/_home/carouselOne"
import CarouselTwo from "../sections/_home/carouselTwo"
import FindOut from "../sections/_home/findOut"
import Hero from "../sections/_home/hero"
import Launch from "../sections/_home/launch"
import styles from "../styles/home.module.scss"

const Home = () => {
    return (
        <Layout>
            <div className={styles.container}>
                <Hero />
                <CarouselOne />
                <About />
                <FindOut />
                <CarouselTwo />
                <Launch />
            </div>
        </Layout>
    )
}

export default Home