import { useState } from 'react'
import Footer from './footer'
import Header from './header'
import MobileNav from './mobileNav'

const Layout = ({ children }) => {

    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <Header setIsOpen={setIsOpen} />
            {children}
            <Footer />
            {isOpen && <MobileNav setIsOpen={setIsOpen} />}
        </>
    )
}

export default Layout