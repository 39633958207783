import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import styles from "../styles/mobileNav.module.scss";

const MobileNav = ({ setIsOpen }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img
          src="/assets/logo_3.png"
          alt="logo"
          onClick={() => navigate(paths.home)}
        />
        <span onClick={() => setIsOpen(false)}>&times;</span>
      </div>

      <nav className={styles.nav}>
        <li onClick={() => navigate(paths.organisers)}>Organisers</li>
        <li onClick={() => navigate(paths.students)}>Students</li>
        <li onClick={() => navigate(paths.whyyouni)}>Why Youni</li>
        <li onClick={() => navigate(paths.commyounity)}>Commyounity</li>
      </nav>

      <div className={styles.btnContainer}>
        <button onClick={() => navigate(paths.contact)}>Get in touch</button>
      </div>
    </div>
  );
};

export default MobileNav;
